import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}


const AvatarDropdown = () => {
  const userProfile = useSelector((state) => state.user.user);
  const sellerProfiles = useSelector((state) => state.seller.sellers);
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const userSubscriptions = useSelector(state => state.user.subscriptions || []);
  const sellerSubscriptions = useSelector(state => state.seller.subscriptions || []);

  // Determine the correct avatar URL and profile type
  const profile = currentProfile === 'User' ? userProfile : sellerProfiles.find(seller => seller.name === currentProfile);
  const avatarUrl = profile?.avatar?.url;
  const isUser = currentProfile === 'User';

  const getSubscriptionImageUrl = () => {
    let maxTier = 0;
  
    if (currentProfile === 'User' && userSubscriptions.length) {
      maxTier = userSubscriptions.reduce((max, sub) => {
        return sub.tier === "Super OG" ? 3 : (sub.tier === "OG" ? 2 : max);
      }, 0);
    } else if (sellerSubscriptions.length) {
      maxTier = sellerSubscriptions.reduce((max, sub) => {
        return sub.tier === "Super OG Shop" ? 3 : (sub.tier === "OG Shop" ? 2 : max);
      }, 0);
    }
  
  
    switch (maxTier) {
      case 3:
        return 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1715090157/super_og_youavf.svg';
      case 2:
        return 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1715090152/og_ircw65.svg';
      default:
        return null;
    }
  };
  
  
  const subscriptionIconUrl = getSubscriptionImageUrl();
  

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        {/* Avatar image as the dropdown button */}
        <Menu.Button>
          <img
            src={avatarUrl || 'default-avatar-url'} // Provide a default avatar URL if needed
            alt={`${currentProfile} Avatar`}
            className="w-[42px] h-[42px] rounded-full border-[2px] border-[#ffffff] cursor-pointer"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-dark">
          <div className="py-1">
            {subscriptionIconUrl && (
              <div className="px-4 py-2">
                <img src={subscriptionIconUrl} alt="Subscription Status" className="h-6 w-6"/>
              </div>
            )}
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={isUser ? `/my-profile/${profile?._id}` : `/shop/preview/${profile?.handle}`}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  My Profile
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                to={isUser ? "/profile" : "/settings"}
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm'
                )}
              >
                Account Settings
              </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/shop-create"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  BuzzWeed Biz
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default AvatarDropdown;

