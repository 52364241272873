import React, { useState } from 'react';
import axios from 'axios';
import { server } from "../../server";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(`${server}/user/forgot-password`, { email });
      setMessage(data.message);
      setError(''); 
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred. Please try again.');
      setMessage(''); 
    }
  };

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-xl font-semibold text-gray-800 my-4">Reset Your Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500"
            required
          />
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Send Reset Link
          </button>
        </div>
        {message && <div className="mt-2 text-sm text-green-600">{message}</div>}
        {error && <div className="mt-2 text-sm text-red-600">{error}</div>}
      </form>
    </div>
  );
};

export default ForgotPassword;
