import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../server';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SponsoredPostsAdmin = () => {
    const [sponsoredAds, setSponsoredAds] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('both'); // Filter for product or shop
    const [isLoading, setIsLoading] = useState(true);
    const [timeResolution, setTimeResolution] = useState('daily'); // Time resolution for the chart
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Clicks',
                data: [],
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
            },
            {
                label: 'Impressions',
                data: [],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    });

    useEffect(() => {
        fetchSponsoredAds();
    }, [selectedFilter, timeResolution]);

    const fetchSponsoredAds = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${server}/sponsored/sponsored-ads`, {
                headers: { 'Content-Type': 'application/json' },
                params: { filter: selectedFilter }
            });
            setSponsoredAds(response.data);
            processChartData(response.data);
        } catch (error) {
            console.error('Error fetching sponsored ads:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
      processChartData(sponsoredAds, selectedFilter);
  }, [sponsoredAds, selectedFilter, timeResolution]);

    const processChartData = (ads, filter) => {
      // Filter ads based on the selected filter type
      const filteredAds = ads.filter(ad => {
          if (filter === 'both') return true;
          if (filter === 'product') return ad.product !== undefined;
          if (filter === 'shop') return ad.shop !== undefined && ad.product === undefined;
          return false;
      });

      const aggregationMap = {};

     // Aggregates clicks and impressions into aggregationMap based on timestamps
filteredAds.forEach(ad => {
  ad.clickTimestamps.forEach(timestamp => {
      const key = new Date(timestamp).toLocaleDateString();
      if (!aggregationMap[key]) {
          aggregationMap[key] = { clicks: 0, impressions: 0 };
      }
      aggregationMap[key].clicks += 1;
  });

  ad.impressionTimestamps.forEach(timestamp => {
      const key = new Date(timestamp).toLocaleDateString();
      if (!aggregationMap[key]) {
          aggregationMap[key] = { clicks: 0, impressions: 0 };
      }
      aggregationMap[key].impressions += 1;
  });
});

// Converts aggregationMap to arrays for chart
const labels = Object.keys(aggregationMap).sort((a, b) => new Date(a) - new Date(b));
const clicksData = labels.map(label => aggregationMap[label].clicks);
const impressionsData = labels.map(label => aggregationMap[label].impressions);

// Updates chart data state
setChartData({
  labels,
  datasets: [
      {
          label: 'Clicks',
          data: clicksData,
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
      {
          label: 'Impressions',
          data: impressionsData,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
  ],
});

  };

  const options = {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
          mode: 'index',
          intersect: false,
      },
      plugins: {
          title: {
              display: true,
              text: 'Sponsored Ads Performance',
              font: {
                  size: 20,
              },
              color: '#fff',
          },
          tooltip: {
              callbacks: {
                  label: (context) => `${context.dataset.label}: ${context.parsed.y}`,
              },
          },
          legend: {
              labels: {
                  color: '#fff', // Adjusts legend label colors to white for better visibility
              },
          },
      },
      scales: {
          x: {
              title: {
                  display: true,
                  text: 'Date',
                  color: '#fff',
              },
              ticks: {
                  color: '#fff', // Adjusts x-axis tick colors to white
              },
          },
          y: {
              title: {
                  display: true,
                  text: 'Count',
                  color: '#fff',
              },
              ticks: {
                  color: '#fff', // Adjusts y-axis tick colors to white
              },
              beginAtZero: true,
          },
      },
      elements: {
          line: {
              tension: 0.4, // Adjust for desired curve
          },
          point: {
              hoverRadius: 7,
              radius: 5,
          },
      },
  };

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
    };

    const toggleAdStatus = async (adId, isActive) => {
        try {
            await axios.post(`${server}/sponsored/toggle-status`, { adId, isActive: !isActive });
            // Optimistically update UI
            setSponsoredAds(prevAds => prevAds.map(ad => ad._id === adId ? { ...ad, isActive: !isActive } : ad));
        } catch (error) {
            console.error('Failed to toggle ad status:', error);
        }
    };

    return (
        <div className="sponsored-posts-admin">
            <h1>Sponsored Posts Admin</h1>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <div className="filters">
                        <button onClick={() => handleFilterChange('both')}>Both</button>
                        <button onClick={() => handleFilterChange('product')}>Products</button>
                        <button onClick={() => handleFilterChange('shop')}>Shops</button>
                    </div>
                    <div className="time-resolution">
                        <button onClick={() => setTimeResolution('daily')}>Daily</button>
                        <button onClick={() => setTimeResolution('hourly')}>Hourly</button>
                    </div>
                    <div className="chart-container" style={{ height: '500px' }}>
                        <Line data={chartData} options={{
                            responsive: true,
                            plugins: {
                                legend: { position: 'top' },
                                title: {
                                    display: true,
                                    text: 'Ad Performance'
                                }
                            },
                            scales: {
                                x: {
                                    title: { display: true, text: 'Date' }
                                },
                                y: {
                                    title: { display: true, text: 'Count' },
                                    beginAtZero: true
                                }
                            }
                        }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <table className="sponsored-table" style={{ width: '80%', margin: '0 auto' }}>
          <thead>
            <tr>
              <th>Type</th>
              <th>Name</th>
              <th>Shop</th>
              <th>Ad Spend</th>
              <th>Ad Duration (days)</th>
              <th>Start Date</th>
              <th>Clicks</th>
              <th>Impressions</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sponsoredAds.map(ad => (
              <tr key={ad._id}>
                <td>{ad.product ? 'Product' : 'Shop'}</td>
                <td>{ad.product ? ad.product.name : ad.shop.name}</td>
                <td>{ad.shop.name}</td> 
                <td>${ad.adSpend.toFixed(2)}</td>
                <td>{ad.adDuration}</td>
                <td>{new Date(ad.startDate).toLocaleDateString()}</td>
                <td>{ad.clicks}</td>
                <td>{ad.impressions}</td>
                <td>
                  <button onClick={() => toggleAdStatus(ad._id, ad.isActive)}>
                    {ad.isActive ? 'Deactivate' : 'Activate'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
                </>
            )}
        </div>
    );
};

export default SponsoredPostsAdmin;
