import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
];

const tiers = [
  {
    name: 'No G',
    id: 'tier-freelancer',
    description: 'The essentials to start your vibe',
    features: ['5 products', 'Up to 1,000 subscribers', 'Basic analytics', '48-hour support response time'],
    mostPopular: false,
    price: { monthly: 'Free', annually: 'Free' },
    priceId: { monthly: 'price_1PCKXA01QsHwcg4xO1Qq3XsE', annually: 'price_1PCKXA01QsHwcg4xO1Qq3XsE' },
  },
  {
    name: 'OG',
    id: 'tier-startup',
    description: 'A boost to create a Buzz',
    features: [
      'Events',
      'Exclusive deals',
      'Advanced analytics',
      '24-hour support response time',
      'Marketing automations',
    ],
    mostPopular: true,
    price: { monthly: '$20', annually: '$150' },
    priceId: { monthly: 'price_1PCKWI01QsHwcg4xHyMDzjbU', annually: 'price_1PCKV401QsHwcg4xvNBQEbA2' },
  },
  {
    name: 'Super OG',
    id: 'tier-enterprise',
    description: 'V.I.P. Access',
    features: [
      'Unlimited products',
      'Unlimited subscribers',
      'Advanced analytics',
      '1-hour, dedicated support response time',
      'Marketing automations',
      'Custom reporting tools',
    ],
    mostPopular: false,
    price: { monthly: '$100', annually: '$1000' },
    priceId: { monthly: 'price_1PCKYl01QsHwcg4xnDzp6rs3', annually: 'price_1PCKZ301QsHwcg4xKomJoXkr' },
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SubscriptionPricing() {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const navigate = useNavigate();

  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-green-400">Pricing</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            It's time to level up!
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
          Choose an affordable plan that’s packed with the best features for special deals, access to events and exclusive entertainament
        </p>
        <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white"
          >
            <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(checked ? 'bg-green-500' : '', 'cursor-pointer rounded-full px-2.5 py-1')
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'bg-white/5 ring-2 ring-green-500' : 'ring-1 ring-white/10',
                'rounded-3xl p-8 xl:p-10'
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3 id={tier.id} className="text-lg font-semibold leading-8 text-white">
                  {tier.name}
                </h3>
                {tier.mostPopular && (
                  <p className="rounded-full bg-green-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                    Most popular
                  </p>
                )}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-300">{tier.description}</p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-white">{tier.price[frequency.value]}</span>
                <span className="text-sm font-semibold leading-6 text-gray-300">{frequency.priceSuffix}</span>
              </p>
              <button
                onClick={() => navigate(`/subscription-checkout/${tier.priceId[frequency.value]}`)}
                className="bg-green-500 text-white rounded-md px-4 py-2 mt-4"
              >
                Subscribe Now
              </button>
              <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

