export default function TempPage() {
    return (
      <div className="relative bg-white">
        <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
          <div className="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-48 xl:col-span-6">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <img
                className="h-11"
                src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1698860714/BuzzWeed-Logo-full-so-web2_kcwwjk.png"
                alt="Your Company"
              />
              <div className="hidden sm:mt-32 sm:flex lg:mt-16">
              </div>
              <h1 className="mt-24 text-4xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-6xl">
               Coming soon!
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Get ready for something amazing...
              </p>
            </div>
          </div>
          <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
            <img
              className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
              src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1706194953/a3lybg3r8hyy8xrv4y0f.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    )
  }
  