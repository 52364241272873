import React from 'react';

const OutlookCalendarButton = ({ event }) => {
    const formatDate = (date) => {
        try {
            const d = new Date(date);
            if (isNaN(d.getTime())) {
                throw new Error('Invalid date');
            }
            // Outlook requires the date in ISO format but without milliseconds
            return d.toISOString().replace(/\.\d{3}/, '');
        } catch (error) {
            console.error('Date formatting error:', error);
            return ''; 
        }
    };

    const outlookCalendarUrl = () => {
        const startTime = formatDate(event.date);
        const endTime = formatDate(new Date(new Date(event.date).getTime() + 2 * 60 * 60 * 1000)); // Assuming a 2-hour event
        return `https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/action/compose&startdt=${startTime}&enddt=${endTime}&subject=${encodeURIComponent(event.title)}&body=${encodeURIComponent(event.description)}&location=${encodeURIComponent(event.location.address)}`;
    };

    const buttonStyle = {
        backgroundColor: '#0078D4', // Outlook blue
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
        fontFamily: '"Segoe UI", sans-serif',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease'
    };

    return (
        <a href={outlookCalendarUrl()} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button style={buttonStyle} onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#005A9E'} onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#0078D4'}>
                Add to Outlook Calendar
            </button>
        </a>
    );
};

export default OutlookCalendarButton;
