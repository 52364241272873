import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { server } from "../../server";
import Loader from "../Layout/Loader";
import { AiOutlineCamera } from "react-icons/ai";
import { loadUser } from "../../redux/actions/user"; // Adjust according to your actions
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const UserBanner = ({ isUser }) => {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams(); // User ID from URL
  const [userBanner, setUserBanner] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${server}/user/user-info/${id}`)
      .then((res) => {
        setUser(res.data.user);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      })
  }, [id]);

  const handleBannerChange = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setUserBanner(reader.result);
        axios.put(
            `${server}/user/update-banner`, // Endpoint for updating user banner
            { userBanner: reader.result },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            dispatch(loadUser()); // Adjust action accordingly
            toast.success("Banner updated successfully!");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    };
    reader.readAsDataURL(e.target.files[0])
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="w-full">
      <img
        src={`${user.userBanner?.url}`}
        alt="User Banner"
        className="w-full object-cover"
        style={{ height: '300px' }}
      />
      {isUser && (
        <div className="w-[42px] h-[42px] bg-[#099910] rounded-full flex items-center justify-center cursor-pointer absolute right-[15px] -mt-[3rem]">
          <input type="file" id="banner" className="hidden" onChange={handleBannerChange} />
          <label htmlFor="banner">
            <AiOutlineCamera />
          </label>
        </div>
      )}
    </div>
  );
}

export default UserBanner;
