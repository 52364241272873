import React, { useState } from 'react';
import { setUserLocation, locationError, saveLocation } from '../../redux/actions/user'
import { useDispatch, useSelector } from "react-redux";

function LocationTracker() {
  const location = useSelector(state => state.user.location);
  const locationError = useSelector(state => state.user.locationError);
  const [showModal, setShowModal] = useState(true);
  const [consent, setConsent] = useState(false);
  const dispatch = useDispatch();

  const handleConsent = () => {
    setConsent(true);
    setShowModal(false);
    startGeolocation();
  };

  const startGeolocation = () => {
    const geo = navigator.geolocation;

    if (!geo) {
        dispatch(locationError('Geolocation is not supported by your browser'));
        return;
    }

    geo.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        const locationData = { latitude, longitude };
         sessionStorage.setItem('userLocation', JSON.stringify(locationData));
        dispatch(setUserLocation(locationData));
        dispatch(saveLocation(locationData));
    }, (error) => {
        dispatch(locationError(`Geolocation error: ${error.message}`));
    }, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    });
};
  

return (
    <div>
      {showModal && !consent && (
        <div className="locator-modal">
          <div className="locator-modal-content">
            <h2>Location Tracking Consent</h2>
            <p>We need your permission to track your location. Do you allow us to access your location data?</p>
            <button className="locator-button" onClick={handleConsent}>Yes, I agree</button>
            <button className="locator-button" onClick={() => setShowModal(false)}>No, thanks</button>
          </div>
        </div>
      )}
      {consent && location && location.latitude && location.longitude ? (
        <div>
          <h2>User Location</h2>
          <p>Latitude: {location.latitude}, Longitude: {location.longitude}</p>
        </div>
      ) : (
        <div>Loading or error...</div>  
      )}
    </div>
  );
}


export default LocationTracker;

