import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getOtherUserFollowers } from '../../redux/actions/followers'; 

const FollowersAvatarDisplay = ({ userId, profileType }) => {
  const maxDisplay = 5; 
  const dispatch = useDispatch();
  // Adjust the selector to fetch followers
  const { otherUserFollowers, loading, error } = useSelector(state => state.followers); 

  useEffect(() => {
    if (userId && profileType) {
      dispatch(getOtherUserFollowers(userId, profileType)); 
    }
  }, [userId, profileType, dispatch]);

  return (
    <div className="flex items-center space-x-4">
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {!loading && !error && (
        <>
          <div className="isolate flex -space-x-1 p-1 overflow-hidden">
            {otherUserFollowers.slice(0, maxDisplay).map((follower, index) => (
              <img
                key={index}
                className="relative z-30 inline-block h-8 w-8 rounded-full ring-1 ring-white"
                src={follower.avatar} 
                alt={follower.name} 
              />
            ))}
          </div>
          <div>
            <span className="text-sm font-semibold">{otherUserFollowers.length} Followers</span>
          </div>
        </>
      )}
    </div>
  );
};

export default FollowersAvatarDisplay;
