import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import NotificationFeed from './NotificationFeed'
import { useSelector, useDispatch } from "react-redux";
import { loadSeller } from "../redux/actions/user";

export default function NotificationSidebar() {
  const [open, setOpen] = useState(true)
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const { user } = useSelector((state) => state.user);
  const seller = useSelector((state) => state.seller.seller);
  const userId= user?._id;
  const sellerId = seller?._id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSeller());
  }, [dispatch]);

  // Function to map currentProfile to "User" or "Shop"
  const getCurrentProfileType = () => {
    return currentProfile === 'User' ? 'User' : 'Shop';
  };

  const currentProfileType = getCurrentProfileType();
  // Determine the profile ID based on the current profile type
  const profileId = currentProfile === 'User' ? userId : sellerId;


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className=" px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-white">
                          Notifications
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-green-700 text-indigo-200 hover:text-white focus:outline-none"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                      </div>
                    </div>
                    <div className="relative flex-1 px-4 py-6 sm:px-6">
                        <NotificationFeed profileId={profileId} profileType={currentProfileType} />

                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

