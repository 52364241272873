import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon  } from '@heroicons/react/20/solid';

const HeroSliderHome = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slidesData = [
    {
      id: 1,
      title: "Marketplace",
      buttonText: "Shop Now",
      link: "/products",
      image: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1713450251/graffitbackground_xyidbd.jpg",
    },
    {
      id: 2,
      title: "Discover Quality",
      buttonText: "Explore",
      link: "/explore",
      image: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1703076292/banners/vhdendtdzy3krvzwrbba.jpg", 
    },
    {
      id: 3,
      title: "New Arrivals",
      buttonText: "New Collection",
      link: "/new-arrivals",
      image: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1713795812/backgroundnew_auduza.jpg",
    },
    {
      id: 4,
      title: "Exclusive Offers",
      buttonText: "Get Offers",
      link: "/offers",
      image: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1713795916/backgroundnew2_y92s7n.jpg",
    },
  ];
  

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slidesData.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slidesData.length) % slidesData.length);
  };

  return (
    <div className="relative" style={{ height: '50vh' }}>
      {slidesData.map((slide, index) => (
        <div
          key={slide.id}
          className={`absolute inset-0 transition-opacity duration-500 ease-in-out ${index === currentSlide ? 'opacity-100' : 'opacity-0'}`}
          style={{ backgroundImage: `url(${slide.image})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundAttachment: 'fixed' }}
        >
          {index === currentSlide && (
            <div className="text-white" style={{ height: '100%', padding: '6em', background: 'linear-gradient(to left, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%)' }}>
              <h1 className="text-7xl font-bold">{slide.title}</h1>
              <Link to={slide.link} className="mt-4 inline-block bg-green-500 hover:bg-green-800 text-white text-3xl font-bold py-2 px-4 rounded-lg">
                {slide.buttonText}
              </Link>
            </div>
          )}
        </div>
      ))}
      <button className="absolute top-1/2 left-4 z-10 text-white bg-black bg-opacity-50 rounded-full p-3" onClick={prevSlide}>
        <ChevronLeftIcon className="h-6 w-6" />
      </button>
      <button className="absolute top-1/2 right-4 z-10 text-white bg-black bg-opacity-50 rounded-full p-3" onClick={nextSlide}>
        <ChevronRightIcon className="h-6 w-6" />
      </button>
    </div>
  );
};

export default HeroSliderHome;

