import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from "../../server";
import styles from "../../styles/styles";

const SubscriptionCheckout = () => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.user);
    const { priceId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState(user?.email || '');
    const [fullName, setFullName] = useState(user?.name || '');
    const [priceDetails, setPriceDetails] = useState(null);
    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4"
                },
                iconColor: '#c4f0ff',
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            },
            complete: {
                color: "#00e676",
            }
        }
    };

    useEffect(() => {
        // Fetch price details from your backend
        const fetchPriceDetails = async () => {
            try {
                const response = await axios.get(`${server}/subscriptions/price-details/${priceId}`);
                setPriceDetails(response.data);
            } catch (error) {
                console.error('Failed to fetch price details', error);
                toast.error('Failed to load pricing information.');
            }
        };

        fetchPriceDetails();
    }, [priceId]);

    const handlePaymentSubmission = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                email: email,
                name: fullName,
            },
        });

        if (error) {
            toast.error(`Payment failed: ${error.message}`);
            setIsLoading(false);
            return;
        }

        try {
            const { data } = await axios.post(`${server}/subscriptions/create-subscription`, {
                email,
                paymentMethodId: paymentMethod.id,
                priceId
            }, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });

            toast.success('Subscription successful!');
            navigate('/profile');
        } catch (error) {
            console.error('Subscription creation failed:', error);
            toast.error('Failed to create subscription. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 max-w-4xl mx-auto">
            <div className="order-2 lg:order-1 bg-black">
                <form onSubmit={handlePaymentSubmission} className={styles.form}>
                    <label className="block pb-2">Name On Card</label>
                    <input type="text" className={styles.input} placeholder="Full Name" value={fullName} onChange={e => setFullName(e.target.value)} required />
                    <label className="block pb-2">Email Address</label>
                    <input type="email" className={styles.input} placeholder="Email Address" value={email} onChange={e => setEmail(e.target.value)} required />
                    <CardElement options={CARD_ELEMENT_OPTIONS} className={styles.cardElement}/>
                    <button type="submit" className={styles.button} disabled={!stripe || isLoading}>
                        {isLoading ? 'Processing...' : 'Confirm Subscription'}
                    </button>
                </form>
            </div>
            <div className="order-1 lg:order-2">
                {priceDetails && (
                    <div className="subscription-details">
                        <h2>{`Level up with your new: ${priceDetails.product.name} subscription`}</h2>
                        <p>{priceDetails.product.description}</p>
                        <p>Price: ${(priceDetails.unit_amount / 100).toFixed(2)} {priceDetails.currency.toUpperCase()}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SubscriptionCheckout;



