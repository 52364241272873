import React from 'react';
import GoogleCalendarButton from './GoogleCalendarButton';
import OutlookCalendarButton from './OutlookCalendarButton';
import DownloadICSButton from './DownloadICSButton';

const MyEventCard = ({ event, onCancelRSVP }) => {


    const styles = {
        card: {
            position: 'relative',
            margin: '20px',
            border: '1px solid #ccc',
            overflow: 'hidden',
            borderRadius: '8px', 
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
        },
        image: {
            width: '100%',
            height: '300px', 
            objectFit: 'cover',
            transition: 'transform 0.5s ease'
        },
        info: {
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            background: 'linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, transparent 100%)',
            color: 'white',
            padding: '20px',
            transition: 'background 0.5s ease'
        },
        title: {
            margin: '0',
            fontWeight: 'bold',
            fontSize: '24px' 
        },
        description: {
            fontSize: '16px', 
            margin: '10px 0 20px' 
        },
        button: {
            backgroundColor: '#007BFF',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            marginTop: '10px',
            cursor: 'pointer',
            borderRadius: '5px',
            fontSize: '16px'
        },
        calendarButton: {
            marginTop: '10px'
        }
    };

    return (
        <div style={styles.card}>
            <img src={event.image} alt={event.title} style={styles.image} />
            <div style={styles.info}>
                <h3 style={styles.title}>{event.title}</h3>
                <p style={styles.description}>{event.description}</p>
                <p>{new Date(event.date).toLocaleString()}</p>
                <p>{event.location.address}</p> 
                <button
                    onClick={() => onCancelRSVP(event._id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Cancel RSVP
                </button>

                <div style={styles.calendarButton}>
                    <GoogleCalendarButton event={event} />
                </div>
                <OutlookCalendarButton event={event} />
                <DownloadICSButton event={event} />
            </div>
        </div>
    );
};

export default MyEventCard;