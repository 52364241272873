import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { fetchFriends } from '../redux/actions/friends';

const FriendsAvatarDisplay = ({ userId, profileType }) => {
  const maxDisplay = 5; 
  const dispatch = useDispatch();
  const { friends, loading, error } = useSelector(state => state.friends); 

  useEffect(() => {
    if (userId && profileType) {
      dispatch(fetchFriends(userId, profileType)); 
    }
  }, [userId, profileType, dispatch]);

  return (
    <div className="flex items-center space-x-4">
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {!loading && !error && (
        <>
      <div className="isolate flex -space-x-1 p-1 overflow-hidden">
        {friends.slice(0, maxDisplay).map((friend, index) => (
          <img
            key={index}
            className="relative z-30 inline-block h-8 w-8 rounded-full ring-1 ring-white"
            src={friend.avatar} 
            alt={friend.name} 
          />
        ))}
      </div>
      <div>
        <span className="text-sm font-semibold">{friends.length} Connections</span>
      </div>
      </>
      )}
    </div>
  );
};

export default FriendsAvatarDisplay;
