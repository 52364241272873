import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFriendListPanel, toggleNotificationSidebar } from '../../state/uiSlice'; 
import { loadNotifications, resetUnseenNotifications } from '../../redux/actions/notifications'; // Adjust the path accordingly
import {
  CalendarIcon,
  ChartBarIcon,
  HomeIcon,
  BellIcon,
  BuildingStorefrontIcon,
  InboxIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import FriendListSlidingPanel from '../FriendsListPanel';
import ProfileSwitchButton from '../ProfileSwitchButton';
import NotificationSidebar from '../NotificationSidebar';
import { MapIcon } from '@heroicons/react/20/solid';
import FollowersFollowingPanel from '../Followers/FollowersFollowingPanel';

// Bell Icon with Notification Badge
const BellWithNotifications = ({ unseenCount }) => (
  <div className="relative">
    <BellIcon className="h-6 w-6" aria-hidden="true" />
    {unseenCount > 0 && (
      <span className="absolute -top-1 -right-1 inline-flex z-50 items-center justify-center px-1 py-1 text-xs font-bold leading-none text-green-100 transform translate-x-1/2 -translate-y-1/2 bg-green-600 rounded-full">
        {unseenCount}
      </span>
    )}
  </div>
);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Sidebar() {
  const dispatch = useDispatch();
  const { loading, user, currentProfile } = useSelector((state) => state.user);
  const { isFriendListPanelOpen, isNotificationSidebarOpen } = useSelector((state) => state.ui);
  const { unseenCount } = useSelector((state) => state.notifications);
  const { seller } = useSelector((state) => state.seller);
  const userId= user?._id;
  const sellerId = seller?._id;
  const profileId = currentProfile === 'User' ? userId : sellerId;

  useEffect(() => {
    if (profileId) {
      dispatch(loadNotifications(currentProfile, profileId));
    }
  }, [dispatch, currentProfile, profileId]);
  

  const handleNavigationClick = (item) => {
    if (item.onClick === 'openTeam') {
      dispatch(toggleFriendListPanel());
    }
    if (item.onClick === 'openNotifications') {
      dispatch(toggleNotificationSidebar());
      dispatch(resetUnseenNotifications(profileId, currentProfile));
    }
    // Add logic for other navigation items if needed
  };

  const shopLink = currentProfile === 'User' ? '/products' : '/wholesale/products';

  // Define your navigation array
  let navigation = [
    { name: '', icon: HomeIcon, href: '/home', current: true },
    { name: '', icon: () => <BellWithNotifications unseenCount={unseenCount} />, href: '#', current: false, onClick: 'openNotifications' },
    { name: '', icon: UsersIcon, href: '#', current: false, onClick: 'openTeam' },
    { name: '', icon: BuildingStorefrontIcon, href: shopLink, current: false },
    { name: '', icon: MapIcon, href: '/map', current: false },
    { name: '', icon: CalendarIcon, href: '/events', current: false },
    
  ];
  if (currentProfile !== 'User') {
    navigation.push({ name: '', icon: ChartBarIcon, href: '/shop-login', current: false });
  }

  return (
    <>
      {/* Bottom Navigation for Mobile Screens */}
      <div className="fixed inset-x-0 bottom-0 z-50 lg:hidden bg-gray-900 shadow-md">
        <nav className="flex justify-around" aria-label="Bottom navigation">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className={classNames(
                item.current
                  ? 'text-green-600'
                  : 'text-gray-600 hover:text-gray-900',
                'py-2 px-3 text-sm font-medium'
              )}
              onClick={() => handleNavigationClick(item)}
            >
              {typeof item.icon === 'function' ? <item.icon /> : <item.icon
                className={classNames(
                  item.current ? 'text-green-700' : 'text-gray-400 hover:text-green-300',
                  'h-6 w-6'
                )}
                aria-hidden="true"
              />}
              <span className="sr-only">{item.name}</span>
            </a>
          ))}
          <div className="py-2 px-3">
      <ProfileSwitchButton />
    </div>
        </nav>
      </div>

      {/* Sidebar for Larger Screens */}
      <div className="hidden lg:flex fixed inset-y-0 left-0 items-center z-50">
        <div style={{ backgroundColor: 'rgba(74, 74, 74, 0.6)' }} className="w-16 rounded-r-lg shadow-md">
          <div className="flex flex-shrink-0 flex-col items-center px-4 py-8">
            <ProfileSwitchButton className="mb8 profile-toolbar-avatar"/>
            <nav className="flex flex-col space-y-1 py-4" aria-label="Sidebar">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current
                      ? 'border-green-600 bg-green-500 text-green-600'
                      : 'border-transparent text-gray-600 hover:bg-gray-500 hover:text-gray-900',
                    'group flex items-center border-l-4 px-3 py-2 text-sm font-medium w-full'
                  )}
                  onClick={() => handleNavigationClick(item)}
                >
                  {typeof item.icon === 'function' ? <item.icon /> : <item.icon
                    className={classNames(
                      item.current ? 'text-green-700' : 'text-gray-400 group-hover:text-green-300',
                      'mr-3 h-6 w-6 flex-shrink-0'
                    )}
                    aria-hidden="true"
                  />}
                  {item.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {/* Conditional rendering of the FriendListSlidingPanel */}
      {isFriendListPanelOpen && <FollowersFollowingPanel/>}
      {isNotificationSidebarOpen && <NotificationSidebar />}
    </>
  );
}