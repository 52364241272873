import React, { useEffect, useState } from 'react';
import axios from 'axios';
import socketIOClient from 'socket.io-client';
import { UserCircleIcon, ChatBubbleLeftEllipsisIcon, NewspaperIcon, UserPlusIcon, ShareIcon } from '@heroicons/react/20/solid';

const ENDPOINT = "https://backend.buzzweed.com/";
const socket = socketIOClient(ENDPOINT, { withCredentials: true });

function NotificationFeed({ profileId, profileType }) {
    const [notifications, setNotifications] = useState([]);

    // Function to return icon based on notification type
    const getIconForType = (type) => {
        switch (type) {
            case 'newComment':
                return <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />;
            case 'newLike':
                return <UserCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />;
            case 'newPost':
                return <NewspaperIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />;
            case 'newFriend':
                return <UserPlusIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />;
            case 'reshare':
                return <ShareIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />;
            default:
                return null;
        }
    };

    useEffect(() => {
        // Fetch initial notifications
        const fetchNotifications = async () => {
            try {
                
                const response = await axios.get(`${ENDPOINT}api/v2/notifications/get-notifications/${profileType}/${profileId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                setNotifications(response.data.notifications);
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        };

        fetchNotifications();

        socket.on('notification', (newNotification) => {
            setNotifications(prevNotifications => [newNotification, ...prevNotifications]);
        });

        socket.on('connect_error', (error) => {
            console.error("Socket connection error:", error);
        });

        return () => socket.off('notification');
    }, [profileId, profileType]);

    return (
        <div className="flow-root">
            <ul role="list" className="-mb-8">
            {Array.isArray(notifications) && notifications.map((notification, idx) => (
                    <li key={notification._id}>
                        <div className="relative pb-8">
                            {idx !== notifications.length - 1 && (
                                <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                            )}
                            <div className="relative flex items-start space-x-3">
                                <div className="relative">
                                    {getIconForType(notification.type)}
                                    {notification.sender && (
                                    <img
                                        className="flex h-10 w-10 items-center justify-center rounded-full"
                                        src={notification.sender.avatar.url}
                                    />
                                )}
                                </div>
                                <div className="min-w-0 flex-1">
                                    <p><strong>{notification.sender.name}</strong></p>
                                    <p> {notification.message}</p>
                                    <p>{new Date(notification.createdAt).toLocaleString()}</p>
                                    {/* Additional notification details */}
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default NotificationFeed;





