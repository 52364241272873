import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { server } from '../server'; 
import UserProfileData from '../components/UserProfileData';
import Header from '../components/Layout/Header';
import Sidebar from '../components/Layout/Sidebar';
import AdvertWidget from '../widgets/AdvertWidget';
import UserBanner from '../components/Shop/UserBanner';
import FriendListTileWidget from '../widgets/FriendListTileWidget';
import { AiOutlineCamera } from "react-icons/ai";
import FriendsAvatarDisplay from '../components/FriendsAvatarDisplay';
import SponsoredProducts from '../components/Sponsored/SponsoredProducts';


const PrivateProfilePage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const { id } = useParams(); 
  const picturePath = user?.picturePath;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${server}/user/user-info/${id}`);
        setUser(response.data.user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmitAvatar = async () => {
    if (!selectedFile) {
      alert('Please select a file to upload');
      return;
    }

    const formData = new FormData();
    formData.append('avatar', selectedFile);

    try {
      const response = await axios.put(`${server}/user/update-avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });

      if (response.data.success) {
        setUser({ ...user, avatar: response.data.user.avatar });
      }
    } catch (error) {
      console.error('Error updating avatar:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>User data not available</div>;
  }

  return (
    <div className="profile-page bg-black">
      <Header />
      <Sidebar />
      <UserBanner isUser={true} />
      <div className="profile-header bg-green-700 h-24 sm:h-20 lg:h-28"></div>
      <div className="profile-main -mt-12 px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-16 lg:px-24">
        <div>
          <div className="-m-1 flex">
            <div className="inline-flex overflow-hidden border-4 border-white" style={{ borderRadius: '50%', width: '150px', height: '150px' }}>
              <img
                src={user.avatar?.url || 'default-avatar.png'}
                alt={user.name}
                style={{ borderRadius: '50%', width: '100%', height: '100%' }}
              />
            </div>
          </div>
        </div>
        <div className="mt-6 sm:ml-6 sm:flex-1">
          <div className="flex items-center">
            <h3 className="text-xl font-bold text-green-500 sm:text-2xl">{user.name}</h3>
          </div>
          <p className="text-sm text-green-500">{user.email}</p>
          <div className="p-4">
          <FriendsAvatarDisplay userId={id} profileType="User" />
          </div>
          {id === user._id && (
          <div className="w-[42px] h-[42px] bg-[#099910] rounded-full flex items-center justify-center cursor-pointer absolute left-[24px] -mt-[3rem]">
            <input type="file" id="avatar" onChange={handleFileChange} className="hidden" />
            <label htmlFor="avatar">
              <AiOutlineCamera />
            </label>
            <button onClick={handleSubmitAvatar}></button>
          </div>
        )}
        </div>
      </div>

      <div className="flex flex-col w-full items-start gap-x-8 px-4 sm:px-6 lg:px-24 lg:flex-row">
  {/* Left column (FriendListTileWidget), full-width on mobile, 25% on larger screens */}
  <div className="order-2 lg:order-1 w-full lg:w-1/4 bg-[#f5f5f5] p-2 rounded-lg lg:sticky lg:top-24">
    <FriendListTileWidget userId={id} profileType="User" />
  </div>

  {/* Main content area, flex-grow on mobile, 50% width on larger screens */}
  <main className="order-1 lg:order-2 flex-grow lg:w-1/2 p-2">
    <UserProfileData />
  </main>

  {/* Right column (SponsoredProducts), full-width on mobile, 25% on larger screens */}
  <aside className="order-3 w-full lg:w-1/4 bg-[#f5f5f5] p-4 rounded-lg lg:sticky lg:top-24 lg:hidden xl:block">
    <SponsoredProducts />
  </aside>
</div>

    </div>
  );
};

export default PrivateProfilePage;
