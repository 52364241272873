import { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Listbox, Disclosure, Menu, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, ChevronUpDownIcon, CheckIcon, FunnelIcon, MinusIcon, PlusIcon, Squares2X2Icon } from '@heroicons/react/20/solid'
import MapComponent from '../components/Map/Map'
import Header from '../components/Layout/Header'
import Sidebar from '../components/Layout/Sidebar'
import { fetchBrands, fetchBrandCatalogItems } from "../redux/actions/brand";
import { loadAllSellers } from "../redux/actions/user";
import MapProductAdSlider from '../components/Sponsored/MapProductAdSlider';

const sortOptions = [
  { name: 'Most Popular', href: '#', current: true },
  { name: 'Best Rating', href: '#', current: false },
  { name: 'Newest', href: '#', current: false },
  { name: 'Price: Low to High', href: '#', current: false },
  { name: 'Price: High to Low', href: '#', current: false },
]
const subCategories = [
  { name: 'Favorites', href: '#' },
  { name: 'Most Liked', href: '#' },
  { name: 'Sales', href: '#' },
  { name: 'Events', href: '#' },
  { name: 'Trending', href: '#' },
]
const filters = [
  {
    id: 'color',
    name: 'Price',
    options: [
      { value: '$1-20', label: '$1-10', checked: false },
      { value: '$21-40', label: '$21-40', checked: false },
      { value: '$41-60', label: '$41-60', checked: true },
      { value: '$61-80', label: '$61-80', checked: false },
      { value: '$81-100', label: '$81-100', checked: false },
      { value: '$100+', label: '$100+', checked: false },
    ],
  },
  {
    id: 'category',
    name: 'Category',
    options: [
      { value: 'new-arrivals', label: 'New Arrivals', checked: false },
      { value: 'sale', label: 'Sale', checked: false },
      { value: 'THC', label: 'THC', checked: true },
      { value: 'CBD', label: 'CBD', checked: false },
      { value: 'Deltas', label: 'Deltas', checked: false },
    ],
  },
  {
    id: 'size',
    name: 'Distance',
    options: [
      { value: '2m', label: '2m', checked: false },
      { value: '6m', label: '6m', checked: false },
      { value: '12m', label: '12m', checked: false },
      { value: '18m', label: '18m', checked: false },
      { value: '20m', label: '20m', checked: false },
      { value: '40m', label: '40m', checked: true },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function MapPage() {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const [selectedBrand, setSelectedBrand] = useState('');
  const  shops  = useSelector((state) => state.seller.allSellers);
  const dispatch = useDispatch();
  const [selectedShopList, setSelectedShopList] = useState(null);
  const brandsFromRedux = useSelector((state) => state.brand.brands);
  const [brandFilters, setBrandFilters] = useState({
    id: 'brands',
    name: 'Brands',
    options: [],
  });


  useEffect(() => {
    // Dispatch fetchBrands action at component mount or when relevant state changes
    dispatch(fetchBrands());
  
    // Determine if a brand has been selected and fetch sellers accordingly
    if (selectedBrand) {
      // If there's a selectedBrand, dispatch loadAllSellers with the brand
      dispatch(loadAllSellers(selectedBrand.value));
    } else {
      // If no brand is selected, dispatch loadAllSellers without a brand to fetch all sellers
      dispatch(loadAllSellers());
    }
  }, [dispatch, selectedBrand]);
  

  useEffect(() => {
    console.log("List of Shops:", shops);
  }, [shops]);

  useEffect(() => {
    if (brandsFromRedux) {
      const brandOptions = brandsFromRedux.map(brand => ({
        value: brand.name,
        label: brand.name,
        checked: false,
      }));
      setBrandFilters(prevFilters => ({ ...prevFilters, options: brandOptions }));
    }
  }, [brandsFromRedux]);
  

  // Function to handle brand selection change
  const handleBrandChange = (value) => {
    console.log("Selected Brand:", value); // Logging the selected brand immediately
    setSelectedBrand(value);
  
    // Reset checked state for brand filters
    const updatedOptions = brandFilters.options.map(option => ({
      ...option,
      checked: option.value === value, // Compare directly with value
    }));
    setBrandFilters(prevFilters => ({ ...prevFilters, options: updatedOptions }));
  };
  

  return (
    <div >
    <Header />
    <Sidebar />
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4 border-t border-gray-200">
                    <h3 className="sr-only">Categories</h3>
                    <ul role="list" className="px-2 py-3 font-medium text-gray-900">
                      {subCategories.map((category) => (
                        <li key={category.name}>
                          <a href={category.href} className="block px-2 py-3">
                            {category.name}
                          </a>
                        </li>
                      ))}
                    </ul>

                    {filters.map((section) => (
                      <Disclosure as="div" key={section.id} className="border-t border-gray-200 px-4 py-6">
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                <span className="font-medium text-gray-900">{section.name}</span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                  ) : (
                                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-6">
                                {section.options.map((option, optionIdx) => (
                                  <div key={option.value} className="flex items-center">
                                    <input
                                      id={`filter-mobile-${section.id}-${optionIdx}`}
                                      name={`${section.id}[]`}
                                      defaultValue={option.value}
                                      type="checkbox"
                                      defaultChecked={option.checked}
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                      className="ml-3 min-w-0 flex-1 text-gray-500"
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="mx-auto">
          <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-8">
            <h1 className="text-4xl font-bold tracking-tight px-4 text-gray-900">Map</h1>

            <div className="flex items-center space-x-4">
      {/* Horizontal filters */}
      <div className="flex items-center space-x-4">
      <Listbox value={selectedBrand} onChange={handleBrandChange}>
        {({ open }) => (
          <>
            <Listbox.Label className="sr-only">Brands</Listbox.Label>
            <div className="relative">
              <Listbox.Button className="group inline-flex items-center rounded-md bg-white py-1.5 px-3 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span>{selectedBrand ? selectedBrand.label : "Select a brand"}</span>
                <ChevronUpDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {brandFilters.options.map((brand) => (
                    <Listbox.Option
                      key={brand.value}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'
                        }`
                      }
                      value={brand}
                    >
                      {({ selected }) => (
                        <>
                          <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                            {brand.label}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
        {subCategories.map((category) => (
          <a
            key={category.name}
            href={category.href}
            className="text-sm font-medium text-gray-700 hover:text-gray-900 whitespace-nowrap"
          >
            {category.name}
          </a>
        ))}
        
      </div>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    Sort
                    <ChevronDownIcon
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <Menu.Item key={option.name}>
                          {({ active }) => (
                            <a
                              href={option.href}
                              className={classNames(
                                option.current ? 'font-medium text-gray-900' : 'text-gray-500',
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              {option.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              <button type="button" className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7">
                <span className="sr-only">View grid</span>
                <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="sr-only">Filters</span>
                <FunnelIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pb-24 pt-6">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div>
              {/* Filters */}
              <div className="filters-container">
              <h2 className="text-lg font-semibold mb-4">Shop List</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {shops?.map((shop) => (
                  <div key={shop._id} className="relative bg-black rounded-lg overflow-hidden group cursor-pointer" onClick={() => setSelectedShopList(shop)}>
                    <img src={shop.banner.url} alt="Shop Cover" className="w-full h-32 object-cover" />
                    <div className="absolute -mt-6 ml-4">
                      <img src={shop.avatar.url} alt="Shop Logo" className="w-12 h-12 object-cover rounded-full border-2 border-white" />
                    </div>
                    <div className="pt-8 pb-4">
                      <h3 className="text-md font-bold text-center mt-2">{shop.name}</h3>
                      <p className="text-sm text-gray-600 text-center">{shop.description}</p>
                    </div>
                  </div>
                ))}
              </div>

              </div>
              <div>
              <MapProductAdSlider />
              </div>
              {/* Map */}
              <div className="lg:w-full map-container">  <MapComponent selectedBrand={selectedBrand} selectedShopList={selectedShopList} /> </div>
            </div>
          </section>
        </main>
      </div>
    </div>
    </div>
  )
}