import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  likeOrUnlikePost,
  fetchComments,
  postComment,
  editPost,
  deletePost,
  deleteComment,
  editComment,
  resharePost
} from '../redux/actions/post'; // Update the import path according to your structure
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';
import Cookies from 'js-cookie';

// Import MUI components and icons as needed
import { Box, Divider, IconButton, Typography } from "@mui/material";
import {
  ChatBubbleOutlineOutlined,
  ChatBubble,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import { Popover, Transition } from '@headlessui/react';
import {
    EllipsisHorizontalIcon,
  } from '@heroicons/react/24/outline';

// Import your local components
import FlexBetween from "../components/FlexBetween";
import Friend from "../components/Friend";
import WidgetWrapper from "../components/WidgetWrapper";
import ProfileSwitchButton from "../components/ProfileSwitchButton";

const PostWidgetRedux = ({
  postId,
  postUserId,
  owner,
  name,
  description,
  profileType,
  location,
  mediaPath,
  avatar,
  likes: initialLikes,
  comments,
  createdAt,
  onShareSuccess,
  resharedBy,
  reshareDescription,
  reshareAvatar
}) => {
    const [isComments, setIsComments] = useState(false);
    const dispatch = useDispatch();
    const token = Cookies.get('token');
    const { user } = useSelector((state) => state.user);
    const { seller } = useSelector((state) => state.seller);
    const loggedInUserId = user?._id;
    const loggedInSellerId = seller?._id;
    const [scrollPosition, setScrollPosition] = useState(0);
    const userProfile = useSelector(state => state.user);
    const sellerProfile = useSelector(state => state.seller); 
    const [showReshareInput, setShowReshareInput] = useState(false);
    const [reshareInput, setReshareInput] = useState('');
    const [isReshare, setIsReshare] = useState(!!resharedBy);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editedDescription, setEditedDescription] = useState(description);
    const [editedReshareDescription, setEditedReshareDescription] = useState(reshareDescription);
    const [commentUpdateTrigger, setCommentUpdateTrigger] = useState(0);
    const formattedDate = format(new Date(createdAt), "PPP");
    const [currentPage, setCurrentPage] = useState(1);
    const [allCommentsLoaded, setAllCommentsLoaded] = useState(false);
    const currentProfileType = useSelector(state => state.user.currentProfile); // Assuming this holds either 'User' or 'Shop'
    const currentProfileId = currentProfileType === 'User' ? loggedInUserId : loggedInSellerId;
    const [isLiked, setIsLiked] = useState(initialLikes[currentProfileId] === true);
    const [likeCount, setLikeCount] = useState(initialLikes ? Object.keys(initialLikes).length : 0);
    const [commentText, setCommentText] = useState('');
    const [fetchedComments, setFetchedComments] = useState([]);
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [newCommentText, setNewCommentText] = useState('');
    const currentProfileName = sessionStorage.getItem('currentProfile');
    const currentProfile = useSelector(state => state.user.currentProfile);
    const commentsData = useSelector((state) => state.posts.comments[postId] || []);
    const postData = useSelector((state) => state.posts.posts.find((post) => post._id === postId));
    const [newComments, setNewComments] = useState([]);

    const toggleEditMode = (e) => {
        if (e) e.preventDefault();
        if (!isEditMode) {
            setScrollPosition(window.scrollY);
        } else {
            // Restore the scroll position when exiting edit mode
            window.scrollTo(0, scrollPosition);
        }
    
        setIsEditMode(!isEditMode);
    
        if (isEditMode) {
            setEditedDescription(description);
        }
    };
    const handleCancelReshare = () => {
        setShowReshareInput(false); // This will hide the reshare input field
        setReshareInput(''); // Optional: Clear the input field
      };
      const handleShowReshareInput = () => {
        setReshareInput('');
        setShowReshareInput(true);
      };


      const isVideo = (path) => {
        return path && (path.endsWith(".mp4") || path.endsWith(".webm")); // Add other video formats if needed
      }



  const handleCancelEdit = () => {
    setEditedDescription(description); // Reset to original description
    setEditedReshareDescription(reshareDescription); // Reset to original reshare description
    setIsEditMode(false); // Exit edit mode
  };
 

  useEffect(() => {
    dispatch(fetchComments(postId, currentPage)); // Fetch current page of comments
  }, [postId, dispatch, currentPage, commentUpdateTrigger]); 

  const loadMoreComments = () => {
    const nextPage = currentPage + 1;
    dispatch(fetchComments(postId, nextPage));
    setCurrentPage(nextPage);
  };

  const handleLikeOrUnlikePost = () => {
    // Optimistic UI update
    setIsLiked(!isLiked); // Toggle the like status
    setLikeCount(isLiked ? likeCount - 1 : likeCount + 1); // Update the like count
  
    // Dispatch the like/unlike action
    dispatch(likeOrUnlikePost(postId, currentProfile))
      .then((response) => {
        // Handle successful response here if needed
        // The Redux store should be updated by the reducer handling LIKE_POST_SUCCESS action
      })
      .catch((error) => {
        // Revert the optimistic UI update in case of error
        setIsLiked(isLiked); // Revert the like status
        setLikeCount(isLiked ? likeCount + 1 : likeCount - 1); // Revert the like count
        toast.error('Failed to update like status');
      });
  };
  
  
  // Effect hook to update like status based on Redux store state
  useEffect(() => {
    if (postData) {
      // Check if the current user's ID is a key in the likes object and if it is set to true
      const isPostLiked = !!postData.likes[currentProfileId];
      
      // Count the total number of likes by checking how many keys have the value true
      const likeCount = Object.values(postData.likes).filter(value => value === true).length;
  
      setIsLiked(isPostLiked);
      setLikeCount(likeCount);
    }
  }, [postData, currentProfileId]);
  
  


const handleCommentSubmit = (e) => {
    e.preventDefault();
    console.log(`Submitting comment: ${commentText} for post ID: ${postId} as ${currentProfile}`);
    dispatch(postComment(postId, commentText, currentProfile))
      .then(() => {
        setCommentText('');
        setCommentUpdateTrigger(prev => prev + 1); // Trigger re-fetching comments
      })
      .catch((error) => {
        console.error('Error posting comment:', error);
        toast.error('Failed to post comment');
      });
  };
  

  const handleEditSubmit = (e) => {
    e.preventDefault();
    dispatch(editPost(postId, editedDescription, reshareDescription));
    setIsEditMode(false);
  };

  const handleDeletePost = (e) => {
    e.preventDefault();
    dispatch(deletePost(postId));
    // Optionally call onPostDelete from parent component if needed
  };

  const handleDeleteComment = (commentId) => {
    dispatch(deleteComment(postId, commentId));
    setCommentUpdateTrigger(prev => prev + 1);
  };

  const handleEditComment = (commentId, newText) => {
    dispatch(editComment(postId, commentId, newText));
    setCommentUpdateTrigger(prev => prev + 1);
  };


const handleSharePost = () => {
    const reshareData = {
      profileType: currentProfile, // Ensure you have access to the current profile
      reshareDescription: reshareInput, // Ensure you have the reshare description from your component's state
    };
  
    dispatch(resharePost(postId, reshareData))
      .then(() => {
        setShowReshareInput(false);
        setReshareInput(''); // Clear the reshare input field
        toast.success('Post reshared successfully');
      })
      .catch((error) => {
        toast.error('Failed to reshare post');
      });
  };

  
  const renderEditForm = () => (
    <form onSubmit={handleEditSubmit} style={{ width: '100%' }}>
      <textarea
      autoFocus
        className="edit-description-textarea"
        value={isReshare ? editedReshareDescription : editedDescription}
        onChange={(e) => isReshare ? setEditedReshareDescription(e.target.value) : setEditedDescription(e.target.value)}
        rows={3}
        style={{
            width: '100%',
            background: 'none',
            border: 'none',
            fontSize: 'inherit', // Match font size of the surrounding text
            color: 'inherit', // Match text color
            lineHeight: 'inherit', // Match line height
            resize: 'none', // Hide resize handle
            outline: 'none', // Hide outline on focus
          }}
      />
      <div className="flex justify-end space-x-2 mt-3">
        <button type="submit" className="inline-flex items-center justify-center rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200 ease-in-out">
          Save Changes
        </button>
        <button type="button" onClick={handleCancelEdit} className="inline-flex items-center justify-center rounded-md bg-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-200 ease-in-out">
          Cancel
        </button>
      </div>
    </form>
  );

  return (
    <>
    <ToastContainer />
    <WidgetWrapper m="2rem 0" className="relative">
      {/* Conditional rendering of Edit and Delete buttons */}
      {(loggedInUserId === owner || loggedInSellerId === owner) && (
    <Popover className="relative inline-block">
      <Popover.Button className="inline-flex items-center text-sm font-semibold text-gray-900">
        <EllipsisHorizontalIcon className="h-10 w-10" aria-hidden="true" />
      </Popover.Button>
  
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute z-10 mt-2 shadow-lg ring-1 ring-black">
          <div className="py-1">
          <a
            href="#"
            onClick={(e) => toggleEditMode(e)} // Pass the event object
            className="block px-4 py-2 text-sm text-gray-700"
            >
            Edit
            </a>
            <a
            href="#"
            onClick={(e) => handleDeletePost(e)} // Pass the event object
            className="block px-4 py-2 text-sm text-gray-700"
            >
            Delete
            </a>

          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )}
        
  
  {/* Reshare section */}
  {resharedBy && (
    <div style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px', marginBottom: '10px' }}>
      <div className="reshare-header" style={{ display: 'flex', alignItems: 'left' }}>
        <img 
          src={reshareAvatar || 'default-avatar-url'} 
          alt={resharedBy}
          style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }} 
        />
        <span style={{ fontWeight: 'bold' }}>Reshared by {resharedBy}</span>
      </div>
      {reshareDescription && (
      <div className="reshare-description" style={{ marginLeft: '40px', marginTop: '5px' }}>
        {isEditMode && isReshare ? renderEditForm() : reshareDescription}
      </div>
      )}
     </div>
    )}
  
        {/* Reshare input field */}
      {showReshareInput && (
        <div className="reshare-container">
          <textarea
            className="reshare-textarea"
            value={reshareInput}
            onChange={(e) => setReshareInput(e.target.value)}
            placeholder="Add a description to your reshare..."
          />
          <button className="reshare-button" onClick={handleSharePost}>
            Share
          </button>
          <button className="reshare-button cancel-button" onClick={handleCancelReshare}>
            Cancel
          </button>
        </div>
      )}
          <Friend
            friendId={owner}
            friendType={profileType}
            name={name}
            subtitle={location}
            avatar={avatar}
          />
          {/* Date Display at the bottom right */}
          <div style={{ fontSize: '10px' }} className="px-20 text-gray-500">
            {formattedDate}
          </div>
  {/* Post Description or Edit Form */}
  <div>
        {isEditMode && !isReshare ? renderEditForm() : (
          <Typography color="#1f9c29" sx={{ mt: "1rem" }}>{description}</Typography>
        )}
      </div>
  
          {/* Media Rendering: Image or Video */}
        {mediaPath && (
          isVideo(mediaPath) ? (
            <video width="100%" controls>
              <source src={mediaPath} type="video/mp4" /> 
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              width="100%"
              height="auto"
              alt="post media"
              style={{ borderRadius: "0.75rem", marginTop: "0.75rem" }}
              src={mediaPath}
            />
          )
        )}
          <FlexBetween mt="0.25rem">
            <FlexBetween gap="1rem">
              <FlexBetween gap="0.3rem">
                <IconButton onClick={handleLikeOrUnlikePost}>
                  {isLiked ? (
                    <img src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1702746849/thumbs-up-active_biz9jp.svg" alt="Liked" style={{ width: '24px', height: '24px' }} />
                    ) : (
                      <img src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1702746849/thumbs-up_pqr2o9.svg" alt="Not Liked" style={{ width: '24px', height: '24px' }} />
                    )}
                </IconButton>
                <Typography>{likeCount}</Typography>
              </FlexBetween>
  
              <FlexBetween gap="0.3rem">
            <IconButton onClick={() => setIsComments(!isComments)}>
                {commentsData.length > 0 ? (
                <ChatBubble /> // Filled chat bubble icon for posts with comments
                ) : (
                <ChatBubbleOutlineOutlined /> // Outlined chat bubble icon for posts without comments
                )}
            </IconButton>
            <Typography>{commentsData.length}</Typography> 
            </FlexBetween>
            </FlexBetween>
  
            <IconButton onClick={handleShowReshareInput} title="Share Post">
            <ShareOutlined />
          </IconButton>
          </FlexBetween>
  
      {/* Comments Section */}  
{isComments && (
  <div className="mt-4 space-y-4">
    {commentsData.map((commentObj) => (
      <div key={commentObj?._id} className="flex items-start space-x-4">
        {/* Commenter Avatar */}
        <div className="flex-shrink-0">
          <img
            className="h-10 w-10 rounded-full"
            src={commentObj && (commentObj.profileType === 'Shop'
            ? (commentObj.shopId?.avatar?.url || newComments.shopId?.avatar?.url)
            : (commentObj.userId?.avatar?.url || newComments.userId?.avatar?.url))}
            alt={commentObj?.profileType === 'Shop'
                 ? commentObj?.shopId?.name || 'Shop Name'
                 : commentObj?.userId?.name || 'User Name'}
          />
        </div>
        <div className="min-w-0 flex-1">
          {/* Comment Text and Dropdown for Edit/Delete */}
          <span className="block text-sm font-medium text-white pb-1">
            {commentObj?.profileType === 'Shop' && commentObj.shopId
             ? commentObj?.shopId.name
             : commentObj?.userId && commentObj?.userId?.name}
          </span>
          <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} className="p-3 rounded-lg shadow">
          {editingCommentId === (commentObj?._id) ? (
              <input
                type="text"
                style={{
                  backgroundColor: '#424242',
                  color: 'white',
                  border: '1px solid #333',
                }}
                value={newCommentText}
                onChange={(e) => setNewCommentText(e.target.value)}
              />
            ) : (
              <span className="block text-sm text-white">{commentObj?.commentText}</span>
            )}
          </div>

          {/* Dropdown Menu for Edit and Delete */}
          {((commentObj?.profileType === 'User' && commentObj.userId && loggedInUserId === commentObj.userId._id) ||
           (commentObj?.profileType === 'Shop' && commentObj.shopId && loggedInSellerId === commentObj.shopId._id)) && (
            <Popover className="relative inline-block">
              <Popover.Button>
                <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="z-10 mt-2 bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    <button
                      onClick={() => {
                        setEditingCommentId(commentObj?._id);
                        setNewCommentText(commentObj?.commentText);
                      }}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteComment(commentObj?._id)}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Delete
                    </button>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          )}

          {/* Save Button for Edited Comment */}
          {editingCommentId === commentObj?._id && (
            <button
              onClick={(e) => {
                e.preventDefault(); // Prevent form submission
                handleEditComment(editingCommentId, newCommentText); // Call the edit function with the new text
                setEditingCommentId(null); // Exit edit mode
                setNewCommentText(''); // Reset the input field
              }}
              className="text-green-500 ml-2"
            >
              Save
            </button>
          )}
        </div>
      </div>
    ))}

    {/* Load More Comments Button - Only shown if there are 10 or more comments */}
        {!allCommentsLoaded && commentsData.length >= 10 && (
        <div className="flex justify-center">
            <button
            onClick={loadMoreComments}
            className="load-more-comments-btn mt-2 px-4 py-2 bg-green-700 text-white rounded-lg hover:bg-green-500 shadow-md transition duration-300 ease-in-out"
            >
            Load More Comments
            </button>
        </div>
        )}
  </div>
)}

{/* Comment Input Section */}
<div className="mt-6">
  <div className="flex items-start space-x-4">
    <ProfileSwitchButton />
    <div className="min-w-0 flex-1">
      <form className="relative" onSubmit={handleCommentSubmit}>
        <textarea
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          rows={3}
          className="block w-full resize-none border-0 bg-transparent px-1.5 py-1.5 text-white placeholder:text-white focus:border-green-500 focus:ring-0 sm:text-sm"
          placeholder="Add your comment..."
        />
        <div className="absolute bottom-0 right-0 flex justify-end py-2 pr-2">
          <button
            type="submit"
            className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500"
          >
            Post
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

    </WidgetWrapper>
    </>
  );
   }
  export default PostWidgetRedux;