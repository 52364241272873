import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { getAllEventsShop } from "../../redux/actions/event";
import ProductCard from "../Route/ProductCard/ProductCard";
import Ratings from "../Products/Ratings";
import PostsWidgetRedux from "../../widgets/PostsWidgetRedux";
import MyPostWidgetRedux from "../../widgets/MyPostWidgetRedux";
import FriendListWidget from "../../widgets/FriendListWidget";
import axios from "axios";
import { server } from "../../server";
import IndividualShopProducts from "./IndividualShopProducts";
import ConnectionsWidget from "../Followers/ConnectionsWidget";


const ShopProfileData = ({ isOwner }) => {
  const { products } = useSelector((state) => state.products);
  const { events } = useSelector((state) => state.events);
  const { handle } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const picturePath = user?.picturePath;
  const _id = user?._id;
  const [shopId, setShopId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${server}/shop/handle-to-id/${handle}`)
      .then(res => {
        const id = res.data.id;
        setShopId(id);
        setIsLoading(false);
        dispatch(getAllProductsShop(id));
        dispatch(getAllEventsShop(id));
      })
      .catch(error => console.log(error));
      setIsLoading(false);
  }, [handle, dispatch]);

  const [activeTab, setActiveTab] = useState('Products');

  const tabs = [
    { name: 'Posts', current: activeTab === 'Posts' },
    { name: 'Products', current: activeTab === 'Products' },
    { name: 'Running Events', current: activeTab === 'Running Events' },
    { name: 'Connections', current: activeTab === 'Connections' },
    { name: 'Reviews', current: activeTab === 'Reviews' },
  ];


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const allReviews =
    products && products.map((product) => product.reviews).flat();

     
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content-container">
      {/* Tab navigation here */}
      <div className="sm:block">
        <nav className="nav" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => setActiveTab(tab.name)}
              className={classNames(
                tab.current
                  ? 'text-green-500 border-b-2 border-green-500'
                  : 'text-white border-b-2 border-transparent hover:text-green-500',
                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm tab-button'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      <br />
      {activeTab === 'Posts' && (
        <div className="w-full">
          {/* Render the new components here */}
          <MyPostWidgetRedux picturePath={picturePath} />
          <PostsWidgetRedux ownerId={shopId} profileType="Shop" isProfile={true} />
        </div>
      )}

     {activeTab === 'Products' && (
       <IndividualShopProducts shopId={shopId} />
      )}


      {activeTab === 'Running Events' && (
        <div className="w-full">
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-1 md:gap-[25px] lg:grid-cols-2 lg:gap-[25px] xl:grid-cols-2 xl:gap-[20px] mb-12 border-0">
            {events &&
              events.map((event, index) => (
                <ProductCard
                  data={event}
                  key={index}
                  isShop={true}
                  isEvent={true}
                />
              ))}
          </div>
          {events && events.length === 0 && (
            <h5 className="w-full text-center py-5 text-[18px]">
              No Events found for this shop!
            </h5>
          )}
        </div>
      )}
      {activeTab === 'Connections' && (
        <div className="w-full">
          <ConnectionsWidget />
        </div>
      )}

      {activeTab === 'Reviews' && (
        <div className="w-full">
          {allReviews &&
            allReviews.map((review, index) => (
              <div className="w-full flex my-4" key={index}>
                <img
                  src={review.user.avatar?.url}
                  className="w-[50px] h-[50px] rounded-full"
                  alt=""
                />
                <div className="pl-2">
                  <div className="flex w-full items-center">
                    <h1 className="font-[600] pr-2">{review.user.name}</h1>
                    <Ratings rating={review.rating} />
                  </div>
                  <p className="font-[400] text-[#000000a7]">{review.comment}</p>
                  <p className="text-[#000000a7] text-[14px]">{"2 days ago"}</p>
                </div>
              </div>
            ))}
          {allReviews && allReviews.length === 0 && (
            <h5 className="w-full text-center py-5 text-[18px]">
              No Reviews found for this shop!
            </h5>
          )}
        </div>
      )}

      {isOwner && (
        <Link
          to={`/dashboard/${handle}`}
          className="flex justify-center items-center px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-2 md:text-lg md:px-10"
        >
          Go to Dashboard
        </Link>
      )}
    </div>
  );
};

export default ShopProfileData;

