import React, { useState } from 'react';
import axios from 'axios';

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email_address: email,
      status: 'subscribed', // 'subscribed' or another status you want for new sign-ups
    };

    try {
      await axios.post(`https://us21.api.mailchimp.com/3.0/lists/${process.env.REACT_APP_MAILCHIMP_LIST_ID}/members/`, data, {
        headers: {
          'Authorization': `apikey ${process.env.REACT_APP_MAILCHIMP_API_KEY}`,
          'Content-Type': 'application/json',
        }
      });
      alert('Thank you for subscribing!');
      // Handle success (clear form, show message, etc.)
    } catch (error) {
      console.error('There was an error subscribing:', error.response.data);
      // Handle error (show error message, etc.)
    }
  };

  return (
    <div className="md:flex md:justify-between md:items-center sm:px-12 px-4 bg-green-700 py-7">
      <h1 className="lg:text-4xl text-3xl md:mb-0 mb-6 lg:leading-normal font-semibold md:w-2/5">
        <span className="text-[#56d879]">Subscribe</span> for updates, <br />
        events and offers
      </h1>
      <div>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email..."
            className="text-gray-800 sm:w-72 w-full sm:mr-5 mr-1 lg:mb-0 mb-4 py-2.5 rounded px-2 focus:outline-none"
          />
          <button type="submit" className="bg-[#56d879] hover:bg-teal-500 duration-300 px-5 py-2.5 rounded-md text-white md:w-auto w-full">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewsletterSignup;
