import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { switchProfile, setActiveSeller, loadSellers } from '../../redux/actions/user';
import { CheckCircleIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from "react-router-dom";

const ProfileSwitchButtonShop = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const userProfile = useSelector((state) => state.user.user);
  const sellerProfiles = useSelector((state) => state.seller.sellers);
  const { seller, isSeller } = useSelector((state) => state.seller);
  

  useEffect(() => {
    if (!sellerProfiles.length) {
      console.log('Loading sellers...');
      dispatch(loadSellers());
    }
  }, [dispatch, sellerProfiles.length]);

let currentProfileAvatarUrl = '';
if (isSeller && seller) {
  currentProfileAvatarUrl = seller?.avatar?.url;
} else if (currentProfile === 'User' && userProfile) {
  currentProfileAvatarUrl = userProfile?.avatar?.url;
}

  const handleSwitchProfile = (index) => {
    if (index === undefined) {
      dispatch(switchProfile());
      sessionStorage.setItem('currentProfile', 'User');
      navigate(`/dashboard/${userProfile.handle}`); // Navigate to user dashboard
    } else {
      const selectedSellerProfile = sellerProfiles[index];
      dispatch(setActiveSeller(selectedSellerProfile));
      sessionStorage.setItem('currentProfile', selectedSellerProfile?.name);
      navigate(`/dashboard/${selectedSellerProfile?.handle}`); // Navigate to seller dashboard
    }
    setOpenModal(false);
    window.location.reload();
  };


  const hasBothProfiles = userProfile && sellerProfiles.length > 0;

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 420, 
    maxWidth: '90%', 
    backgroundColor: 'rgba(0, 0, 0, 0.8)', 
    boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.25)', 
    padding: '16px',
    borderRadius: 4,
    overflow: 'hidden',
    border: '2px solid green' 
  };
  
  const modalItemStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    margin: '5px 0',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    }
  };

  const modalItemImageStyle = {
    height: '30px',
    width: '30px',
    borderRadius: '100%',
    objectFit: 'cover',
    marginRight: '10px',
  };

  const checkIconStyle = {
    height: '24px',
    width: '24px',
    marginLeft: 'auto',
    color: 'green'
  };

  const avatarButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'green', // Adjust as needed
    borderRadius: '50%',
    border: '1px solid #ccc', // Adjust border color as needed
    width: '42px',
    height: '42px',
    position: 'relative',
  };

  const swapIconStyle = {
    position: 'absolute',
    right: '-10px',
    bottom: '-10px',
    backgroundColor: 'green',
    borderRadius: '50%',
    border: '1px solid #ccc',
    padding: '3px',
  };

  return (
    <div>
      {userProfile ? (
        <>
          <button onClick={() => setOpenModal(true)} style={avatarButtonStyle}>
            <img
              src={currentProfileAvatarUrl}
              alt={`${currentProfile} Avatar`}
              style={{ height: '42px', width: '42px', maxWidth: '42px', borderRadius: '100%' }}
            />
            {hasBothProfiles && <SwapHorizIcon style={swapIconStyle} />}
          </button>

          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="profile-switch-modal"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <IconButton
                aria-label="close"
                onClick={() => setOpenModal(false)}
                sx={{
                  position: 'absolute', 
                  top: 8, 
                  right: 8, 
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Switch Profile
              </Typography>

              {sellerProfiles.map((seller, index) => (
                <div key={index} style={modalItemStyle} onClick={() => handleSwitchProfile(index)}>
                  <img src={seller.avatar.url} alt={`${seller.name} Avatar`} style={modalItemImageStyle} />
                  {seller.name}
                  {currentProfile === seller.name && <CheckCircleIcon style={checkIconStyle} />}
                </div>
              ))}
            </Box>
          </Modal>
        </>
      ) : (
        <Link to="/login">
          <UserCircleIcon className="h-12 w-12 text-gray-400" aria-hidden="true" />
        </Link>
      )}
    </div>
  );
};

export default ProfileSwitchButtonShop;
