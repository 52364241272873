import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { server } from '../../server';

const RegionForm = ({ currentRegion, setCurrentRegion, fetchRegions }) => {
    const [formData, setFormData] = useState({ name: '', states: [], boundaries: {} });
    const [states, setStates] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        const fetchStates = async () => {
            const { data } = await axios.get(`${server}/region/states`);
            const options = data.map(state => ({ value: state, label: state }));
            setStates(options);

            if (currentRegion) {
                const selected = currentRegion.states.map(state => ({ value: state, label: state }));
                setSelectedOptions(selected);
                setFormData({
                    ...currentRegion,
                    states: currentRegion.states || []
                });
            }
        };

        fetchStates();
    }, [currentRegion]);

    const handleChangeSelect = selectedOptions => {
        setSelectedOptions(selectedOptions);
        setFormData(prev => ({
            ...prev,
            states: selectedOptions.map(option => option.value)
        }));
    };

    const handleRemoveState = (stateToRemove) => {
        const newSelectedOptions = selectedOptions.filter(option => option.value !== stateToRemove.value);
        setSelectedOptions(newSelectedOptions);
        setFormData(prev => ({
            ...prev,
            states: newSelectedOptions.map(option => option.value)
        }));
    };

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (currentRegion && currentRegion._id) {
            await handleEdit();
        } else {
            await handleCreate();
        }
    };

    const handleCreate = async () => {
        try {
            await axios.post(`${server}/region/regions`, formData);
            fetchRegions();
            setCurrentRegion(null);
        } catch (error) {
            console.error('Failed to create region', error);
        }
    };

    const handleEdit = async () => {
        try {
            await axios.put(`${server}/region/regions/${currentRegion._id}`, formData);
            fetchRegions();
            setCurrentRegion(null);
        } catch (error) {
            console.error('Failed to update region', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="region-form">
            <input
                type="text"
                name="name"
                placeholder="Region Name"
                value={formData.name}
                onChange={handleChangeInput}
            />
            <Select
                isMulti
                name="states"
                options={states}
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedOptions}
                onChange={handleChangeSelect}
                placeholder="Select states..."
            />
            <div className="selected-states">
                {selectedOptions.map(option => (
                    <span key={option.value} className="state-badge">
                        {option.label} <button type="button" onClick={() => handleRemoveState(option)}>&times;</button>
                    </span>
                ))}
            </div>
            <button type="submit">Save Region</button>
            <button type="button" className="cancel-button" onClick={() => setCurrentRegion(null)}>Cancel</button>
        </form>
    );
};

export default RegionForm;












