import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../server';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SponsoredDashboard = () => {
    const [sponsoredProducts, setSponsoredProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null); // Now will store the product object or null
    const [isLoading, setIsLoading] = useState(true);
    const [timeResolution, setTimeResolution] = useState('daily'); // 'daily' or 'hourly'
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Clicks',
                data: [],
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
            },
            {
                label: 'Impressions',
                data: [],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    });

    useEffect(() => {
        const fetchSponsoredProducts = async () => {
            setIsLoading(true);
            try {
                
                const token = localStorage.getItem('token'); 
                const response = await axios.get(`${server}/sponsored/sponsored-products-dash`, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                    }
                });
                setSponsoredProducts(response.data);
                processChartData(response.data);
            } catch (error) {
                console.error('Error fetching sponsored products:', error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchSponsoredProducts();
    }, []); 
    

    useEffect(() => {
        processChartData(sponsoredProducts);
    }, [sponsoredProducts, selectedProduct, timeResolution]);

    const processChartData = (data) => {
        // Initialization of the aggregationMap remains the same
        const aggregationMap = {};
    
        data.forEach(ad => {
            // Example for processing click timestamps
            ad.clickTimestamps.forEach(timestamp => {
                let key;
                const date = new Date(timestamp);
                if (timeResolution === 'daily') {
                    key = date.toLocaleDateString();
                } else { // 'hourly'
                    key = `${date.toLocaleDateString()} ${date.getHours()}:00`;
                }
    
                if (!aggregationMap[key]) {
                    aggregationMap[key] = { clicks: 0, impressions: 0 };
                }
                aggregationMap[key].clicks += 1;
            });
    
            // Similar processing for impression timestamps
            ad.impressionTimestamps.forEach(timestamp => {
                let key;
                const date = new Date(timestamp);
                if (timeResolution === 'daily') {
                    key = date.toLocaleDateString();
                } else { // 'hourly'
                    key = `${date.toLocaleDateString()} ${date.getHours()}:00`;
                }
    
                if (!aggregationMap[key]) {
                    aggregationMap[key] = { clicks: 0, impressions: 0 };
                }
                aggregationMap[key].impressions += 1;
            });
        });
    
        // Convert the aggregationMap into the format needed for the chart
        const labels = Object.keys(aggregationMap).sort();
        const clicks = labels.map(label => aggregationMap[label].clicks);
        const impressions = labels.map(label => aggregationMap[label].impressions);
    
        setChartData({
            labels,
            datasets: [
                {
                    label: 'Clicks',
                    data: clicks,
                    borderColor: 'rgb(75, 192, 192)',
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                },
                {
                    label: 'Impressions',
                    data: impressions,
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
            ],
        });
    };
    
    

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins: {
          title: {
            display: true,
            text: selectedProduct ? `Data for ${selectedProduct.name}` : 'Total Clicks and Impressions',
            font: {
                size: 24, // Increase the font size
            },
            color: 'white'
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                label += context.parsed.y;
                return label;
              },
            },
          },
          legend: {
            onClick: (e, legendItem, legend) => {
              const index = legendItem.datasetIndex;
              const chart = legend.chart;
              const meta = chart.getDatasetMeta(index);
          
              // Toggle the visibility
              meta.hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null;
          
              // Trigger an update and render to show the change
              chart.update();
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Date',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Count',
            },
            beginAtZero: true,
          },
        },
        elements: {
          line: {
            tension: 0.4,
          },
          point: {
            hoverRadius: 7,
            radius: 5,
          },
        },
      };

    const toggleAdActiveStatus = async (adId, isActive) => {
        try {
            await axios.post(`${server}/sponsored/toggle-status`, { adId, isActive }, { withCredentials: true });
            setSponsoredProducts(prev => prev.map(ad => ad._id === adId ? { ...ad, isActive } : ad));
        } catch (error) {
            console.error('Error toggling ad status:', error);
        }
    };

    return (
        <div className="sponsored-dashboard">
            <h2 className="dashboard-title">Sponsored Products</h2>
            {isLoading ? (
                <p>Loading sponsored products...</p>
            ) : sponsoredProducts.length > 0 ? (
                <>
                    <button onClick={() => setSelectedProduct(null)} className="view-total-button">
                        View Total Ad Data
                    </button>
                    <div>
                <button
                    className="daily-ad-graph-button"
                    onClick={() => setTimeResolution('daily')}
                    disabled={timeResolution === 'daily'}
                >
                    Daily View
                </button>
                <button
                    className="hourly-ad-graph-button"
                    onClick={() => setTimeResolution('hourly')}
                    disabled={timeResolution === 'hourly'}
                >
                    Hourly View
                </button>
            </div>


                    <div style={{ height: '500px', width: '100%', margin: '20px 0' }}>
                        <Line data={chartData} options={options} />
                    </div>

                    <table className="sponsored-table">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Total Ad Campaign</th>
                                <th>Spent So Far</th>
                                <th>Ad Duration (days)</th>
                                <th>Active Status</th>
                                <th>Click Count</th>
                                <th>Impressions</th>
                                <th>Cost Per Click</th>
                                <th>Cost Per Impression</th>
                                <th>Toggle Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sponsoredProducts.map((sponsored) => {
                                // Calculate Cost Per Click (CPC)
                                const costPerClick = sponsored.clicks > 0 ? (sponsored.adSpend / sponsored.clicks).toFixed(2) : 0;
                                const costPerImpression = sponsored.impressions > 0 ? (sponsored.adSpend / sponsored.impressions).toFixed(2) : 0;

                                return (
                                    <tr key={sponsored._id} style={{ cursor: 'pointer' }}>
                                        <td onClick={() => setSelectedProduct({ _id: sponsored._id, name: sponsored.product?.name || 'Product not found' })}>
                                            {sponsored.product && sponsored.product.images && sponsored.product.images[0] ? (
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <img src={sponsored.product.images[0].url} alt={sponsored.product.name} style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px' }} />
                                                    <span>{sponsored.product.name}</span>
                                                </div>
                                            ) : (
                                                'Product not found'
                                            )}
                                        </td>
                                        <td>${sponsored.adSpend.toFixed(2)}</td>
                                        <td>${sponsored.spentSoFar.toFixed(2)}</td>
                                        <td>{sponsored.adDuration}</td>
                                        <td>{sponsored.isActive ? 'Active' : 'Inactive'}</td>
                                        <td>{sponsored.clicks}</td>
                                        <td>{sponsored.impressions}</td>
                                        <td>${costPerClick}</td> 
                                        <td>${costPerImpression}</td>
                                        <td>
                                            <button onClick={() => toggleAdActiveStatus(sponsored._id, !sponsored.isActive)}>
                                                {sponsored.isActive ? 'Pause' : 'Resume'}
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </>
            ) : (
                <p>No sponsored products found.</p>
            )}
        </div>
    );
};

export default SponsoredDashboard;



