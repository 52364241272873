import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { server } from "../../server";

const SponsoredBrandCarousel = () => {
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-brands`, { withCredentials: true });
                const activeBrands = response.data.filter(ad => ad.isActive && ad.brand);
                setBrands(activeBrands);
            } catch (error) {
                console.error('Error fetching brands:', error);
            }
        };

        fetchBrands();
    }, []);

    useEffect(() => {
        brands.forEach(brand => {
            axios.post(`${server}/sponsored/impression/${brand._id}`, {}, { withCredentials: true })
                .then(() => console.log('Impression logged for brand:', brand._id))
                .catch(error => console.error('Error logging impression:', error));
        });
    }, [brands]);

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`);
            console.log('Click logged for brand ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    return (
        <Carousel>
            {brands.map(({ _id, brand }) => (
                <Item key={_id} brand={brand} logAdClick={() => logAdClick(_id)} />
            ))}
        </Carousel>
    );
};

const Item = ({ brand, logAdClick }) => {
    return (
        <Paper style={{
            background: `url(${brand.coverImage.url}) center center`,
            backgroundSize: 'cover',
            height: 400,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            color: '#fff'
        }}>
            <div style={{
                background: 'rgba(0, 0, 0, 0.5)', 
                borderRadius: '10px',
                padding: '20px',
                textAlign: 'center'
            }}>
                <h2 style={{ fontSize: '2em' }}>{brand.name}</h2>
                <p style={{ fontSize: '1.25em' }}>{brand.description}</p>
                <Button component={Link} to={`/brand/${brand.name}`} onClick={logAdClick} variant="contained" style={{backgroundColor: '#4caf50', marginTop: '20px'}}>
                    Visit Brand
                </Button>
            </div>
        </Paper>
    )
}

export default SponsoredBrandCarousel;
