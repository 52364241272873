import React from 'react'
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import Categories from "../components/Route/Categories/Categories";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Events from "../components/Sales/Events";
import Footer from "../components/Layout/Footer";
import Sidebar from '../components/Layout/Sidebar';
import SignupCTA from '../components/SignupCTA';
import ShopBySeller from '../components/Shop/ShopBySeller';
import LocationTracker from '../components/Location/Locator';
import SponsoredProductCarousel from '../components/Sponsored/SponsoredProductsCarousel';
import SponsoredShopsCarousel from '../components/Sponsored/SponsoredShopsCarousel';
import BannerAd from '../components/Sponsored/BannerAd';
import HeroSliderHome from '../components/Route/Hero/HeroSliderHome';
import DualProductAdCarousel from '../components/Sponsored/DualProductAd';
import QuadProductAdCarousel from '../components/Sponsored/QuadProductAdCarousel';
import TopBannerAd from '../components/Sponsored/TopBannerAd';
import EventCarousel from '../components/Events/EventCarousel';
import SponsoredBrandCarousel from '../components/Sponsored/SponsoredBrandCarousel';
import RecommendedFriends from '../components/Followers/RecommendedFriends';


const HomePage = () => {
  return (
    <div>
        <Header activeHeading={1} />
        <Sidebar />
        <BannerAd />
        <TopBannerAd />
        <HeroSliderHome />
        <ShopBySeller />
        <DualProductAdCarousel />
        <SponsoredProductCarousel />
        <EventCarousel />
        <RecommendedFriends />
        <SponsoredShopsCarousel />
        <QuadProductAdCarousel />
        <LocationTracker />
        <Categories />
        <BestDeals />
        <SponsoredBrandCarousel />
        <Events />
        <FeaturedProduct />
        <Footer />
    </div>
  )
}

export default HomePage