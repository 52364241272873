import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from "../../server";
import { toast } from 'react-toastify'; 
import ChangeSubscription from './ChangeSubscription';

const UserSubscriptions = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);

    useEffect(() => {
        fetchSubscriptions();
    }, []);

    const fetchSubscriptions = async () => {
        try {
            const response = await axios.get(`${server}/subscriptions/my-subscriptions`, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            setSubscriptions(response.data);
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch subscriptions');
            setLoading(false);
            console.error(error);
        }
    };

    const confirmCancelSubscription = (subscriptionId) => {
        setShowModal(true);
        setSelectedSubscription(subscriptionId);
    };

    const cancelSubscription = async () => {
        try {
            await axios.post(`${server}/subscriptions/cancel-subscription`, { subscriptionId: selectedSubscription }, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            fetchSubscriptions(); 
            setShowModal(false);
            toast.success('Subscription cancelled successfully!');
        } catch (error) {
            console.error('Failed to cancel subscription', error);
            toast.error('Failed to cancel subscription');
            setShowModal(false);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <h2>My Subscriptions</h2>
            {showModal && (
    <div style={{
        position: 'fixed', 
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000
    }}>
        <div style={{
            width: '300px',
            backgroundColor: 'darkgrey', 
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
            textAlign: 'center', 
            position: 'relative' 
        }}>
            <h4>Are you sure you want to cancel this subscription?</h4>
            <button onClick={cancelSubscription} style={{
                marginRight: '10px',
                backgroundColor: '#4CAF50', 
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                padding: '10px 15px',
                cursor: 'pointer',
                fontSize: '16px'
            }}>Yes</button>
            <button onClick={() => setShowModal(false)} style={{
                backgroundColor: '#f44336', 
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                padding: '10px 15px',
                cursor: 'pointer',
                fontSize: '16px'
            }}>No</button>
        </div>
    </div>
)}

            {subscriptions.length > 0 ? (
                <ul className="user-subscriptions-list">
                    {subscriptions.map(subscription => (
                        <li key={subscription._id} className={`user-subscriptions-item ${subscription.status === 'active' ? 'user-subscriptions-item-active' : ''}`}>
                            <p className="user-subscriptions-detail">Tier: {subscription.tier}</p>
                            <p className="user-subscriptions-detail">Status: {subscription.status}</p>
                            <p className="user-subscriptions-detail">Next Billing Date: {new Date(subscription.currentPeriodEnd).toLocaleDateString()}</p>
                            {subscription.status === 'active' && (
                                <div className="active-subscription-controls">
                                    <ChangeSubscription currentSubscriptionId={subscription._id} />
                                    <button onClick={() => confirmCancelSubscription(subscription._id)} className="user-subscriptions-cancel-btn">
                                        Cancel Subscription
                                    </button>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No subscriptions found.</p>
            )}

        </div>
    );
};

export default UserSubscriptions;


