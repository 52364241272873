import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Box, Typography } from '@mui/material';
import EventCard from '../components/Events/EventCard'; 
import EventCreationForm from '../components/Events/EventCreationForm';
import { server } from "../server";
import Sidebar from '../components/Layout/Sidebar';
import Header from '../components/Layout/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyEvents from '../components/Events/MyEvents';
import { useSelector } from 'react-redux'; 

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'black',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const mainContentStyle = {
    paddingLeft: '10%',
    paddingRight: '10%'
};

const EventsPage = () => {
    const [events, setEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [filterDistance, setFilterDistance] = useState(50);
    const [filterTime, setFilterTime] = useState(168);
    const [userLocation, setUserLocation] = useState(() => {
        const savedLocation = sessionStorage.getItem('userLocation');
        return savedLocation ? JSON.parse(savedLocation) : null;
    });
    console.log('User Location from session:', userLocation);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        fetchEvents();
    }, [userLocation, filterDistance, filterTime]); 

    const fetchEvents = async () => {
        try {
            const response = await axios.get(`${server}/event/events`);
            const now = new Date();
            const fetchedEvents = response.data.map(event => ({
                ...event,
                distance: userLocation ? getDistanceFromLatLonInKm(
                    userLocation.latitude, 
                    userLocation.longitude, 
                    event.location.lat, 
                    event.location.lng
                ) : undefined,
                timeToEvent: Math.abs(new Date(event.date) - now) / 36e5 // Convert milliseconds to hours
            }));

            const filtered = fetchedEvents.filter(event =>
                (filterDistance === 'all' || event.distance <= filterDistance) &&
                (filterTime === 'all' || event.timeToEvent <= filterTime)
            ).sort((a, b) => a.distance - b.distance);

            setFilteredEvents(filtered);
            setEvents(fetchedEvents);
        } catch (error) {
            console.error('Failed to fetch events:', error);
            toast.error('Failed to load events.');
        }
    };
    

    const handleRSVP = (eventId) => {
        const updatedEvents = events.map(event => 
            event._id === eventId ? { ...event, isGoing: true } : event
        );
        setEvents(updatedEvents);
        setFilteredEvents(updatedEvents);
        toast.success('RSVP successful!');
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        const R = 6371;
        const dLat = deg2rad(lat2-lat1);
        const dLon = deg2rad(lon2-lon1);
        const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                  Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
                  Math.sin(dLon/2) * Math.sin(dLon/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        const distance = R * c;
        return distance;
    }

    function deg2rad(deg) {
        return deg * (Math.PI/180);
    }
    const handleFilterChange = (e, type) => {
        const value = e.target.value;
        if (type === 'distance') {
            setFilterDistance(value === 'all' ? 'all' : Number(value));
        } else if (type === 'time') {
            setFilterTime(value === 'all' ? 'all' : Number(value));
        }
    };

    if (!userLocation) {
        return <div>Loading location information...</div>;
    }

    return (
        <div>
            <Header />
            <Sidebar />
            <div style={mainContentStyle}>
                <main className="lg:pl-20">
                    <div className="xl:pl-96">
                        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
                        <select value={filterDistance} onChange={(e) => handleFilterChange(e, 'distance')} className="mb-4 bg-white border border-green-300 text-gray-700 py-2 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-500">
                            <option value="all">All Distances</option>
                            <option value="25">25 km</option>
                            <option value="50">50 km</option>
                            <option value="75">75 km</option>
                            <option value="100">100 km</option>
                            <option value="125">125 km</option>
                            <option value="150">150 km</option>
                        </select>
                        <select value={filterTime} onChange={(e) => handleFilterChange(e, 'time')} className="mb-4 bg-white border border-green-300 text-gray-700 py-2 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-500">
                            <option value="all">All Times</option>
                            <option value="24">Next 24 hours</option>
                            <option value="48">Next 48 hours</option>
                            <option value="72">Next 72 hours</option>
                            <option value="168">Next 7 days</option>
                        </select>
                            {filteredEvents.map(event => (
                                <EventCard key={event.id} event={event} onRSVP={handleRSVP} />
                            ))}
                        </div>
                    </div>
                </main>
                <aside className="fixed inset-y-0 left-20 hidden w-96 overflow-y-auto border-r border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block">
                    <div className="mt-24">
                    <Button variant="outlined" onClick={handleOpen}>
                        Create Event
                    </Button>
                    </div>
                    <MyEvents />
                </aside>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Create a New Event
                        </Typography>
                        <EventCreationForm onClose={handleClose} />
                    </Box>
                </Modal>
            </div>
            <ToastContainer position="top-right" autoClose={5000} />
        </div>
    );
};

export default EventsPage;


