import React from 'react';
import { useState } from "react";
import CSVUploadComponent from './CSVUploadComponent';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from "../../server";
import Modal from '@mui/material/Modal';

const BulkProductUpload = () => {
  const handleProductsFromCSV = async (products) => {
    try {
      // Assuming your API endpoint is /create-products and accepts an array of products
      const response = await axios.post(`${server}/product/create-products-csv`, {
        products: products
      }, {
        // Add any necessary headers here, such as Authorization
      });

      if (response.status === 201) {
        toast.success("Products created successfully!");
        // Additional success handling
      }
    } catch (error) {
      console.error("Error occurred during products creation:", error);
      toast.error("Failed to create products.");
      // Error handling
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%', 
    maxWidth: '600px', 
    backgroundColor: 'rgba(0, 0, 0, 0.8)', 
    border: '2px solid #333',
    borderRadius: '15px', 
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)', 
    padding: '24px',
    outline: 'none',
    overflowY: 'auto',
    backdropFilter: 'blur(10px)', 
  };

  return (
    <>
      <button onClick={handleOpen} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
        Bulk Upload via CSV
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="bulk-upload-modal-title"
      >
        <div style={modalStyle}>
          <h2 id="bulk-upload-modal-title" className="text-xl font-bold text-gray-700 mb-4">Bulk Product Upload</h2>
          <CSVUploadComponent onProductsParsed={handleProductsFromCSV} />
          <button onClick={handleClose} className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default BulkProductUpload;
