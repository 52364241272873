import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { server } from "../server";

const SellerActivationPage = () => {
  const { activation_token } = useParams();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [stripeOnboardingUrl, setStripeOnboardingUrl] = useState("");

  useEffect(() => {
    console.log("Activation token from URL:", activation_token); // Log the token received from URL

    if (activation_token) {
      activateShop();
    }
  }, [activation_token]);

  const activateShop = async () => {
    try {
      const response = await axios.post(`${server}/shop/activation`, {
        activation_token,
      });
      console.log("Activation response:", response); // Log the response
      finalizeSetup();
    } catch (err) {
      const message = err.response?.data?.message || "An error occurred during activation";
      console.error("Activation error:", message); // Log the error
      setErrorMessage(message);
      setError(true);
    }
  };

  const finalizeSetup = async () => {
    try {
      const response = await axios.post(`${server}/finalize-setup`, {}, {
        withCredentials: true
      });
      setStripeOnboardingUrl(response.data.url);
    } catch (error) {
      console.error('Error during final setup:', error.message);
      setError(true);
      setErrorMessage("Failed to initialize Stripe setup.");
    }
  };

  return (
    <div style={{
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      {error ? (
        <p>Error: {errorMessage}</p>
      ) : stripeOnboardingUrl ? (
        <div>
          <p>Your account has been activated successfully! Please complete your payment setup to start receiving payments.</p>
          <button onClick={() => window.location.href = stripeOnboardingUrl} style={{ padding: "10px 20px", fontSize: "16px", cursor: "pointer", backgroundColor: "blue", color: "white", borderRadius: "5px" }}>
            Complete Payment Setup
          </button>
        </div>
      ) : (
        <p>Activating your account...</p>
      )}
    </div>
  );
};

export default SellerActivationPage;

