import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation

export default function ShopByBrand() {
  const { allProducts, isLoading } = useSelector((state) => state.products);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Extract unique brands
  const brands = [...new Set(allProducts.map(product => product.brand))];

  return (
    <div className="bg-white">
      <div className="py-16 sm:py-24 lg:mx-auto lg:max-w-7xl lg:px-8">
        <div className="flex items-center justify-between px-4 sm:px-6 lg:px-0">
          <h2 className="text-2xl font-bold tracking-tight text-green-600">Shop by Brand</h2>
        </div>

        <div className="mt-8">
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
            {brands.map((brand, index) => (
              <div key={index} className="group relative p-4 shadow-lg rounded-lg bg-black text-center">
                <Link to={`/brand/${brand}`} className="no-underline">
                  <div className="text-white font-bold">{brand}</div>
                  <div className="mt-2 text-sm text-gray-300">Explore products</div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
