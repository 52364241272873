import { createReducer } from "@reduxjs/toolkit";
import {
  SET_USER_LOCATION,
  LOCATION_ERROR,
  SAVE_LOCATION_SUCCESS,
  SAVE_LOCATION_ERROR,
  FETCH_USER_AND_SUBSCRIPTION_REQUEST,
  FETCH_USER_AND_SUBSCRIPTION_SUCCESS,
  FETCH_USER_AND_SUBSCRIPTION_FAILURE,
} from "../actions/user";

const initialState = {
  isAuthenticated: false,
  friends: [],
  currentProfile: sessionStorage.getItem('currentProfile') || 'User', 
  user: null,
  users: [],
  subscriptions: [], 
  location: null,
  locationError: null,
  locationSaveSuccess: false,
  locationSaveError: null,
};

export const userReducer = createReducer(initialState, {
  LoadUserRequest: (state) => {
    state.loading = true;
  },
  LoadUserSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.user = action.payload;
  },
  LoadUserFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
  },
  [SET_USER_LOCATION]: (state, action) => {
    console.log("Setting user location:", action.payload);
    state.location = action.payload;
  },
  [LOCATION_ERROR]: (state, action) => {
      console.log("Location error:", action.payload);
      state.locationError = action.payload;
  },
  [SAVE_LOCATION_SUCCESS]: (state, action) => {
      console.log("Location saved successfully:", action.payload);
      state.locationSaveSuccess = true;
      state.locationData = action.payload; 
  },
  [SAVE_LOCATION_ERROR]: (state, action) => {
      console.log("Error saving location:", action.payload);
      state.locationSaveError = action.payload;
  },
  [FETCH_USER_AND_SUBSCRIPTION_REQUEST]: (state) => {
    state.loading = true;
    state.error = null; 
  },
  [FETCH_USER_AND_SUBSCRIPTION_SUCCESS]: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.user = action.payload.user;
    state.subscriptions = action.payload.subscriptions;
  },
  [FETCH_USER_AND_SUBSCRIPTION_FAILURE]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
    state.subscriptions = []; 
  },
  

  //Switch Profile

  SwitchProfile: (state, action) => {
    state.currentProfile = action.payload;
  },

  // update user information
  updateUserInfoRequest: (state) => {
    state.loading = true;
  },
  updateUserInfoSuccess: (state, action) => {
    state.loading = false;
    state.user = action.payload;
  },
  updateUserInfoFailed: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  // update user address
  updateUserAddressRequest: (state) => {
    state.addressloading = true;
  },
  updateUserAddressSuccess: (state, action) => {
    state.addressloading = false;
    state.successMessage = action.payload.successMessage;
    state.user = action.payload.user;
  },
  updateUserAddressFailed: (state, action) => {
    state.addressloading = false;
    state.error = action.payload;
  },

  // delete user address
  deleteUserAddressRequest: (state) => {
    state.addressloading = true;
  },
  deleteUserAddressSuccess: (state, action) => {
    state.addressloading = false;
    state.successMessage = action.payload.successMessage;
    state.user = action.payload.user;
  },
  deleteUserAddressFailed: (state, action) => {
    state.addressloading = false;
    state.error = action.payload;
  },

  // get all users --- admin
  getAllUsersRequest: (state) => {
    state.usersLoading = true;
  },
  getAllUsersSuccess: (state,action) => {
    state.usersLoading = false;
    state.users = action.payload;
  },
  getAllUsersFailed: (state,action) => {
    state.usersLoading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
  clearMessages: (state) => {
    state.successMessage = null;
  },
});
