import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { server } from "../../server";

const SponsoredProducts = () => {
    const [sponsoredProducts, setSponsoredProducts] = useState([]);

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`);
            console.log('Click logged for ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const logImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/impression/${adId}`, {}, { withCredentials: true });
            console.log('Impression logged for ad:', adId);
        } catch (error) {
            console.error('Error logging impression:', error);
        }
    };

    useEffect(() => {
        const fetchSponsoredProducts = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-products`, { withCredentials: true });
                // Filter out inactive ads before setting the state
                const activeSponsoredProducts = response.data.filter(ad => ad.isActive === true && ad.product !== undefined);
                setSponsoredProducts(activeSponsoredProducts); // Set state with only active ads
            } catch (error) {
                console.error('Error fetching sponsored products:', error);
            }
        };
        fetchSponsoredProducts();
    }, []);
    

    useEffect(() => {
        sponsoredProducts.forEach(({ _id: adId }) => {
            logImpression(adId); // Use the adId for impressions
        });
    }, [sponsoredProducts]);

    return (
        <div style={{ padding: '20px', backgroundColor: '#333', borderRadius: '10px', margin: '10px auto', maxWidth: '1000px' }}>
            <h2 style={{ textAlign: 'center', marginBottom: '20px', color: 'white' }}>Sponsored Products</h2>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {sponsoredProducts.map(({ _id: adId, product }) => ( // Destructure to get adId and product separately
                    <div key={adId} style={{ width: '100%', textAlign: 'center', margin: '10px 0', border: '1px solid #ddd', borderRadius: '10px', overflow: 'hidden' }}>
                        <Link to={`/product/${product._id}`} onClick={() => logAdClick(adId)} style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
                            {product.images && product.images.length > 0 && (
                                <img src={product.images[0].url} alt={product.name} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                            )}
                            <div style={{ backgroundColor: 'rgba(84, 82, 82, 0.5)', color: 'white', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <h3 style={{ fontSize: '16px', margin: '0' }}>{product.name}</h3>
                                    <p style={{ fontSize: '14px', fontWeight: 'bold', margin: '5px 0' }}>${product.discountPrice || product.originalPrice}</p>
                                </div>
                                {product.handle && (
                                    <Link to={`/shop/${product.handle}`} style={{ fontSize: '12px', textDecoration: 'none', color: '#1f9c29', backgroundColor: 'white', padding: '5px 10px', borderRadius: '5px' }}>Visit Store</Link>
                                )}
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SponsoredProducts;




