import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { server } from "../../server";

const MapProductAdSlider = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-products`, { withCredentials: true });
                const activeProducts = response.data.filter(ad => ad.isActive && ad.product).slice(0, 7);
                setProducts(activeProducts);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        products.forEach(product => {
            axios.post(`${server}/sponsored/impression/${product._id}`, {}, { withCredentials: true })
                .then(() => console.log('Impression logged for product:', product._id))
                .catch(error => console.error('Error logging impression:', error));
        });
    }, [products]);

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`);
            console.log('Click logged for ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    return (
        <div className="fixed z-50 bottom-0 left-0 px-4 py-4" style={{ width: '235px' }}>
            <Carousel autoPlay={true} animation="slide" navButtonsAlwaysVisible={true} interval={7000}>
                {products.map(({ _id, product }) => (
                    <Paper key={_id} style={{
                        background: `url(${product.images[0].url}) center center`,
                        backgroundSize: 'cover',
                        height: 200,
                        width: 200,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#fff',
                        borderRadius: '20px',
                        overflow: 'hidden'
                    }}>
                        <div style={{
                            background: 'rgba(0, 0, 0, 0.3)', 
                            borderRadius: '10px',
                            padding: '20px',
                            textAlign: 'center',
                            height:'100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                        }}>
                            <h2 style={{ fontSize: '1.2em' }}>{product.name}</h2>
                            <p style={{ fontSize: '1em' }}>{`$${product.discountPrice || product.originalPrice}`}</p>
                            <Button component={Link} to={`/product/${product._id}`} onClick={() => logAdClick(_id)} variant="contained" style={{backgroundColor: '#4caf50', marginTop: '20px', marginBottom: '0px'}}>
                                Buy now
                            </Button>
                        </div>
                    </Paper>
                ))}
            </Carousel>
        </div>
    );
};

export default MapProductAdSlider;
