import { Box } from "@mui/material";
import { styled } from "@mui/system";

const WidgetWrapper = styled(Box)(({ theme }) => ({
  padding: "1.5rem 1.5rem 1.5rem 1.5rem",
  backgroundColor: "background-color:rgba(0,0,0,.5)",
  borderRadius: "0.75rem",
}));

export default WidgetWrapper;
