import React from 'react';
import axios from 'axios';
import { server } from "../../server";
import GoogleCalendarButton from './GoogleCalendarButton';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const EventCard = ({ event, onRSVP, onCancelRSVP }) => {
    const currentProfileType = useSelector((state) => state.user.currentProfile);
    const navigate = useNavigate();  

    const goToEventDetail = () => {
        navigate(`/events/${event._id}`); 
    };
    
    const handleRSVP = async () => {
        try {
            const data = {
                currentProfile: currentProfileType, 
            };
            const response = await axios.post(`${server}/event/rsvp/${event._id}`, data, {
                withCredentials: true
            });
            onRSVP(event._id);
            
        } catch (error) {
            console.error('Error RSVPing to event:', error);
            alert('Failed to RSVP: ' + error.response?.data?.message || 'Error occurred');
        }
    };

    const styles = {
        card: {
            position: 'relative',
            margin: '20px',
            border: '1px solid #ccc',
            overflow: 'hidden',
            borderRadius: '8px', 
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
        },
        image: {
            width: '100%',
            height: '300px', 
            objectFit: 'cover',
            transition: 'transform 0.5s ease'
        },
        info: {
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            background: 'linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, transparent 100%)',
            color: 'white',
            padding: '20px',
            transition: 'background 0.5s ease'
        },
        title: {
            margin: '0',
            fontWeight: 'bold',
            fontSize: '24px' 
        },
        description: {
            fontSize: '16px', 
            margin: '10px 0 20px' 
        },
        button: {
            backgroundColor: '#007BFF',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            marginTop: '10px',
            cursor: 'pointer',
            borderRadius: '5px',
            fontSize: '16px'
        },
        calendarButton: {
            marginTop: '10px'
        }
    };

    return (
        <div style={styles.card} onClick={goToEventDetail}>
            <img src={event.image} alt={event.title} style={styles.image} />
            <div style={styles.info}>
                <h3 style={styles.title}>{event.title}</h3>
                <p style={styles.description}>{event.description}</p>
                <p>{new Date(event.date).toLocaleString()}</p>
                <p>{event.location.address}</p> 
                <button
                    style={styles.button}
                    onClick={(e) => {
                        e.stopPropagation(); 
                        if (!event.isGoing) {
                            handleRSVP();
                        }
                    }}
                >
                    {event.isGoing ? 'Going' : 'Attend'}
                </button>
                <button
                    onClick={(e) => {
                        e.stopPropagation(); 
                        onCancelRSVP(event._id);
                    }}
                >
                    Cancel RSVP
                </button>
                <div style={styles.calendarButton}>
                    <GoogleCalendarButton event={event} />
                </div>

            </div>
        </div>
    );
};

export default EventCard;


