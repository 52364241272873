import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from "../../server";
import { useSelector } from "react-redux";


const BrandCreate = () => {
  const { seller } = useSelector((state) => state.seller);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [coverImage, setCoverImage] = useState(null);
  const [avatarImage, setAvatarImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    if (coverImage) formData.append('coverImage', coverImage);
    if (avatarImage) formData.append('avatarImage', avatarImage);

    try {
      const response = await axios.post(`${server}/brand/create-brand`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'current-profile': seller.name || 'defaultProfile',
        },
        withCredentials: true,
      });
      // Example usage of the response data
      if (response.data.success) {
        toast.success('Brand created successfully!');
        // Optionally use response data to reset form or handle success
        setName('');
        setDescription('');
        setCoverImage(null);
        setAvatarImage(null);
      } else {
        // Handle case where success is false
        toast.error('Brand creation failed.');
      }
    } catch (error) {
      console.error('Error creating brand:', error);
      toast.error('Failed to create brand.');
    }
  };


  const handleCoverImageChange = (e) => {
    setCoverImage(e.target.files[0]);
  };

  const handleAvatarImageChange = (e) => {
    setAvatarImage(e.target.files[0]);
  };

  return (
    <div className="space-y-8 bg-black">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            id="name"
            name="name"
            type="text"
            required
            className="mt-1 block w-full rounded-md bg-gray-700 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            rows={4}
            className="mt-1 block w-full rounded-md bg-gray-700 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>

        <div>
          <label htmlFor="coverImage" className="block text-sm font-medium text-gray-700">
            Cover Image
          </label>
          <input
            id="coverImage"
            name="coverImage"
            type="file"
            className="mt-1 block w-full text-sm text-gray-500
            file:mr-4 file:py-2 file:px-4
            file:rounded-full file:border-0
            file:text-sm file:font-semibold
            file:bg-violet-50 file:text-violet-700
            hover:file:bg-violet-100"
            onChange={(e) => handleCoverImageChange(e, setCoverImage)}
          />
        </div>

        <div>
          <label htmlFor="avatarImage" className="block text-sm font-medium text-gray-700">
            Avatar Image
          </label>
          <input
            id="avatarImage"
            name="avatarImage"
            type="file"
            className="mt-1 block w-full text-sm text-gray-500
            file:mr-4 file:py-2 file:px-4
            file:rounded-full file:border-0
            file:text-sm file:font-semibold
            file:bg-violet-50 file:text-violet-700
            hover:file:bg-violet-100"
            onChange={(e) => handleAvatarImageChange(e, setAvatarImage)}
          />
        </div>

        <button type="submit" className="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
          Create Brand
        </button>
      </form>
    </div>
  );
};

export default BrandCreate;


