import React from "react";
import { useLottie } from "lottie-react";
import animationData from "../../Assests/animations/Buzzweed-full-no-shadow.json";

const Loader = () => {
  const options = {
    animationData: animationData,
    loop: true,
    width: 300,
    height: 300,
  };

  const { View } = useLottie(options);

  return (
    <div className="w-full h-screen flex items-center justify-center">
      {View}
    </div>
  );
};

export default Loader;

