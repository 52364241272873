import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import Loader from "../Layout/Loader";
import EditProduct from "../Products/EditProducts";
import axios from 'axios';
import { getAllProductsShop, deleteProduct } from "../../redux/actions/product";
import { server } from "../../server";

const AllProducts = () => {
  const { products, isLoading } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductsShop(seller._id));
  }, [dispatch]);

  const openDeleteModal = (id) => {
    setDeleteProductId(id);
    setDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${server}/product/delete-shop-product/${deleteProductId}`, {
        withCredentials: true,
        headers: {
          'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
        },
      });
  
      console.log(response.data.message);
  
      // Optionally, you can fetch the updated list of products after deletion
      dispatch(getAllProductsShop(seller._id));
    } catch (error) {
      console.error("Error deleting product:", error.response.data);
    }
  };

  const handleEdit = (productId) => {
    setSelectedProductId(productId);
    setEditModalOpen(true);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#333333', 
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    outline: 'none',
    width: '80%', 
    maxHeight: '80vh',
    overflowY: 'auto',
    color: '#374151', 
  };

  const deleteModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#333333', 
    padding: '20px',
    borderRadius: '10px', 
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)', 
    width: 400, 
    textAlign: 'center', 
    outline: 'none',
    border: '2px solid #4CAF50'
  };

  const closeIconStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    color: 'black', 
    fontSize: '1.5rem' 
  };

  const columns = [
    {
      field: "image",
      headerName: "Image",
      width: 130,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <img
          src={params.row.image} 
          alt={params.row.name}
          style={{ width: '125px', height: 'auto', borderRadius: '8px' }}
        />
      ),
    },
    { 
      field: "id", 
      headerName: "Product ID", 
      minWidth: 150, 
      flex: 0.7, 
      headerAlign: 'center', 
      align: 'center',
    },
    { 
      field: "name", 
      headerName: "Name", 
      minWidth: 180, 
      flex: 1,
      headerAlign: 'center', 
      align: 'left',
    },
    { 
      field: "productType", 
      headerName: "Product Type", 
      minWidth: 130, 
      flex: 0.6,
      headerAlign: 'center', 
      align: 'center',
    },
    { 
      field: "price", 
      headerName: "Price", 
      minWidth: 100, 
      flex: 0.4,
      headerAlign: 'center', 
      align: 'center',
    },
    { 
      field: "Stock", 
      headerName: "Stock", 
      type: "number", 
      minWidth: 80, 
      flex: 0.3,
      headerAlign: 'center', 
      align: 'center',
    },
    { 
      field: "SKU", 
      headerName: "SKU", 
      minWidth: 120, 
      flex: 0.5, 
      headerAlign: 'center', 
      align: 'center',
    },
    { 
      field: "category", 
      headerName: "Category", 
      minWidth: 150, 
      flex: 0.6, 
      headerAlign: 'center', 
      align: 'center',
    },
    { 
      field: "sold", 
      headerName: "Sold Out", 
      type: "number", 
      minWidth: 130, 
      flex: 0.4,
      headerAlign: 'center', 
      align: 'center',
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      minWidth: 180,
      flex: 1.2,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Link to={`/product/${params.id}`} style={{ marginRight: '2px' }}>
            <AiOutlineEye size={20} title="Preview" />
          </Link>
          <Button onClick={() => handleEdit(params.id)} style={{ color: '#4B5563', marginRight: '2px' }}>
            <AiOutlineEdit size={20} title="Edit" />
          </Button>
          <Button onClick={() => openDeleteModal(params.id)} style={{ color: '#EF4444' }}>
  <AiOutlineDelete size={20} title="Delete" />
</Button>

        </div>
      ),
    },
  ];
  

  const rows = [];

  if (products) {
    products.forEach((product) => {
      rows.push({
        id: product._id,
        name: product.name,
        productType: Array.isArray(product.productType) ? product.productType.join(', ') : product.productType,
        price: `US$ ${product.discountPrice.toFixed(2)}`,
        Stock: product.stock,
        sold: product.sold_out,
        image: product.images[0]?.url || 'defaultProductImageUrl',
        SKU: product.SKU,
        category: product.category,
      });
    });
  }
  

return (
  <>
    {isLoading ? (
      <Loader />
    ) : (
      <div style={{ backgroundColor: '#333333', padding: '6px', marginTop: '20px', minHeight: '100vh', borderRadius: '6px' }}>
        <div style={{ width: '100%', margin: 'auto', padding: '16px' }}>
          <h1 style={{ fontSize: '24px', fontWeight: 'bold', color: '#F7FAFC' }}>All Products</h1>
        </div>

        <div style={{ marginTop: '32px' }}>
          <DataGrid
            rows={rows}
            rowHeight={120} 
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            sx={{
              boxShadow: 'none',
              border: '1px solid #2D3748',
              color: '#F7FAFC',
              '& .MuiDataGrid-cell': {
                borderBottom: '1px solid #2D3748',
                padding: '4px',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#146e10',
                color: '#F7FAFC',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
          />
        </div>
      </div>
    )}

    <Modal
      open={editModalOpen}
      onClose={() => setEditModalOpen(false)}
      aria-labelledby="edit-product-modal"
      aria-describedby="modal-to-edit-product"
    >
      <div style={{
        ...modalStyle,
        backgroundColor: '#141414', // Dark background for the modal
        color: '#E2E8F0', // Lighter text color for contrast
      }}>
        <AiOutlineClose
          style={{ ...closeIconStyle, color: '#CBD5E0' }} // Adjusting close icon color for visibility
          onClick={() => setEditModalOpen(false)}
        />
        {selectedProductId && <EditProduct productId={selectedProductId} onClose={() => setEditModalOpen(false)} />}
      </div>
    </Modal>
    <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="delete-product-modal"
        aria-describedby="modal-to-confirm-product-deletion"
      >
        <div style={deleteModalStyle}>
          <h2>Are you sure you want to delete this product?</h2>
          <Button onClick={handleDelete} color="error">
            Yes
          </Button>
          <Button onClick={() => setDeleteModalOpen(false)}>
            No
          </Button>
        </div>
      </Modal>
  </>
);
};

export default AllProducts;
