import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Assuming you have a function getCookie to retrieve the value of a cookie
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const AgeVerificationWrapper = ({ children }) => {
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAgeVerification = () => {
      const ageVerified = getCookie('ageVerified') === 'true';

      if (!ageVerified) {
        navigate('/ageverificationpage');
      } else {
        setIsVerified(true);
      }
    };

    checkAgeVerification();
  }, [navigate]);

  if (!isVerified) {
    return <div>Verifying age...</div>;
  }

  return <>{children}</>;
};

export default AgeVerificationWrapper;

