import React, { useState } from 'react';
import axios from 'axios';
import { server } from '../../server'; 

const PackingSlipForm = ({ onClose }) => {
    const [orderDetails, setOrderDetails] = useState({
        number: '',
        date: '',
        customer: {
            name: '',
            address: ''
        },
        items: []
    });
    const [itemInput, setItemInput] = useState({
        name: '',
        quantity: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "itemName" || name === "itemQuantity") {
            setItemInput({
                ...itemInput,
                [name === "itemName" ? "name" : "quantity"]: value
            });
        } else {
            setOrderDetails({
                ...orderDetails,
                [name]: value
            });
        }
    };

    const addItemToList = () => {
        setOrderDetails({
            ...orderDetails,
            items: [...orderDetails.items, { ...itemInput, quantity: parseInt(itemInput.quantity, 10) }]
        });
        setItemInput({ name: '', quantity: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${server}/shippingrule/packing-slip`, orderDetails);
            alert('Packing slip generated! File: ' + response.data.filePath);
        } catch (error) {
            console.error('Error generating packing slip:', error);
            alert('Failed to generate packing slip.');
        }
    };

    return (
        <div className="modal-content">
        <h1>Generate Packing Slip</h1>
        <button onClick={onClose} style={{ float: 'right' }}>Close</button>
            <form onSubmit={handleSubmit}>
                <label>Order Number:
                    <input type="text" name="number" value={orderDetails.number} onChange={handleInputChange} />
                </label>
                <label>Order Date:
                    <input type="date" name="date" value={orderDetails.date} onChange={handleInputChange} />
                </label>
                <label>Customer Name:
                    <input type="text" name="customerName" value={orderDetails.customer.name} onChange={handleInputChange} />
                </label>
                <label>Customer Address:
                    <input type="text" name="customerAddress" value={orderDetails.customer.address} onChange={handleInputChange} />
                </label>
                <h3>Add Items</h3>
                <label>Item Name:
                    <input type="text" name="itemName" value={itemInput.name} onChange={handleInputChange} />
                </label>
                <label>Item Quantity:
                    <input type="number" name="itemQuantity" value={itemInput.quantity} onChange={handleInputChange} />
                </label>
                <button type="button" onClick={addItemToList}>Add Item</button>
                <ul>
                    {orderDetails.items.map((item, index) => (
                        <li key={index}>{item.name} - Quantity: {item.quantity}</li>
                    ))}
                </ul>
                <button type="submit">Generate Packing Slip</button>
            </form>
        </div>
    );
};

export default PackingSlipForm;
