import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { server } from "../../server";
import styles from "../../styles/styles";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "./Ratings";
import axios from "axios";
import { CheckIcon } from '@heroicons/react/24/solid';

const ProductDetails = ({ data }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProductsShop(data && data?.shop._id));
    if (wishlist && wishlist.find((i) => i._id === data?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
    
  }, [data, wishlist]);

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: count };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const avg =  totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);


  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = data._id + user._id;
      const userId = user._id;
      const sellerId = data.shop._id;
      await axios
        .post(`${server}/conversation/create-new-conversation`, {
          groupTitle,
          userId,
          sellerId,
        })
        .then((res) => {
          navigate(`/inbox?${res.data.conversation._id}`);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error("Please login to create a conversation");
    }
  };

  return (
    <div className="content-bg">
      {data ? (
        <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
          <div className="w-full py-5">
            <div className="block w-full 800px:flex">
            <div className="w-full 800px:w-[50%]">
                {/* Render selected image or video */}
                {select < data.images.length ? (
                  <img
                    src={`${data.images[select]?.url}`}
                    alt=""
                    className="w-[80%] h-auto object-cover rounded-lg shadow-lg"
                  />
                ) : (
                  <video
                    src={`${data.videos[select - data.images.length]?.url}`}
                    className="w-[80%] h-auto object-cover rounded-lg shadow-lg"
                    controls
                  />
                )}
               <div className="w-full flex flex-wrap">
                {/* Map through images for selection */}
                {data.images.map((image, index) => (
                    <img
                        key={index}
                        src={image.url}
                        alt={`Image ${index + 1}`}
                        className={`h-[200px] w-auto object-cover mr-2 mb-2 cursor-pointer border-2 rounded-md ${
                            select === index ? "border-green-500" : "border-transparent"
                        } hover:border-green-600 transition-all duration-300`}
                        onClick={() => setSelect(index)}
                    />
                ))}
                {/* Map through videos for selection */}
                {data.videos.map((video, index) => (
                    <video
                        key={index}
                        src={video.url}
                        className={`h-[200px] w-auto object-cover mr-2 mb-2 cursor-pointer border-2 rounded-md ${
                            select === data.images.length + index ? "border-green-500" : "border-transparent"
                        } hover:border-green-600 transition-all duration-300`}
                        onClick={() => setSelect(data.images.length + index)}
                    />
                ))}
            </div>
              </div>
              <div className="w-full 800px:w-[50%] pt-5">
              {data.brand && (
            <div className="mt-4 inline-flex items-center bg-gray-900 rounded-full">
              <span className="inline-block bg-green-700 text-white text-sm font-bold py-1 px-3 rounded-full shadow-md mr-2">
                Brand:
              </span>
              <span className="text-md text-gray-700 font-semibold px-3">{data.brand}</span>
              {data.brandTypeSelection === 'branded' && (
    <div>
      <img
      src= 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1710252613/feat_morfbr.png'
      alt=""
      ></img>
  </div>
  )}
            </div>
          )}
                <h1 className={`${styles.productTitle}`}>{data.name}</h1>
                <p className="product-description">{data.description}</p>
                <div className="flex pt-3">
                  <h4 className={`${styles.productDiscountPrice}`}>
                    ${data.discountPrice}
                  </h4>
                  <h3 className={`${styles.price}`}>
                    {"$" + data.originalPrice ? data.originalPrice : null}
                  </h3>
                </div>
                {/* Available for Delivery and Pickup Badges */}
                <div className="flex space-x-2 mt-6">
                  {data.availableForDelivery && (
                    <span className="inline-block bg-green-500 text-white text-sm font-bold py-1 px-3 rounded-full shadow-md">
                      Available for Delivery
                    </span>
                  )}
                  {data.availableForPickup && (
                    <span className="inline-block bg-blue-500 text-white text-sm font-bold py-1 px-3 rounded-full shadow-md">
                      Available for Pickup
                    </span>
                  )}
                </div>

                <div className="flex items-center mt-12 justify-between pr-3">
                  <div>
                    <button
                      className="text-white font-bold px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                      onClick={decrementCount}
                    >
                      -
                    </button>
                    <span className="bg-gray-200 text-gray-800 font-medium px-4 py-[11px]">
                      {count}
                    </span>
                    <button
                      className="text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                      onClick={incrementCount}
                    >
                      +
                    </button>
                  </div>
                  <div>
                    {click ? (
                      <AiFillHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() => removeFromWishlistHandler(data)}
                        color={click ? "red" : "#333"}
                        title="Remove from wishlist"
                      />
                    ) : (
                      <AiOutlineHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() => addToWishlistHandler(data)}
                        color={click ? "red" : "#333"}
                        title="Add to Wishlist"
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`${styles.button} !mt-6 !rounded !h-11 flex items-center`}
                  onClick={() => addToCartHandler(data._id)}
                >
                  <span className="text-white flex items-center">
                    Add to Cart <AiOutlineShoppingCart className="ml-1" />
                  </span>
                </div>
                <div className="flex items-center pt-8">
                  <Link to={`/shop/${data?.shop.handle}`}>
                    <img
                      src={`${data?.shop?.avatar?.url}`}
                      alt=""
                      className="w-[50px] h-[50px] rounded-full mr-2"
                    />
                  </Link>
                  <div className="pr-8">
                    <Link to={`/shop/${data?.shop.handle}`}>
                      <h3 className={`${styles.shop_name} pb-1 pt-1`}>
                        {data.shop.name}
                      </h3>
                    </Link>
                    <h5 className="pb-3 text-[15px]">
                      ({averageRating}/5) Ratings
                    </h5>
                  </div>
                  <div
                    className={`${styles.button} bg-[#6443d1] mt-4 !rounded !h-11`}
                    onClick={handleMessageSubmit}
                  >
                    <span className="text-white flex items-center">
                      Send Message <AiOutlineMessage className="ml-1" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ProductDetailsInfo
            data={data}
            products={products}
            totalReviewsLength={totalReviewsLength}
            averageRating={averageRating}
          />
          <br />
          <br />
        </div>
      ) : null}
    </div>
  );
};

const ProductDetailsInfo = ({
  data,
  products,
  totalReviewsLength,
  averageRating,
}) => {
  const [active, setActive] = useState(1);

  return (
    <div className="bg-[#f5f6fb] px-3 800px:px-10 py-2 rounded">
      <div className="w-full flex justify-between border-b pt-10 pb-2">
        <div className="relative">
          <h4
            className={
              "text-[#000] text-[24px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(1)}
          >
            Product Details
          </h4>
          {active === 1 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
        <div className="relative">
          <h4
            className={
              "text-[#000] text-[24px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(2)}
          >
            Product Reviews
          </h4>
          {active === 2 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
        <div className="relative">
          <h4
            className={
              "text-[#000] text-[24px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(3)}
          >
            Seller Information
          </h4>
          {active === 3 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
      </div>
      {active === 1 ? (
        <>
          <p className="py-2 text-[18px] leading-8 pb-10 whitespace-pre-line">
            {data.description}
          </p>
          <div className="flex flex-wrap justify-center gap-4 mt-4">
            {/* Display Aggregate Metrics */}
            {data.aggregate_metrics && (
              <div className="flex-1 min-w-0 border border-green-500 rounded-lg p-4">
                <h3 className="text-lg font-semibold mb-2">Aggregate Metrics:</h3>
                <div className="grid grid-cols-3 gap-2">
                  <div className="px-4 py-5 bg-black rounded-lg shadow">
                    <p className="text-white">CBD:</p>
                    <p className="text-xl text-green-500 font-semibold">{data.aggregate_metrics.cbd} {data.aggregate_metrics.cbd_unit}</p>
                  </div>
                  <div className="px-4 py-5 bg-black rounded-lg shadow">
                    <p className="text-white">Terpenes:</p>
                    <p className="text-xl text-green-500 font-semibold">{data.aggregate_metrics.terpenes} {data.aggregate_metrics.terpenes_unit}</p>
                  </div>
                  <div className="px-4 py-5 bg-black rounded-lg shadow">
                    <p className="text-white">THC:</p>
                    <p className="text-xl text-green-500 font-semibold">{data.aggregate_metrics.thc} {data.aggregate_metrics.thc_unit}</p>
                  </div>
                </div>
              </div>
            )}

            {/* Display Test Results Image */}
            {data.testResults && data.testResults.length > 0 && (
              <div className="flex-1 min-w-0 border border-green-500 rounded-lg p-4 shadow-lg">
                <h3 className="text-lg font-semibold mb-2 text-center">Test Results:</h3>
                <img 
                  src={data.testResults[0].url} 
                  alt="Test Results" 
                  className="max-w-xs w-full h-auto mx-auto mb-4"
                />
              </div>
            )}
          </div>


          {/* Barcode Image and Barcode String */}
          <div className="flex flex-col items-center justify-center mt-4">
            {data.barcode && data.barcode.length > 0 && (
              <div className="border border-green-500 rounded-lg p-4 shadow-lg">
                <h3 className="text-lg font-semibold mb-2 text-center">Product Barcode:</h3>
                <img 
                  src={data.barcode[0].url} 
                  alt="Product Barcode" 
                  className="max-w-xs w-full h-auto mx-auto mb-4"
                />
              </div>
            )}
            {data.barcodeString && (
              <div className="mt-4 text-center">
                <h3 className="text-lg font-semibold">Barcode Number:</h3>
                <p className="text-green-500 font-bold">{data.barcodeString}</p>
              </div>
            )}
          </div>

          {/* Display Nutrition Info Images if the product is edible */}
          {data.nutritionInfoImage && data.nutritionInfoImage.length > 0 && (
              <div className="mt-4 border border-green-500 rounded-lg p-4 shadow-lg">
                  <h3 className="text-lg font-semibold mb-2 text-center">Nutrition Information:</h3>
                  <img 
                    src={data.nutritionInfoImage[0].url} 
                    alt="Nutrition Info" 
                    className="max-w-xs w-full h-auto mx-auto mb-4"
                  />
              </div>
          )}
        </>
      ) : null}

      {active === 2 ? (
        <div className="w-full min-h-[40vh] flex flex-col items-center py-3 overflow-y-scroll">
          {data &&
            data.reviews.map((item, index) => (
              <div className="w-full flex my-2">
                <img
                  src={`${item.user.avatar?.url}`}
                  alt=""
                  className="w-[50px] h-[50px] rounded-full"
                />
                <div className="pl-2 ">
                  <div className="w-full flex items-center">
                    <h1 className="font-[500] mr-3">{item.user.name}</h1>
                    <Ratings rating={data?.ratings} />
                  </div>
                  <p>{item.comment}</p>
                </div>
              </div>
            ))}

          <div className="w-full flex justify-center">
            {data && data.reviews.length === 0 && (
              <h5>No Reviews for this product.</h5>
            )}
          </div>
        </div>
      ) : null}

{active === 3 && (
  <div className="w-full block grid grid-cols-1 md:grid-cols-2 gap-6 p-5">
    {/* First Section - One Column */}
    <div className="w-full p-3 bg-black rounded-xl shadow-neumorphism">
      <Link to={`/shop/${data.shop.handle}`} className="group">
        <div className="flex items-center p-4 bg-black rounded-lg shadow-md hover:bg-gray-800 transition-colors duration-300 ease-in-out">
          <img
            src={`${data?.shop?.avatar?.url}`}
            className="w-16 h-16 rounded-full border-2 border-green-500"
            alt={`${data.shop.name} avatar`}
          />
          <div className="pl-4">
            <h3 className="text-lg font-semibold text-gray-100 group-hover:text-green-600 transition-colors duration-300">
              {data.shop.name}
            </h3>
            <p className="text-sm text-gray-400">
              ({averageRating}/5) Ratings
            </p>
          </div>
        </div>
      </Link>
      <p className="mt-4 text-gray-300">{data.shop.description}</p>
    </div>

    {/* Second Section - Two Columns */}
    <div className="w-full flex flex-wrap justify-between">
      <div className="w-full md:w-1/2 lg:w-1/3 p-4 mb-4 bg-black rounded-xl shadow-neumorphism">
        <h4 className="text-gray-100 text-md font-semibold">Joined on</h4>
        <p className="text-green-500 text-lg font-bold">
          {data.shop?.createdAt?.slice(0, 10)}
        </p>
      </div>

      <div className="w-full md:w-1/2 lg:w-1/3 p-4 mb-4 bg-black rounded-xl shadow-neumorphism">
        <h4 className="text-gray-100 text-md font-semibold">Total Products</h4>
        <p className="text-green-500 text-lg font-bold">
          {products && products.length}
        </p>
      </div>

      <div className="w-full md:w-1/2 lg:w-1/3 p-4 mb-4 bg-black rounded-xl shadow-neumorphism">
        <h4 className="text-gray-100 text-md font-semibold">Total Reviews</h4>
        <p className="text-green-500 text-lg font-bold">
          {totalReviewsLength}
        </p>
      </div>
    </div>
  </div>
)}


{/* Button Section */}
<div className="w-full mt-4 text-center">
  <Link to={`/shop/${data?.shop.handle}`}>
    <div className="bg-green-500 rounded-lg shadow-lg p-4 text-center transition duration-300 hover:bg-green-600 inline-block">
      <h4 className="text-white text-lg font-bold">Visit Shop</h4>
    </div>
  </Link>
</div>

      
    </div>
  );
};

export default ProductDetails;