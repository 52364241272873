import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import PostsWidgetRedux from "../widgets/PostsWidgetRedux";
import AboutUser from "../components/AboutUser";
import FriendListWidget from "../widgets/FriendListWidget";
import MyPostWidgetRedux from "../widgets/MyPostWidgetRedux";
import ConnectionsWidget from "./Followers/ConnectionsWidget";


const UserProfileData = () => {
  const { user } = useSelector((state) => state.user);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('Posts');
  const picturePath = user?.picturePath;

  useEffect(() => {
    // Dispatch actions to fetch user-related data (posts, friends, etc.)
    // Example: dispatch(getUserPosts(id));
    // dispatch(getUserFriends(id));
  }, [dispatch, id]);

  const tabs = [
    { name: 'Posts', current: activeTab === 'Posts' },
    { name: 'About', current: activeTab === 'About' },
    { name: 'Connections', current: activeTab === 'Connections' },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className="content-container">
      {/* Tab navigation */}
      <div className="sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => setActiveTab(tab.name)}
              className={classNames(
                tab.current
                  ? 'text-green-500 border-green-500'
                  : 'text-gray-500 border-transparent hover:text-green-500',
                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      <br />
      {activeTab === 'Posts' && (
        <div className="w-full">
          <MyPostWidgetRedux picturePath={picturePath} />
          <PostsWidgetRedux ownerId={id} profileType="User" isProfile={true} />
        </div>
      )}

      {activeTab === 'About' && (
        <div className="w-full">
          <AboutUser userId={id} />
        </div>
      )}

      {activeTab === 'Connections' && (
        <div className="w-full">
          <ConnectionsWidget />
        </div>
      )}
    </div>
  );
};

export default UserProfileData;
