import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../server'; 
import FriendCard from './FriendCard'; 
import { useSelector } from 'react-redux';

const RecommendedFriends = () => {
    const [recommendedFriends, setRecommendedFriends] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const currentProfile = useSelector((state) => state.user.currentProfile);
    const { user } = useSelector((state) => state.user);
    const initialSeller  = useSelector((state) => state.seller.seller);
    const seller  = useSelector((state) => state.seller.activeSeller);
    const sellerId = seller?._id || initialSeller?._id;
    const userId = currentProfile === 'User' ? user?._id : sellerId;
    const profileType = currentProfile === 'User' ? 'User' : 'Shop'

    useEffect(() => {
        if (!userId || !profileType) {
            console.log('User ID or Profile Type is missing:', userId, profileType);
            return;  // Prevent API call if userId or profileType is undefined
        }
        
        const fetchRecommendedFriends = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${server}/followers/recommend-friends/${userId}/${profileType}`, {
                    withCredentials: true
                });
                setRecommendedFriends(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Failed to fetch recommended friends:', err);
                setError('Failed to load recommended friends');
                setLoading(false);
            }
        };
    
        fetchRecommendedFriends();
    }, [userId, profileType, currentProfile]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <h2>Recommended Friends</h2>
            <div className="suggested-friend-list">
                {recommendedFriends.map(friend => (
                    <FriendCard key={friend._id} friend={friend} profileType={profileType} friendId={friend._id}/> 
                ))}
            </div>
        </div>
    );
};

export default RecommendedFriends;
