import { createReducer } from "@reduxjs/toolkit";
import {
  CREATE_POST_REQUEST,
  CREATE_POST_SUCCESS,
  CREATE_POST_FAILURE,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  UPLOAD_VIDEO_REQUEST,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILURE,
  LIKE_POST_SUCCESS,
  POST_COMMENT_SUCCESS,
  EDIT_POST_SUCCESS,
  EDIT_COMMENT_SUCCESS,
  DELETE_POST_SUCCESS,
  DELETE_COMMENT_SUCCESS,
  FETCH_COMMENTS_SUCCESS,
  OPERATION_FAIL,
  OPERATION_REQUEST,
  OPERATION_COMPLETE,
  CLEAR_ERRORS,
  FETCH_POSTS_REQUEST, 
  FETCH_POSTS_SUCCESS, 
  FETCH_POSTS_FAILURE,
  RESHARE_POST_REQUEST,
  RESHARE_POST_SUCCESS,
  RESHARE_POST_FAILURE,
} from '../actions/post'; // Ensure the path matches where your actions are defined

const initialState = {
  posts: [],
  comments: {}, // Assuming comments are stored by postId for easy access
  loading: false,
  error: null,
};

export const postsReducer = createReducer(initialState, {

    [CREATE_POST_REQUEST]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [CREATE_POST_SUCCESS]: (state, action) => {
        state.loading = false;
        state.posts.unshift(action.payload); // prepend the new post
        state.error = null;
      },
      [CREATE_POST_FAILURE]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },
      [UPLOAD_IMAGE_REQUEST]: (state) => {
        state.loading = true;
    },
    [UPLOAD_IMAGE_SUCCESS]: (state, action) => {
        state.loading = false;
        state.imageUploadUrl = action.payload; // Store the uploaded image URL
    },
    [UPLOAD_IMAGE_FAILURE]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    [UPLOAD_VIDEO_REQUEST]: (state) => {
        state.loading = true;
    },
    [UPLOAD_VIDEO_SUCCESS]: (state, action) => {
        state.loading = false;
        state.videoUploadUrl = action.payload; // Store the uploaded video URL
    },
    [UPLOAD_VIDEO_FAILURE]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    [RESHARE_POST_REQUEST]: (state) => {
        state.loading = true;
        state.error = null;
    },
    [RESHARE_POST_SUCCESS]: (state, action) => {
        state.loading = false;
        const resharedPost = {
            ...action.payload,
            likes: {}, 
            comments: [] 
        };
        state.posts.unshift(resharedPost);
        state.error = null;
    },
    
    [RESHARE_POST_FAILURE]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },

  // Handle fetch posts request
  [FETCH_POSTS_REQUEST]: (state) => {
    state.loading = true;
    state.error = null;
  },

  [FETCH_POSTS_SUCCESS]: (state, action) => {
    state.loading = false;
    state.posts = [...state.posts, ...action.payload.posts];
    state.page = action.payload.page;
    state.totalPages = action.payload.totalPages;
    state.error = null;
},


  // Handle fetch posts failure
  [FETCH_POSTS_FAILURE]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Assuming the payload is the error
  },

  [LIKE_POST_SUCCESS]: (state, action) => {
    const { postId, likes } = action.payload;
    const index = state.posts.findIndex(post => post._id === postId);
    if (index !== -1) {
      state.posts[index].likes = likes; // Update likes for the post
    }
  },
  [POST_COMMENT_SUCCESS]: (state, action) => {
    const { postId, comment } = action.payload;
    if (!state.comments[postId]) {
      state.comments[postId] = [comment];
    } else {
      state.comments[postId].push(comment);
    }
  },

  [EDIT_POST_SUCCESS]: (state, action) => {
    const index = state.posts.findIndex(post => post._id === action.payload._id);
    if (index !== -1) {
      const updatedPosts = [...state.posts];
      updatedPosts[index] = { ...updatedPosts[index], ...action.payload };
      return {
        ...state,
        posts: updatedPosts,
      };
    }
    return state; // Return the state unchanged if the post isn't found
  },
  [EDIT_COMMENT_SUCCESS]: (state, action) => {
    const { postId, commentId, updatedComment } = action.payload;
    if (state.comments[postId]) {
      const index = state.comments[postId].findIndex(comment => comment._id === commentId);
      if (index !== -1) {
        state.comments[postId][index] = updatedComment; // Update the comment with the new content
      }
    }
  },
  [DELETE_POST_SUCCESS]: (state, action) => {
    const postId = action.payload;
    state.posts = state.posts.filter(post => post._id !== postId); // Remove the post from the state
    delete state.comments[postId]; // Also remove related comments
  },
  [DELETE_COMMENT_SUCCESS]: (state, action) => {
    const { postId, commentId } = action.payload;
    if (state.comments[postId]) {
      state.comments[postId] = state.comments[postId].filter(comment => comment._id !== commentId); // Remove the comment from the state
    }
  },
  [FETCH_COMMENTS_SUCCESS]: (state, action) => {
    const { postId, comments } = action.payload;
    state.comments[postId] = comments; // Update the state with fetched comments
  },
  [OPERATION_FAIL]: (state, action) => {
    state.error = action.payload; // Set the error received from any operation
  },
  [OPERATION_REQUEST]: (state) => {
    state.loading = true; // Set loading state at the beginning of any operation
  },
  [OPERATION_COMPLETE]: (state) => {
    state.loading = false;
    state.error = null; // Clear any error at the end of an operation
  },
  [CLEAR_ERRORS]: (state) => {
    state.error = null; // Clear any existing errors
  },
});



