import React, { useState, useEffect } from "react";
import {
  AiFillHeart,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";

const ProductCard = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const handleQuantityChange = (event, amount) => {
    event.stopPropagation();

    if (amount < 0) {
      setQuantity(Math.max(1, quantity + amount));
    } else {
      setQuantity(Math.min(data.stock, quantity + amount));
    }
  };

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: quantity };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  return (
    <>
      <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} className="w-full h-[420px] rounded-lg shadow-sm p-3 relative cursor-pointer">
        
        <Link to={`${isEvent === true ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
          <img src={`${data.images && data.images[0]?.url}`} alt="" className="w-full h-[170px] object-contain" />
          <h5 className={`${styles.shop_name}`}>{data.shop.name}</h5>
          <h4 className="pb-3 font-[500] items-center">
            {data.name.length > 40 ? data.name.slice(0, 40) + "..." : data.name}
          </h4>

          <div className="flex">
            <Ratings rating={data?.ratings} />
          </div>

          <div className="py-2 flex items-center justify-between">
            <div className="flex">
              <h5 className={`${styles.productDiscountPrice}`}>
               $ {data.originalPrice === 0 ? data.originalPrice : data.discountPrice} 
              </h5>
              <h4 className={`${styles.price}`}>
                {"$" + data.originalPrice ? data.originalPrice : null}
              </h4>
            </div>
            <span className="font-[400] text-[17px] text-[#68d284]">
              {data?.sold_out} sold
            </span>
          </div>
        </Link>

        {/* Add to cart with quantity selector */}
        <div className="flex items-center justify-center mt-3"> 
          <button 
            onClick={(e) => handleQuantityChange(e, -1)} 
            className="border px-2">
            -
          </button>
          <span className="mx-2">{quantity}</span>
          <button 
            onClick={(e) => handleQuantityChange(e, 1)} 
            className="border px-2" 
            disabled={quantity >= data.stock}>
            +
          </button>
          <AiOutlineShoppingCart
            size={25}
            className="cursor-pointer ml-2"
            onClick={(e) => {
              e.stopPropagation();
              addToCartHandler(data._id);
            }}
            color="#444"
            title="Add to cart"
          />
        </div>

        <div className="absolute right-2 top-5 flex items-center justify-center bg-green-700 rounded-full p-1">
  {click ? (
    <AiFillHeart
      size={22}
      className="cursor-pointer"
      onClick={() => removeFromWishlistHandler(data)}
      color={click ? "red" : "#333"}
      title="Remove from wishlist"
    />
  ) : (
    <AiOutlineHeart
      size={22}
      className="cursor-pointer"
      onClick={() => addToWishlistHandler(data)}
      color={click ? "red" : "#333"}
      title="Add to wishlist"
    />
  )}
</div>

<div className="absolute right-2 top-14 flex items-center justify-center bg-green-700 rounded-full p-1">
  <AiOutlineEye
    size={22}
    className="cursor-pointer"
    onClick={() => setOpen(!open)}
    color="#333"
    title="Quick view"
  />
</div>

{open && <ProductDetailsCard setOpen={setOpen} data={data} />}

{data.brandTypeSelection === 'branded' && (
  <div className="absolute left-2 top-5">
    <img
      src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1710252613/feat_morfbr.png"
      alt="Featured Brand"
      className="w-10 h-10"
    />
  </div>
)}

      </div>
    </>
  );
};

export default ProductCard;


