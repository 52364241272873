import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useSelector, useDispatch } from 'react-redux';
import { loadSeller } from "../../redux/actions/user";
import FollowerListWidget from './FollowersListWidget';
import FollowingListWidget from './FollowingListWidget';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function FollowersFollowingPanel() {
  const [open, setOpen] = useState(true);
  const [activeTab, setActiveTab] = useState('Followers');
  const dispatch = useDispatch();
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const profileId = currentProfile === 'User' ? user?._id : seller?._id;
  const profileType = currentProfile === 'User' ? 'User' : 'Shop'

  useEffect(() => {
    dispatch(loadSeller()); // Assuming you might need to load user data; adjust as necessary
  }, [dispatch]);

  const tabs = [
    { name: 'Followers', current: activeTab === 'Followers' },
    { name: 'Following', current: activeTab === 'Following' },
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">Social Connections</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mb-4 border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                          {tabs.map((tab) => (
                            <a
                              key={tab.name}
                              href="#"
                              className={classNames(
                                tab.current ? 'border-green-500 text-green-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                              )}
                              aria-current={tab.current ? 'page' : undefined}
                              onClick={() => setActiveTab(tab.name)}
                            >
                              {tab.name}
                            </a>
                          ))}
                        </nav>
                      </div>
                    </div>
                    {activeTab === 'Followers' && <FollowerListWidget  key={profileId} userId={profileId} profileType={profileType} />}
                    {activeTab === 'Following' && <FollowingListWidget  key={profileId} userId={profileId} profileType={profileType} />}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
