import React from 'react';

const GoogleCalendarButton = ({ event }) => {
    const formatDate = (date) => {
        try {
            const d = new Date(date);
            if (isNaN(d.getTime())) {
                throw new Error('Invalid date');
            }
            return d.toISOString().replace(/-|:|\.\d\d\d/g, '');
        } catch (error) {
            console.error('Date formatting error:', error);
            return ''; 
        }
    };
    

    const googleCalendarUrl = () => {
        const startTime = formatDate(event.date);
        const endTime = formatDate(new Date(new Date(event.date).getTime() + 2 * 60 * 60 * 1000)); // Assuming a 2 hour event
        return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.title)}&dates=${startTime}/${endTime}&details=${encodeURIComponent(event.description)}`;
    };

    const buttonStyle = {
        backgroundColor: '#1a73e8', // Google blue
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
        fontFamily: '"Google Sans", sans-serif',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease'
    };

    return (
        <a href={googleCalendarUrl()} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button style={buttonStyle} onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#2c83f6'} onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1a73e8'}>
                Add to Google Calendar
            </button>
        </a>
    );
};

export default GoogleCalendarButton;

