import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProduct } from "../../redux/actions/product";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import { Switch } from '@headlessui/react';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { fetchBrands, fetchBrandCatalogItems } from "../../redux/actions/brand";
import TagSelector from "../Tags/TagSelector";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CreateProduct = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.products);
  const { brands } = useSelector(state => state.brand);
  const catalogItems = useSelector(state => state.brand.catalogItems);
  const [brandTypeSelection, setBrandTypeSelection] = useState('custom'); 
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedCatalogItem, setSelectedCatalogItem] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [productType, setProductType] = useState(""); 
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]); 
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [stock, setStock] = useState();
  const [availableForPickup, setAvailableForPickup] = useState(false);
  const [availableForDelivery, setAvailableForDelivery] = useState(false);
  const [brand, setBrand] = useState("");
  const [SKU, setSKU] = useState("");
  const [lowInventoryThreshold, setLowInventoryThreshold] = useState();
  const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
  const [containsTHC, setContainsTHC] = useState(false);
  const [containsCBD, setContainsCBD] = useState(false);
  const [barcode, setBarcode] = useState([]);
  const [barcodePreviews, setBarcodePreviews] = useState([]);
  const [testResults, setTestResults] = useState([]);
  const [testResultPreviews, setTestResultPreviews] = useState([]);
  const [nutritionInfoImage, setNutritionInfoImage] = useState([]);
  const [nutritionInfoImagePreviews, setNutritionInfoImagePreviews] = useState([]);
  const [isEdible, setIsEdible] = useState(false);
  const [cbd, setCbd] = useState(0.0);
  const [terpenes, setTerpenes] = useState(0.0);
  const [thc, setThc] = useState(0.0);
  const [cbdUnit, setCbdUnit] = useState("%");
  const [terpenesUnit, setTerpenesUnit] = useState("%");
  const [thcUnit, setThcUnit] = useState("%");
  const [isDragOver, setIsDragOver] = useState(false);
  const [videos, setVideos] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const [barcodeString, setBarcodeString] = useState('');
  const [catalogImages, setCatalogImages] = useState([]);
  const [catalogVideos, setCatalogVideos] = useState([]);
  const [catalogBarcodes, setCatalogBarcodes] = useState([]);
  const [catalogTestResults, setCatalogTestResults] = useState([]);
  const [catalogNutritionInfoImages, setCatalogNutritionInfoImages] = useState([]);
  const [isOnSale, setIsOnSale] = useState(false);




  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Product created successfully!");
      navigate(`/dashboard/${seller.handle}`);
      window.location.reload();
    }
  }, [dispatch, error, success]);

  useEffect(() => {
    if (brandTypeSelection === 'branded') {
      dispatch(fetchBrands());
    }
  }, [brandTypeSelection, dispatch]);

  useEffect(() => {
    if (selectedBrand) {
      dispatch(fetchBrandCatalogItems(selectedBrand));
    }
  }, [selectedBrand, dispatch]);

  useEffect(() => {
    if (selectedCatalogItem) {
      // Basic Information
      setName(selectedCatalogItem.name);
      setDescription(selectedCatalogItem.description);
      setCategory(selectedCatalogItem.category);
      setSKU(selectedCatalogItem.SKU);
      setTags(selectedCatalogItem.tags);
      setProductType(selectedCatalogItem.productType);
      setUnitOfMeasurement(selectedCatalogItem.unitOfMeasurement);
      setContainsTHC(selectedCatalogItem.containsTHC);
      setContainsCBD(selectedCatalogItem.containsCBD);
      setIsEdible(selectedCatalogItem.isEdible);
      setBrand(selectedCatalogItem.brand.name);
      setDiscountPrice(selectedCatalogItem.priceFloor);
  
      // Images and Videos
      const imageUrls = selectedCatalogItem?.images.map(image => image.url);
      setImagePreviews(imageUrls);
      setCatalogImages(selectedCatalogItem.images);
      const videoUrls = selectedCatalogItem?.videos.map(video => video.url);
      setVideoPreviews(videoUrls);
      setCatalogVideos(selectedCatalogItem?.videos);
  
      // Test Results and Nutrition Info Images
      const testResultUrls = selectedCatalogItem?.testResults.map(result => result.url);
      setTestResultPreviews(testResultUrls);
      setCatalogTestResults(selectedCatalogItem?.testResults);


      const nutritionInfoImageUrls = selectedCatalogItem?.nutritionInfoImage.map(image => image.url);
      setNutritionInfoImagePreviews(nutritionInfoImageUrls);
      setCatalogNutritionInfoImages(selectedCatalogItem?.nutritionInfoImage);

      const barcodeUrls = selectedCatalogItem?.barcodes.map(result => result.url);
      setBarcodePreviews(barcodeUrls);
      setCatalogBarcodes(selectedCatalogItem?.barcodes);
    }
  }, [selectedCatalogItem]);
  
  // Assuming there's a function or effect that updates the discountPrice based on user input
const handleDiscountPriceChange = (newPrice) => {
  if (selectedCatalogItem) {
    // Ensure the new price is not lower than the priceFloor
    const adjustedPrice = Math.max(newPrice, selectedCatalogItem.priceFloor);
    setDiscountPrice(adjustedPrice);
  } else {
    // If no catalog item is selected, or you want to allow setting the price freely when not restricted
    setDiscountPrice(newPrice);
  }
};



  const handleImageChange = (e, fieldName) => {
    const newFiles = Array.from(e.target.files);
    let updatedFiles = [];
    let updatedPreviews = [];

    switch(fieldName) {
        case "images":
            updatedFiles = [...images, ...newFiles]; // Append new files to existing files
            updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
            setImages(updatedFiles);
            setImagePreviews(updatedPreviews);
            break;
        case "barcode":
            // Similar approach for barcodes
            updatedFiles = [...barcode, ...newFiles];
            updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
            setBarcode(updatedFiles);
            setBarcodePreviews(updatedPreviews);
            break;
        case "testResults":
            // Similar approach for test results
            updatedFiles = [...testResults, ...newFiles];
            updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
            setTestResults(updatedFiles);
            setTestResultPreviews(updatedPreviews);
            break;
        case "nutritionInfoImage":
            // Similar approach for nutrition info images
            updatedFiles = [...nutritionInfoImage, ...newFiles];
            updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
            setNutritionInfoImage(updatedFiles);
            setNutritionInfoImagePreviews(updatedPreviews);
            break;
        default:
            // Handle error or unexpected field name
    }
};

const handleVideoChange = (e) => {
  const newFiles = Array.from(e.target.files);
  const updatedFiles = [...videos, ...newFiles];
  const updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
  setVideos(updatedFiles);
  setVideoPreviews(updatedPreviews);
};

const handleVideoDrop = (e) => {
  e.preventDefault();
  e.stopPropagation();
  setIsDragOver(false); // Assuming you have a state for handling drag over UI changes

  const newFiles = Array.from(e.dataTransfer.files).filter(
      file => file.type.startsWith('video/')
  );
  let updatedFiles = [...videos, ...newFiles];
  let updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
  setVideos(updatedFiles);
  setVideoPreviews(updatedPreviews);
};

const handleRemoveVideo = (index) => {
  const newVideos = videos.filter((_, i) => i !== index);
  const newPreviews = newVideos.map(file => URL.createObjectURL(file));
  setVideos(newVideos);
  setVideoPreviews(newPreviews);
};


  


  const handleProductTypeChange = (e) => {
    const value = e.target.value;
    setProductType((prevTypes) => {
      if (prevTypes.includes(value)) {
        return prevTypes.filter((type) => type !== value);
      } else {
        return [...prevTypes, value];
      }
    });
  };

  const handleRemoveImage = (index, files, setFiles, setPreviews) => {
    const newFiles = files.filter((_, i) => i !== index);
    const newPreviews = newFiles.map(file => URL.createObjectURL(file));
    setFiles(newFiles);
    setPreviews(newPreviews);
  };

  const onDragEnd = (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }
  
    const newImages = Array.from(images);
    const newPreviews = Array.from(imagePreviews);
  
    // Reordering the array items
    const [removedImage] = newImages.splice(result.source.index, 1);
    const [removedPreview] = newPreviews.splice(result.source.index, 1);
  
    newImages.splice(result.destination.index, 0, removedImage);
    newPreviews.splice(result.destination.index, 0, removedPreview);
  
    // Updating state
    setImages(newImages);
    setImagePreviews(newPreviews);
  };
  
  
  const handleImageDrop = (e, fieldName) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);

    const newFiles = Array.from(e.dataTransfer.files);
    let updatedFiles = [];
    let updatedPreviews = [];

    switch(fieldName) {
        case "images":
            updatedFiles = [...images, ...newFiles]; // Append new files to existing files
            updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
            setImages(updatedFiles);
            setImagePreviews(updatedPreviews);
            break;
        case "barcode":
            // Similar approach for barcodes
            updatedFiles = [...barcode, ...newFiles];
            updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
            setBarcode(updatedFiles);
            setBarcodePreviews(updatedPreviews);
            break;
        case "testResults":
            // Similar approach for test results
            updatedFiles = [...testResults, ...newFiles];
            updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
            setTestResults(updatedFiles);
            setTestResultPreviews(updatedPreviews);
            break;
        case "nutritionInfoImage":
            // Similar approach for nutrition info images
            updatedFiles = [...nutritionInfoImage, ...newFiles];
            updatedPreviews = updatedFiles.map(file => URL.createObjectURL(file));
            setNutritionInfoImage(updatedFiles);
            setNutritionInfoImagePreviews(updatedPreviews);
            break;
        default:
            // Handle error or unexpected field name
    }
};


  const newForm = new FormData();

  const handleSubmit = (e) => {
    e.preventDefault();

    const aggregateMetrics = {
      cbd: cbd,
      terpenes: terpenes,
      thc: thc,
      cbd_unit: cbdUnit,
      terpenes_unit: terpenesUnit,
      thc_unit: thcUnit
    };
    newForm.append("aggregate_metrics", JSON.stringify(aggregateMetrics));

      images.forEach(images => newForm.append('images', images));
      newForm.append('catalogImages', JSON.stringify(catalogImages));

      barcode.forEach(barcode => newForm.append('barcode', barcode));
      newForm.append('catalogBarcodes', JSON.stringify(catalogBarcodes));
      
      testResults.forEach(testResults => newForm.append('testResults', testResults));
      newForm.append('catalogTestResults', JSON.stringify(catalogTestResults));

      nutritionInfoImage.forEach(nutritionInfoImage => newForm.append('nutritionInfoImage', nutritionInfoImage));
      newForm.append('catalogNutritionInfoImages', JSON.stringify(catalogNutritionInfoImages));

      videos.forEach(videos => newForm.append('videos', videos));
      newForm.append('catalogVideos', JSON.stringify(catalogVideos));


    newForm.append("name", name);
    newForm.append("brandTypeSelection", brandTypeSelection);
    newForm.append("description", description);
    newForm.append("category", category);
    newForm.append("tags", tags);
    newForm.append("originalPrice", originalPrice);
    newForm.append("discountPrice", discountPrice);
    newForm.append("stock", stock);
    newForm.append("brand", brand);
    newForm.append("shopId", seller._id);
    newForm.append("handle", seller.handle);
    newForm.append("productType", JSON.stringify(productType));
    newForm.append("availableForPickup", availableForPickup);
    newForm.append("availableForDelivery", availableForDelivery);
    newForm.append("SKU", SKU);
    newForm.append("lowInventoryThreshold", lowInventoryThreshold);
    newForm.append("unitOfMeasurement", unitOfMeasurement);
    newForm.append("containsTHC", containsTHC);
    newForm.append("containsCBD", containsCBD);
    newForm.append("isEdible", isEdible);
    newForm.append("barcodeString", barcodeString);
    newForm.append('isOnSale', isOnSale);

    const config = {
      
      headers: {
          'Content-Type': 'multipart/form-data',
          'current-profile': seller.name || 'defaultProfile', // Use the seller's name or a default value
      },
      withCredentials: true,
  };

  dispatch(createProduct(newForm, config));
};


  const preventScrollChange = (event) => {
    event.target.blur();
  };

  const updateTags = (newTags) => {
    setTags(newTags);
  };

return (
  <div className="w-[90%] md:w-[90%] lg:w-[90%] bg-white shadow-lg rounded-lg p-6 overflow-y-auto">
    <h5 className="text-2xl font-semibold text-gray-800 text-center mb-6">Create A Product</h5>
    <div className="py-4">
  <fieldset>
    <legend className="sr-only">Product Type</legend>
    <div className="text-base font-semibold text-gray-900 mb-2">Product Type</div>
    <p className="text-sm text-gray-500 mb-4">Choose a product type:</p>
    <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
      <div className="flex items-center">
        <input
          type="radio"
          name="productType"
          value="custom"
          checked={brandTypeSelection === 'custom'}
          onChange={() => setBrandTypeSelection('custom')}
          className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-600"
          id="custom"
        />
        <label htmlFor="custom" className="ml-3 block text-sm font-medium text-gray-900">
          Custom
        </label>
      </div>
      <div className="flex items-center">
        <input
          type="radio"
          name="productType"
          value="branded"
          checked={brandTypeSelection === 'branded'}
          onChange={() => setBrandTypeSelection('branded')}
          className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-600"
          id="branded"
        />
        <label htmlFor="branded" className="ml-3 block text-sm font-medium text-gray-900">
          Branded
        </label>
      </div>
    </div>
  </fieldset>
</div>


      {/* Conditional Brand Selection Dropdown */}
{brandTypeSelection === 'branded' && (
  <select
    value={selectedBrand}
    onChange={(e) => setSelectedBrand(e.target.value)}
    className="form-select block w-full px-3 py-2 border border-gray-300 bg-white text-gray-700 text-base rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
  >
    <option value="">Select a Brand</option>
    {brands.map((brand) => (
      <option key={brand._id} value={brand._id}>{brand.name}</option>
    ))}
  </select>
)}

<div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
{selectedBrand && catalogItems.map((item) => (
              <div key={item._id} className="relative cursor-pointer" onClick={() => setSelectedCatalogItem(item)}>
                <div className="relative h-72 w-full overflow-hidden rounded-lg">
                  {item.images.length > 0 && (
                    <img src={item.images[0].url} alt={item.name} className="h-full w-full object-cover object-center" />
                  )}
                </div>
                <div className="relative mt-4">
                  <h3 className="text-sm font-medium text-gray-900">{item.name}</h3>
                  <p className="mt-1 text-sm text-gray-500">{item.category}</p>
                  <p className="mt-2 text-sm font-semibold text-gray-700">${item.priceFloor}</p>
                </div>
              </div>
            ))}
</div>


    <form onSubmit={handleSubmit} className="space-y-4">
      {/* Name Field */}
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
<div className="mb-4">
    <label htmlFor="name" className="block text-sm font-semibold text-gray-700 mb-2">
        Name <span className="text-green-500">*</span>
    </label>
    <input
        type="text"
        name="name"
        id="name"
        value={name}
        required
        onChange={(e) => setName(e.target.value)}
        className="mt-1 p-2 focus:ring-2 focus:ring-green-600 focus:border-transparent block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out bg-gray-900 text-white h-10" // Added black text color
        placeholder="Enter your product name..."
    />
</div>
</div>
</div>
{/* Image Upload Section */}
<div className="flex flex-col items-center justify-center">
    <label htmlFor="upload" className="block text-sm font-medium text-gray-700 mb-3">
        Upload Product Images
    </label>

    <div 
        className={`flex justify-center rounded-lg border ${
          isDragOver ? 'border-green-500' : 'border-dashed border-gray-300'
      } px-6 py-10 w-full`}
        onDragEnter={() => setIsDragOver(true)}
        onDragLeave={() => setIsDragOver(false)}
        onDragOver={(e) => { e.preventDefault(); setIsDragOver(true); }}
        onDrop={(e) => handleImageDrop(e, 'images')} 
    >
        <div className="text-center">
            <PhotoIcon className="mx-auto h-12 w-12 text-gray-500" aria-hidden="true" />
            <label htmlFor="upload" className="relative cursor-pointer rounded-md font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-green-500">
                <span>Drag and drop your images here or click to upload</span>
                <input 
                    type="file" 
                    id="upload" 
                    multiple 
                    onChange={(e) => handleImageChange(e, 'images', setImages)} 
                    className="sr-only" 
                />
            </label>
            <p className="text-xs leading-5 text-gray-400">PNG, JPG, GIF up to 10MB</p>
        </div>
    </div>

{/* Drag-and-Drop Image Previews */}
<DragDropContext onDragEnd={onDragEnd}>
  <Droppable droppableId="droppableImages" direction="horizontal">
    {(provided) => (
      <div
        {...provided.droppableProps}
        ref={provided.innerRef}
        className="flex flex-wrap mt-4 gap-4"
      >
        {imagePreviews.map((imageUrl, index) => (
          <Draggable key={index} draggableId={`image-${index}`} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={`relative m-2 ${index === 0 ? 'border-4 border-white' : 'border border-green-500'} rounded-lg shadow-sm overflow-hidden ${snapshot.isDragging ? "opacity-75" : ""}`}
                style={{ 
                  ...provided.draggableProps.style, 
                  height: index === 0 ? '216px' : '144px', // Larger height for the first image
                  width: index === 0 ? '216px' : '144px', // Larger width for the first image
                }}
              >
                <img 
                  src={imageUrl} 
                  alt={`Uploaded ${index}`} 
                  className={`object-cover ${index === 0 ? 'h-54 w-54' : 'h-36 w-36'}`} // Larger size for the first image
                />
                
                {/* Number Badge */}
                <div className="absolute top-0 left-0 bg-green-600 text-white text-xs px-2 py-1">
                  {index + 1}
                </div>

                <button
                  onClick={() => handleRemoveImage(index, images, setImages, setImagePreviews)}
                  className="absolute top-1 right-1 bg-red-600 text-white rounded-full p-1 hover:bg-red-700 transition duration-200"
                  aria-label="Remove image"
                >
                  &times;
                </button>
              </div>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
</DragDropContext>
</div>


<div className="flex flex-col items-center justify-center">
    <label htmlFor="video-upload" className="block text-sm font-medium text-gray-700 mb-3">
        Upload A Product Video
    </label>

    <div 
      className={`flex justify-center rounded-lg border ${
        isDragOver ? 'border-green-500' : 'border-dashed border-gray-300'
      } px-6 py-10 w-full`}
      onDragEnter={() => setIsDragOver(true)}
      onDragLeave={() => setIsDragOver(false)}
      onDragOver={(e) => { e.preventDefault(); setIsDragOver(true); }}
      onDrop={(e) => handleVideoDrop(e)} // Implement handleVideoDrop similar to handleImageDrop
    >
        <div className="text-center">
            <PhotoIcon className="mx-auto h-12 w-12 text-gray-500" aria-hidden="true" />
            <label htmlFor="video-upload" className="relative cursor-pointer rounded-md font-semibold text-gray-700 hover:text-green-500">
                <span>Drag and drop your videos here or click to upload</span>
                <input 
                    type="file" 
                    id="video-upload" 
                    multiple 
                    onChange={handleVideoChange} 
                    className="sr-only" 
                />
            </label>
            <p className="text-xs leading-5 text-gray-400">MP4 up to 50MB</p>
        </div>
    </div>

    {/* Display uploaded video previews */}
    <div className="flex flex-wrap mt-4 gap-4">
      {videoPreviews.map((videoUrl, index) => (
        <div key={index} className="relative m-2 border border-green-500 rounded-lg shadow-sm overflow-hidden">
          <video src={videoUrl} alt="Video Preview" className="h-54 w-54 object-cover" controls/>
          <button 
            onClick={() => handleRemoveVideo(index)} 
            className="absolute top-1 right-1 bg-red-600 text-white rounded-full p-1 hover:bg-red-700 transition duration-200"
            aria-label="Remove video"
            >
            ×
            </button>
            </div>
            ))}
            </div>
            
            </div>



      {/* Description Field */}
      <div>
        <label htmlFor="description" className="block text-sm font-medium text-gray-700">
          Description <span className="text-green-500">*</span>
        </label>
        <textarea
          id="description"
          name="description"
          rows={4}
          required
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="mt-1 p-2 focus:ring-2 focus:ring-green-600 focus:border-transparent block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out bg-gray-900 text-white h-10"
          placeholder="Enter your product description..."
        />
      </div>

      {/* Product Type */}
      <div className="mt-10">
        <label className="block text-sm font-medium text-gray-700">
          Product Type <span className="text-green-500">*</span>
        </label>
        <div className="mt-2">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              value="retail"
              checked={productType.includes("retail")}
              onChange={handleProductTypeChange}
              className="form-checkbox h-5 w-5 text-green-600"
            />
            <span className="ml-2 text-gray-700">Retail</span>
          </label>
          <label className="inline-flex items-center ml-6">
            <input
              type="checkbox"
              value="wholesale"
              checked={productType.includes("wholesale")}
              onChange={handleProductTypeChange}
              className="form-checkbox h-5 w-5 text-green-600"
            />
            <span className="ml-2 text-gray-700">Wholesale</span>
          </label>
        </div>
      </div>

     {/* SKU and Brand Fields Container */}
     <div className="mt-10">
<div className="flex justify-between gap-4 mt-6">
    {/* SKU Field */}
    <div className="flex-1">
        <label htmlFor="sku" className="block text-sm font-medium text-gray-700">
            SKU
        </label>
        <input
            type="text"
            name="sku"
            id="sku"
            value={SKU}
            onChange={(e) => setSKU(e.target.value)}
            className="mt-1 p-2 focus:ring-2 focus:ring-green-600 focus:border-transparent block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out bg-gray-900 text-white h-10"
            placeholder="Enter SKU..."
        />
    </div>

    {/* Brand Field */}
    <div className="flex-1">
        <label htmlFor="brand" className="block text-sm font-medium text-gray-700">
            Brand <span className="text-green-500">*</span>
        </label>
        <input
            type="text"
            name="brand"
            id="brand"
            value={brand}
            required
            onChange={(e) => setBrand(e.target.value)}
            className="mt-1 p-2 focus:ring-2 focus:ring-green-600 focus:border-transparent block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out bg-gray-900 text-white h-10"
            placeholder="Enter your product brand..."
        />
    </div>
</div>
</div>

{/* Category Dropdown */}
<div className="mt-10">
  <label htmlFor="category" className="block text-sm font-medium text-gray-700">
    Category <span className="text-green-500">*</span>
  </label>
  <select
    id="category"
    name="category"
    value={category}
    required
    onChange={(e) => setCategory(e.target.value)}
    className="mt-1 block w-full md:w-5/12 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  >
    <option value="">Choose a category</option>
    {categoriesData.map((cat, index) => (
      <option key={index} value={cat.title}>{cat.title}</option>
    ))}
  </select>
</div>

{/* on sale toggle */}
<div className="mt-10">
  <Switch.Group as="div" className="flex items-center justify-between">
    <span className="flex-grow flex flex-col">
      <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
        On Sale
      </Switch.Label>
    </span>
    <Switch
      checked={isOnSale}
      onChange={setIsOnSale}
      className={`${isOnSale ? 'bg-green-600' : 'bg-gray-200'} relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
    >
      <span
        className={`${isOnSale ? 'translate-x-6' : 'translate-x-1'} inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
      />
    </Switch>
  </Switch.Group>
</div>


{/* Original Price and Discount Price Fields Container */}
{/* Original Price Field */}
<div className="flex-1">
  <label htmlFor="original-price" className="block text-sm font-medium text-gray-700">
    Original Price <span className="text-green-500">*</span>
  </label>
  <input
    type="number"
    name="original-price"
    id="original-price"
    value={originalPrice}
    onChange={(e) => {
      setOriginalPrice(e.target.value);
      if (!isOnSale) {
        setDiscountPrice(e.target.value); // Auto-set discount price if not on sale
      }
    }}
    onWheel={preventScrollChange}
    className="mt-1 p-2 focus:ring-2 focus:ring-green-600 focus:border-transparent block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out bg-gray-900 text-white h-10"
    placeholder="Enter the original price..."
  />
</div>

{/* Discount Price Field, only if on sale */}
{isOnSale && (
  <div className="flex-1">
    <label htmlFor="discount-price" className="block text-sm font-medium text-gray-700">
      Discount Price
    </label>
    <input
      type="number"
      name="discount-price"
      id="discount-price"
      value={discountPrice}
      onChange={(e) => handleDiscountPriceChange(e.target.value)}
      onWheel={preventScrollChange}
      className="mt-1 p-2 focus:ring-2 focus:ring-green-600 focus:border-transparent block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out bg-gray-900 text-white h-10"
      placeholder="Enter the discounted price..."
    />
  </div>
)}


{/* Stock and Low Inventory Threshold Fields Container */}
<div className="mt-10">
<div className="flex justify-between gap-4 mt-6">
    {/* Stock Field */}
    <div className="flex-1">
        <label htmlFor="stock" className="block text-sm font-medium text-gray-700">
            Stock <span className="text-green-500">*</span>
        </label>
        <input
            type="number"
            name="stock"
            id="stock"
            value={stock}
            onChange={(e) => setStock(e.target.value)}
            onWheel={preventScrollChange}
            className="mt-1 p-2 focus:ring-2 focus:ring-green-600 focus:border-transparent block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out bg-gray-900 text-white h-10"
            placeholder="Enter the stock amount..."
        />
    </div>

    {/* Low Inventory Threshold Field */}
    <div className="flex-1">
        <label htmlFor="low-inventory-threshold" className="block text-sm font-medium text-gray-700">
            Low Inventory Threshold
        </label>
        <input
            type="number"
            name="low-inventory-threshold"
            id="low-inventory-threshold"
            value={lowInventoryThreshold}
            onChange={(e) => setLowInventoryThreshold(e.target.value)}
            onWheel={preventScrollChange}
            className="mt-1 p-2 focus:ring-2 focus:ring-green-600 focus:border-transparent block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out bg-gray-900 text-white h-10"
            placeholder="Set the low inventory threshold..."
        />
    </div>
</div>
</div>


{/* Unit of measurement dropdown */}
<div className="mt-10">
  <label htmlFor="unit-of-measurement" className="block text-sm font-medium text-gray-700">
    Unit of Measurement
  </label>
  <select
    id="unit-of-measurement"
    name="unit-of-measurement"
    value={unitOfMeasurement}
    onChange={(e) => setUnitOfMeasurement(e.target.value)}
    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  >
    <option value="">Select Unit</option>
    <option value="g">Grams (g)</option>
    <option value="lbs">Pounds (lbs)</option>
    <option value="kg">Kilograms (kg)</option>
  </select>
</div>


{/* Tags Field */}
<div className="mt-10">
<TagSelector selectedTags={tags} updateTags={updateTags} />
</div>

      {/* Toggle Switches */}
     
{/* First Row: Available for Pickup and Available for Delivery */}
<div className="mt-10">
<div className="flex justify-start gap-4 mb-4">
    {/* Available for Pickup Toggle */}
    <div className="flex items-center space-x-3">
        <Switch
            checked={availableForPickup}
            onChange={setAvailableForPickup}
            className={classNames(
                availableForPickup ? 'bg-green-600' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-[24px] w-[48px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
            )}
        >
            <span className="sr-only">Available for Pickup</span>
            <span
                aria-hidden="true"
                className={classNames(
                    availableForPickup ? 'translate-x-6' : 'translate-x-0',
                    'pointer-events-none inline-block h-[20px] w-[20px] rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
            />
        </Switch>
        <span className="text-sm font-medium text-gray-700">Available for Pickup</span>
    </div>

    {/* Available for Delivery Toggle */}
    <div className="flex items-center space-x-3">
        <Switch
            checked={availableForDelivery}
            onChange={setAvailableForDelivery}
            className={classNames(
                availableForDelivery ? 'bg-green-600' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-[24px] w-[48px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
            )}
        >
            <span className="sr-only">Available for Delivery</span>
            <span
                aria-hidden="true"
                className={classNames(
                    availableForDelivery ? 'translate-x-6' : 'translate-x-0',
                    'pointer-events-none inline-block h-[20px] w-[20px] rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
            />
        </Switch>
        <span className="text-sm font-medium text-gray-700">Available for Delivery</span>
    </div>
</div>
</div>

{/* Second Row: Contains THC and Contains CBD */}
<div className="mt-10">
<div className="flex justify-start gap-4">
    {/* Contains THC Toggle */}
    <div className="flex items-center space-x-3">
        <Switch
            checked={containsTHC}
            onChange={setContainsTHC}
            className={classNames(
                containsTHC ? 'bg-green-600' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-[24px] w-[48px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
            )}
        >
            <span className="sr-only">Contains THC</span>
            <span
                aria-hidden="true"
                className={classNames(
                    containsTHC ? 'translate-x-6' : 'translate-x-0',
                    'pointer-events-none inline-block h-[20px] w-[20px] rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
            />
        </Switch>
        <span className="text-sm font-medium text-gray-700">Contains THC</span>
    </div>

    {/* Contains CBD Toggle */}
    <div className="flex items-center space-x-3">
        <Switch
            checked={containsCBD}
            onChange={setContainsCBD}
            className={classNames(
                containsCBD ? 'bg-green-600' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-[24px] w-[48px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
            )}
        >
            <span className="sr-only">Contains CBD</span>
            <span
                aria-hidden="true"
                className={classNames(
                    containsCBD ? 'translate-x-6' : 'translate-x-0',
                    'pointer-events-none inline-block h-[20px] w-[20px] rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
            />
        </Switch>
        <span className="text-sm font-medium text-gray-700">Contains CBD</span>
    </div>
</div>
</div>

{/* Aggregate Metrics Input Fields */}
{ (containsTHC || containsCBD) && (
<div className="mt-4">
  <div className="grid grid-cols-2 gap-4">
    {/* CBD Field */}
    <div>
      <label htmlFor="cbd" className="block text-sm font-medium text-gray-700">
        CBD
      </label>
      <input
        type="number"
        id="cbd"
        value={cbd}
        onChange={(e) => setCbd(e.target.value)}
        onWheel={preventScrollChange}
        className="mt-1 p-2 focus:ring-2 focus:ring-green-600 focus:border-transparent block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out bg-gray-900 text-white h-10"
      />
      <select
        id="cbd_unit"
        value={cbdUnit}
        onChange={(e) => setCbdUnit(e.target.value)}
        className="mt-1 p-2 focus:ring-2 focus:ring-green-600 focus:border-transparent block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out bg-gray-900 text-white h-10"
      >
        <option value="%">%</option>
        <option value="mg">mg</option>
        <option value="g">g</option>
      </select>
    </div>
    {/* Terpenes Field */}
    <div>
      <label htmlFor="terpenes" className="block text-sm font-medium text-gray-700">
        Terpenes
      </label>
      <input
        type="number"
        id="terpenes"
        value={terpenes}
        onChange={(e) => setTerpenes(e.target.value)}
        onWheel={preventScrollChange}
        className="mt-1 p-2 focus:ring-2 focus:ring-green-600 focus:border-transparent block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out bg-gray-900 text-white h-10"
      />
      <select
        id="terpenes_unit"
        value={terpenesUnit}
        onChange={(e) => setTerpenesUnit(e.target.value)}
        className="mt-1 p-2 focus:ring-2 focus:ring-green-600 focus:border-transparent block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out bg-gray-900 text-white h-10"
      >
        <option value="%">%</option>
        <option value="mg">mg</option>
        <option value="g">g</option>
      </select>
    </div>
    {/* THC Field */}
    <div>
      <label htmlFor="thc" className="block text-sm font-medium text-gray-700">
        THC
      </label>
      <input
        type="number"
        id="thc"
        value={thc}
        onChange={(e) => setThc(e.target.value)}
        onWheel={preventScrollChange}
        className="mt-1 p-2 focus:ring-2 focus:ring-green-600 focus:border-transparent block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out bg-gray-900 text-white h-10"
      />
      <select
        id="thc_unit"
        value={thcUnit}
        onChange={(e) => setThcUnit(e.target.value)}
        className="mt-1 p-2 focus:ring-2 focus:ring-green-600 focus:border-transparent block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out bg-gray-900 text-white h-10"
      >
        <option value="%">%</option>
        <option value="mg">mg</option>
        <option value="g">g</option>
      </select>
    </div>
  </div>
</div>
)}

{/* Barcode String Input Field */}
{ (containsTHC || containsCBD) && (
  <div className="mt-4">
    <label htmlFor="barcode-string" className="block text-sm font-medium text-gray-700">
      Barcode 
    </label>
    <input
      type="text"
      id="barcode-string"
      value={barcodeString}
      onChange={(e) => setBarcodeString(e.target.value)}
      className="mt-1 p-2 focus:ring-2 focus:ring-green-600 focus:border-transparent block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 transition duration-150 ease-in-out bg-gray-900 text-white h-10"
      placeholder="Enter barcode..."
    />
  </div>
)}


{/* Barcode Image Upload Section */}
{ (containsTHC || containsCBD) && (
  <div className="flex flex-col items-center justify-center">
    <label htmlFor="barcode-upload" className="block text-sm font-medium text-gray-700 mb-3">
      Upload Barcode
    </label>

    <div 
      className={`flex justify-center rounded-lg border ${
        isDragOver ? 'border-green-500' : 'border-dashed border-gray-300'
    } px-6 py-10 w-full`}
      onDragEnter={() => setIsDragOver(true)}
      onDragLeave={() => setIsDragOver(false)}
      onDragOver={(e) => { e.preventDefault(); setIsDragOver(true); }}
      onDrop={(e) => handleImageDrop(e, 'barcode')} 
    >
      <div className="text-center">
        <PhotoIcon className="mx-auto h-12 w-12 text-gray-500" aria-hidden="true" />
        <label htmlFor="barcode-upload" className="relative cursor-pointer rounded-md font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-green-500">
          <span>Drag and drop your barcodes here or click to upload</span>
          <input 
            type="file" 
            id="barcode-upload" 
            multiple 
            onChange={(e) => handleImageChange(e, 'barcode', setBarcode)} 
            className="sr-only" 
          />
        </label>
        <p className="text-xs leading-5 text-gray-400">PNG, JPG, GIF up to 10MB</p>
      </div>
    </div>
    {/* Display uploaded barcode previews */}
<div className="flex flex-wrap mt-4 gap-4">
  {barcodePreviews.map((barcodeUrl, index) => (
    <div key={index} className="relative m-2 border border-green-500 rounded-lg shadow-sm overflow-hidden">
      <img src={barcodeUrl} alt="Barcode" className="h-24 w-24 object-cover" />
      <button  
        onClick={() => handleRemoveImage(index, barcode, setBarcode, setBarcodePreviews)} 
        className="absolute top-1 right-1 bg-red-600 text-white rounded-full p-1 hover:bg-red-700 transition duration-200"
        aria-label="Remove barcode"
      >
        &times;
      </button>
    </div>
  ))}
</div>


  </div>
)}

{/* Test Results Image Upload Section */}
{ (containsTHC || containsCBD) && (
  <div className="flex flex-col items-center justify-center">
    <label htmlFor="test-results-upload" className="block text-sm font-medium text-gray-700 mb-3">
      Upload Test Results
    </label>

    <div 
      className={`flex justify-center rounded-lg border ${
        isDragOver ? 'border-green-500' : 'border-dashed border-gray-300'
    } px-6 py-10 w-full`}
    onDragEnter={() => setIsDragOver(true)}
    onDragLeave={() => setIsDragOver(false)}
    onDragOver={(e) => { e.preventDefault(); setIsDragOver(true); }}
      onDrop={(e) => handleImageDrop(e, 'testResults')} 
    >
      <div className="text-center">
        <PhotoIcon className="mx-auto h-12 w-12 text-gray-500" aria-hidden="true" />
        <label htmlFor="test-results-upload" className="relative cursor-pointer rounded-md font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-green-500">
          <span>Drag and drop test results here or click to upload</span>
          <input 
            type="file" 
            id="test-results-upload" 
            multiple 
            onChange={(e) => handleImageChange(e, 'testResults', setTestResults)} 
            className="sr-only" 
          />
        </label>
        <p className="text-xs leading-5 text-gray-400">PNG, JPG, GIF up to 10MB</p>
      </div>
    </div>
    {/* Display uploaded test result previews */}
<div className="flex flex-wrap mt-4 gap-4">
  {testResultPreviews.map((testResultUrl, index) => (
    <div key={index} className="relative m-2 border border-green-500 rounded-lg shadow-sm overflow-hidden">
      <img src={testResultUrl} alt="Test Result" className="h-24 w-24 object-cover" />
      <button 
        onClick={() => handleRemoveImage(index, testResults, setTestResults, setTestResultPreviews)} 
        className="absolute top-1 right-1 bg-red-600 text-white rounded-full p-1 hover:bg-red-700 transition duration-200"
        aria-label="Remove test result"
      >
        &times;
      </button>
    </div>
  ))}
</div>



  </div>
)}



{/* Is Edible Toggle */}
<div className="mt-4">
  <div className="flex items-center space-x-3">
    <Switch
      checked={isEdible}
      onChange={setIsEdible}
      className={classNames(
        isEdible ? 'bg-green-600' : 'bg-gray-200',
        'relative inline-flex flex-shrink-0 h-[24px] w-[48px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
      )}
    >
      <span className="sr-only">Is Edible</span>
      <span
        aria-hidden="true"
        className={classNames(
          isEdible ? 'translate-x-6' : 'translate-x-0',
          'pointer-events-none inline-block h-[20px] w-[20px] rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
        )}
      />
    </Switch>
    <span className="text-sm font-medium text-gray-700">Is Edible</span>
  </div>
</div>

{/* Nutrition Info Image Upload Section */}
{ (isEdible) && (
  <div className="flex flex-col items-center justify-center">
    <label htmlFor="nutrition-info-upload" className="block text-sm font-medium text-gray-700 mb-3">
      Upload Nutrition Info
    </label>

    <div 
      className={`flex justify-center rounded-lg border ${
        isDragOver ? 'border-green-500' : 'border-dashed border-gray-300'
    } px-6 py-10 w-full`}
      onDragEnter={() => setIsDragOver(true)}
      onDragLeave={() => setIsDragOver(false)}
      onDragOver={(e) => { e.preventDefault(); setIsDragOver(true); }}
      onDrop={(e) => handleImageDrop(e, 'nutritionInfoImage')} 
    >
      <div className="text-center">
        <PhotoIcon className="mx-auto h-12 w-12 text-gray-500" aria-hidden="true" />
        <label htmlFor="nutrition-info-upload" className="relative cursor-pointer rounded-md font-semibold text-gray-700 hover:text-green-500">
          <span>Drag and drop nutrition info here or click to upload</span>
          <input 
            type="file" 
            id="nutrition-info-upload" 
            multiple 
            onChange={(e) => handleImageChange(e, 'nutritionInfoImage', setNutritionInfoImage)} 
            className="sr-only" 
          />
        </label>
        <p className="text-xs leading-5 text-gray-400">PNG, JPG, GIF up to 10MB</p>
      </div>
    </div>
    {/* Display uploaded nutrition info previews */}
<div className="flex flex-wrap mt-4 gap-4">
  {nutritionInfoImagePreviews.map((nutritionInfoUrl, index) => (
    <div key={index} className="relative m-2 border border-green-500 rounded-lg shadow-sm overflow-hidden">
      <img src={nutritionInfoUrl} alt="Nutrition Info" className="h-24 w-24 object-cover" />
      <button 
        onClick={() => handleRemoveImage(index, nutritionInfoImage, setNutritionInfoImage, setNutritionInfoImagePreviews)} 
        className="absolute top-1 right-1 bg-red-600 text-white rounded-full p-1 hover:bg-red-700 transition duration-200"
        aria-label="Remove nutrition info"
      >
        &times;
      </button>
    </div>
  ))}
</div>



  </div>
)}


      {/* Submit Button */}
      <div className="flex justify-center">
        <button
          type="submit"
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Create Product
        </button>
      </div>
    </form>
  </div>
);
  };
export default CreateProduct;