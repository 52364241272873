import React, { useEffect, useState } from 'react';
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FlexBetween from "../components/FlexBetween";
import UserImage from "../components/UserImage";
import axios from 'axios';
import Cookie from "js-cookie";
import { server } from "../server";

const token = Cookie.get('token');

const Friend = ({ 
  friendId, 
  name, 
  subtitle, 
  avatar,
  friendType,
  friends   
}) => {
  const navigate = useNavigate();
  const [sellerHandle, setSellerHandle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
 

  useEffect(() => {
    
    if (friendType === 'Shop') {
      setIsLoading(true);
      
      axios.get(`${server}/shop/get-shop-info/${friendId}`)
        .then(res => {
          
          if (res.data && res.data.shop) {
            setSellerHandle(res.data.shop.handle);
          } else {
            console.error('Shop data is missing in the response');
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching shop info:', error);
          setIsLoading(false);
        });
    }
  }, [friendId, friendType]);
  
  

  const handleProfileClick = () => {
    if (isLoading) {
      // Optionally, handle the loading state (e.g., show a loading indicator or disable the button)
      return;
    }
    if (friendType === 'User') {
      navigate(`/profile/${friendId}`);
    } else if (sellerHandle) {
      navigate(`/shop/${sellerHandle}`);
    } else {
      console.error('Seller handle is not defined');
    }
  };
  
  return (
    <FlexBetween>
      <FlexBetween gap="1rem">
        <UserImage image={avatar} size="55px" />
        <Box onClick={handleProfileClick} sx={{ cursor: "pointer" }}>
          <Typography
            color="#1f9c29"
            variant="h5"
            fontWeight="500"
            sx={{
              "&:hover": {
                color: "#1f9c29",
              },
            }}
          >
            {name}
          </Typography>
          <Typography color="#1f9c29" fontSize="0.75rem">
            {subtitle}
          </Typography>
        </Box>
      </FlexBetween>
      {/* Optionally, display a loading indicator here */}
    </FlexBetween>
  );
};

export default Friend;




