// ShopBanner.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { server } from "../../server";
import Loader from "../Layout/Loader";
import { AiOutlineCamera } from "react-icons/ai";
import { loadSeller } from "../../redux/actions/user";
import { useDispatch} from "react-redux";
import { toast } from "react-toastify";

const ShopBanner = ({ isOwner }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { handle } = useParams();
  const [banner, setBanner] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${server}/shop/handle-to-id/${handle}`) // Resolve handle to ID
      .then(res => {
        const id = res.data.id;
        return axios.get(`${server}/shop/get-shop-info/${id}`); // Fetch shop info using ID
      })
      .then(res => {
        setData(res.data.shop);
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  }, [handle, dispatch]);

  const handleBannerChange = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setBanner(reader.result);
        axios.put(
            `${server}/shop/update-shop-banner`, // Assuming you have an endpoint for the banner as well
            { banner: reader.result },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            dispatch(loadSeller());
            toast.success("Banner updated successfully!");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    };
    reader.readAsDataURL(e.target.files[0])
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="w-full">
      <img
        src={`${data.banner?.url}`}
        alt="Shop Banner"
        className="w-full object-cover"
        style={{ height: '300px' }}
      />
      {isOwner && (
        <div className="w-[42px] h-[42px] bg-[#099910] rounded-full flex items-center justify-center cursor-pointer absolute right-[15px] -mt-[3rem]">
          <input type="file" id="banner" className="hidden" onChange={handleBannerChange} />
          <label htmlFor="banner" >
            <AiOutlineCamera />
          </label>
        </div>
      )}
    </div>
  );
}

export default ShopBanner;
