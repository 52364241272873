import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import ProductCard from "../Route/ProductCard/ProductCard";
import { server } from "../../server";
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, FunnelIcon } from '@heroicons/react/20/solid';
import { useSelector } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const IndividualShopProducts = ({ shopId }) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [priceFilter, setPriceFilter] = useState('');
  const [sortByNewest, setSortByNewest] = useState(false);
  const currentProfile = useSelector((state) => state.user.currentProfile);

  useEffect(() => {
    axios.get(`${server}/product/get-all-products-shop/${shopId}`, {withCredentials: true})
      .then((response) => {
        const filteredByCurrentProfile = response.data.products.filter(product => 
          currentProfile === 'User' ? product.productType.includes('retail') : product.productType.includes('wholesale'));
        setProducts(filteredByCurrentProfile);
        setFilteredProducts(filteredByCurrentProfile);
      })
      .catch((error) => console.error('Error fetching products:', error));
  }, [shopId, currentProfile]);

  useEffect(() => {
    applyFilters();
  }, [priceFilter, sortByNewest, products]);

  const applyFilters = () => {
    let filtered = [...products];

    // Price Filter
    if (priceFilter) {
      const [min, max] = priceFilter.split('-').map(Number);
      filtered = filtered.filter(product => {
        const price = product.discountPrice || product.originalPrice;
        return price >= min && (max ? price <= max : true);
      });
    }

    // Sort by Newest
    if (sortByNewest) {
      filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }

    setFilteredProducts(filtered);
  };

  return (
    <div>
      {/* Filters */}
      <Disclosure as="section" aria-labelledby="filter-heading">
        {({ open }) => (
          <>
            <div className="relative py-4">
              <div className="mx-auto flex max-w-7xl justify-end px-4 sm:px-6 lg:px-8">
                <Disclosure.Button className="group inline-flex items-center text-sm font-medium text-gray-700">
                  <FunnelIcon className="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Filters
                  <ChevronDownIcon className={`${open ? 'transform rotate-180' : ''} ml-2 h-5 w-5 text-gray-500`} />
                </Disclosure.Button>
              </div>
            </div>
            <Disclosure.Panel className="border-t border-gray-200 py-10">
              <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-4 px-4 text-sm sm:px-6 lg:px-8">
                <div className="space-y-4">
                  {/* Price Filter */}
                  <div>
                    <label htmlFor="price-filter" className="block text-sm font-medium text-gray-700">Price Range</label>
                    <select
                      id="price-filter"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      value={priceFilter}
                      onChange={e => setPriceFilter(e.target.value)}
                    >
                      <option value="">All Prices</option>
                      <option value="0-25">$0 - $25</option>
                      <option value="25-50">$25 - $50</option>
                      <option value="50-75">$50 - $75</option>
                      <option value="75-">$75+</option>
                    </select>
                  </div>
                  {/* Sort by Newest */}
                  <div>
                    <label htmlFor="newest-filter" className="block text-sm font-medium text-gray-700">Sort by Newest</label>
                    <input
                      id="newest-filter"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      checked={sortByNewest}
                      onChange={e => setSortByNewest(e.target.checked)}
                    />
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {/* Product Grid */}
      <div className="grid grid-cols-2 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-3 lg:gap-[25px] xl:grid-cols-3 xl:gap-[20px] mb-12 border-0">
        {filteredProducts.map((product, index) => (
          <ProductCard data={product} key={index} />
        ))}
      </div>
    </div>
  );
};

export default IndividualShopProducts;


