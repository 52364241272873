import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from '../../server'; 
import MyEventCard from './MyEventCard';

const MyEvents = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchMyEvents();
    }, []);

    const fetchMyEvents = async () => {
        try {
            const response = await axios.get(`${server}/event/events/my`, { withCredentials: true });
            setEvents(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch events');
            setLoading(false);
            console.error(err);
        }
    };

    const handleCancelRSVP = async (eventId) => {
        try {
            const response = await axios.delete(`${server}/event/rsvp/${eventId}`, { withCredentials: true });
            
            // Remove the event from the list of displayed events
            const updatedEvents = events.filter(event => event._id !== eventId);
            setEvents(updatedEvents);
        } catch (error) {
            console.error('Failed to cancel RSVP:', error);
            alert('Failed to cancel RSVP: ' + (error.response?.data?.message || 'Error occurred'));
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <h2>My Events</h2>
            {events.length > 0 ? (
                <div>
                    {events.map(event => (
                        <MyEventCard key={event._id} event={event} onCancelRSVP={handleCancelRSVP} />
                    ))}
                </div>
            ) : (
                <p>No events found.</p>
            )}
        </div>
    );
};

export default MyEvents;

