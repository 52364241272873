import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from '../server'; 
import Header from '../components/Layout/Header';
import Sidebar from '../components/Layout/Sidebar';
import AdvertWidget from '../widgets/AdvertWidget';
import UserBanner from '../components/Shop/UserBanner';
import FriendListTileWidget from '../widgets/FriendListTileWidget';
import AddFriendButton from '../components/AddFriendButton';
import { useParams} from 'react-router-dom';
import { useSelector } from "react-redux";
import UserProfileData from '../components/UserProfileData';
import SponsoredProducts from '../components/Sponsored/SponsoredProducts';
import FollowersAvatarDisplay from '../components/Followers/FollowersAvatarDisplay';

const PublicProfilePage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams(); // Extract the user ID from the URL
  const picturePath = user?.picturePath;
  const currentProfile = useSelector((state) => state.user.currentProfile);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${server}/user/user-info/${id}`);
        setUser(response.data.user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>User data not available</div>;
  }

  return (
    <div className="profile-page bg-black">
      <Header />
      <Sidebar />
      <UserBanner isUser={false}/>
      <div className="profile-header bg-green-700 h-24 sm:h-20 lg:h-28"></div>
      <div className="profile-main -mt-12 px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-16 lg:px-24">
        <div>
          <div className="-m-1 flex">
            <div className="inline-flex overflow-hidden border-4 border-white" style={{ borderRadius: '50%', width: '150px', height: '150px' }}>
              <img
                src={user.avatar?.url || 'default-avatar.png'}
                alt={user.name}
                style={{ borderRadius: '50%', width: '100%', height: '100%' }}
              />
            </div>
          </div>
        </div>
        <div className="mt-6 sm:ml-6 sm:flex-1">
          <div className="flex items-center">
            <h3 className="text-xl font-bold text-green-500 sm:text-2xl">{user.name}</h3>
            {/* Add online status or other icons here */}
          </div>
          <p className="text-sm text-green-500">{user.email}</p>
          
          <AddFriendButton friendId={id} friendType="User"/>
          <div className="p-4">
            <FollowersAvatarDisplay userId={id} profileType="User" />
          </div>
        </div>
      </div>
  
      {/* Adjusted layout for User Posts and Widgets */}
      <div className="flex flex-col w-full items-start gap-x-8 px-4 sm:px-6 lg:px-24 lg:flex-row">
  {/* Left column (FriendListTileWidget), full-width on mobile, 25% on larger screens */}
  <div className="order-2 lg:order-1 w-full lg:w-1/4 bg-[#f5f5f5] p-2 rounded-lg lg:sticky lg:top-24">
    <FriendListTileWidget userId={id} profileType="User" />
  </div>

  {/* Main content area, flex-grow on mobile, 50% width on larger screens */}
  <main className="order-1 lg:order-2 flex-grow lg:w-1/2 p-2">
    <UserProfileData />
  </main>

  {/* Right column (SponsoredProducts), full-width on mobile, 25% on larger screens */}
  <aside className="order-3 w-full lg:w-1/4 bg-[#f5f5f5] p-4 rounded-lg lg:sticky lg:top-24 lg:hidden xl:block">
    <SponsoredProducts />
  </aside>
</div>

</div>
  );
  }  

export default PublicProfilePage;




      


