import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mode: 'light',
  isFriendListPanelOpen: false,
  isNotificationSidebarOpen: false, 
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === 'light' ? 'dark' : 'light';
    },
    toggleFriendListPanel: (state) => {
      state.isFriendListPanelOpen = !state.isFriendListPanelOpen;
    },
    setFriendListPanelOpen: (state, action) => {
      state.isFriendListPanelOpen = action.payload;
    },
    toggleNotificationSidebar: (state) => { 
      state.isNotificationSidebarOpen = !state.isNotificationSidebarOpen;
    },
    setNotificationSidebarOpen: (state, action) => { 
      state.isNotificationSidebarOpen = action.payload;
    },
  },
});


export const { 
  setMode, 
  toggleFriendListPanel, 
  setFriendListPanelOpen, 
  toggleNotificationSidebar, 
  setNotificationSidebarOpen 
} = uiSlice.actions;


export default uiSlice.reducer;

