import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { PersonAddOutlined, PersonRemoveOutlined } from "@mui/icons-material";
import axios from 'axios';
import Cookie from "js-cookie";
import { fetchIsFriend } from "../redux/actions/friends";
import { server } from "../server";

const AddFriendButton = ({ friendId, friendType }) => {
    const token = Cookie.get('token');
    const dispatch = useDispatch();
    const currentProfile = useSelector((state) => state.user.currentProfile);
    const { user } = useSelector((state) => state.user);
    const { seller } = useSelector((state) => state.seller);
    const isFriend = useSelector(state => state.friends.isFriend);

    // Determine the ID and type of the current profile
    const currentProfileId = currentProfile === 'User' ? user?._id : seller?._id;
    const currentProfileType = currentProfile  === 'User' ? 'User' : 'Shop';

    console.log("Component render with currentProfileId:", currentProfileId);
    console.log("Current Profile Type:", currentProfileType, "Friend ID:", friendId, "Friend Type:", friendType);
    console.log("Is friend:", isFriend);

    useEffect(() => {
        if (currentProfileId && friendId) {
            console.log("Effect running to fetch friend status");
            dispatch(fetchIsFriend(currentProfileId, friendId, currentProfileType, friendType));
        }
    }, [dispatch, currentProfileId, friendId, currentProfileType, friendType]);

    const handleAddRemoveFriend = async () => {
        console.log("Attempting to update friend status for:", currentProfileId, "with:", friendId);
        try {
            await axios.put(
                `${server}/friends/add-remove-friend/${currentProfileId}/${friendId}/${currentProfileType}/${friendType}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            console.log("Friend status updated, re-fetching to confirm");
            // Toggle isFriend status optimistically, confirm with server fetch
            dispatch(fetchIsFriend(currentProfileId, friendId, currentProfileType, friendType)); // Re-fetch to confirm status
        } catch (error) {
            console.error("Error updating friend:", error);
            // Fetch current status to ensure UI consistency
            dispatch(fetchIsFriend(currentProfileId, friendId, currentProfileType, friendType));
        }
    };

    return (
        <Button
            startIcon={isFriend ? <PersonRemoveOutlined /> : <PersonAddOutlined />}
            onClick={handleAddRemoveFriend}
            sx={{
                backgroundColor: isFriend ? "#c0c0c0" : "#1f9c29",
                p: "0.6rem",
                color: "#fff",
                borderRadius: '8px',
                "&:hover": {
                    backgroundColor: isFriend ? "#a9a9a9" : "#197a21",
                },
            }}
        >
            {isFriend ? "Following" : "Follow"}
        </Button>
    );
};

export default AddFriendButton;
