import React, {  useState, Fragment, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import { categoriesData, productData } from "../../static/data";
import {
  AiOutlineHeart,
  AiOutlineSearch,
  AiOutlineShoppingCart,
  AiOutlineClose
} from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { BiMenuAltLeft } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import DropDown from "./DropDown";
import Navbar from "./Navbar";
import { useSelector } from "react-redux";
import Cart from "../cart/Cart";
import Wishlist from "../Wishlist/Wishlist";
import { RxCross1 } from "react-icons/rx";
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { server } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AvatarDropdown from "./AvatarDropdown";

const Header = ({ activeHeading }) => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { isSeller } = useSelector((state) => state.seller);
  const { wishlist } = useSelector((state) => state.wishlist);
  const profileType = sessionStorage.getItem('currentProfile') || 'User'; 
  const cartState = useSelector(state => state.cart.carts); 
  const cart = cartState[profileType] || [];
  const { allProducts } = useSelector((state) => state.products);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const searchBoxRef = useRef(null);
  const [active, setActive] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

    // Handler to open the Wishlist and ensure the Cart is closed
    const handleOpenWishlist = () => {
      setOpenCart(false); // Close Cart if it's open
      setOpenWishlist(true); // Open Wishlist
    };
  
    // Handler to open the Cart and ensure the Wishlist is closed
    const handleOpenCart = () => {
      setOpenWishlist(false); // Close Wishlist if it's open
      setOpenCart(true); // Open Cart
    };
  

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts =
      allProducts &&
      allProducts.filter((product) =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
    setSearchData(filteredProducts);
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  // Function to clear search term and search data
  const clearSearch = () => {
    setSearchTerm("");
    setSearchData(null);
  };

   // Function to handle focus on the search input
   const handleFocus = () => {
    if (searchTerm) {
      // Show the search results only if there is a search term
      setSearchData(
        allProducts.filter(product =>
          product.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  };

  // Logout Handler
  const logoutHandler = () => {
    axios.post(`${server}/user/logout`, {}, { 
      withCredentials: true 
    })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload(true);
        navigate("/login");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
        setSearchData(null); // Clear search data or hide the dropdown
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBoxRef]);


  return (
    <>
      <div className="mx-auto sticky px-4 py-1 top-0 z-50 bg-black">
        <div className="hidden 800px:h-[50px] 800px:my-[20px] 800px:flex items-center justify-between">
          <div>
            <Link to="/">
              <img
                src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1698860714/BuzzWeed-Logo-full-so-web2_kcwwjk.png"
                alt=""
              />
            </Link>
          </div>
          {/* categories */}
          <div onClick={() => setDropDown(!dropDown)}>
            <div className="relative h-[60px] mt-[10px] w-[270px] hidden 1000px:block">
              <BiMenuAltLeft size={30} className="absolute top-3 left-2" />
              <button style={{ 
      position: 'absolute', 
      bottom: 0, 
      backgroundColor: 'rgba(255, 255, 255, .2)' 
    }}
                className={`h-full w-full flex justify-between items-center pl-10 font-sans text-lg font-[500] select-none rounded-t-md`}
              >
                All Categories
              </button>
              <IoIosArrowDown
                size={20}
                className="absolute right-2 top-4 cursor-pointer"
                onClick={() => setDropDown(!dropDown)}
              />
              {dropDown ? (
                <DropDown
                  categoriesData={categoriesData}
                  setDropDown={setDropDown}
                />
              ) : null}
            </div>
          </div>

          {/* search box */}
          <div className="w-[42%] relative" ref={searchBoxRef}>
            <input style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
              type="text"
              placeholder="Search Products..."
              value={searchTerm}
              onChange={handleSearchChange}
              onFocus={handleFocus}
              className="h-[40px] w-full px-8 border-[#46d300] border-[2px] rounded-[2rem]"
            />
            <AiOutlineSearch
              size={20}
              className="absolute left-2 top-1.5 mt-1 cursor-pointer"
            />
            {searchTerm && (
          <AiOutlineClose
            size={18}
            className="absolute right-2 top-1.5 cursor-pointer"
            onClick={clearSearch}
          />
        )}
            {searchData && searchData.length !== 0 ? (
              <div className="absolute min-h-[30vh] search-drop shadow-sm-2 z-[9] p-4">
                {searchData &&
                  searchData.map((i, index) => {
                    return (
                      <Link to={`/product/${i._id}`}>
                        <div className="w-full flex items-start-py-3 hover:bg-green-500 rounded-lg">
                          <img
                            src={`${i.images[0]?.url}`}
                            alt=""
                            className="w-[50px] h-[50px] mr-[10px] rounded-md"
                          />
                         <h1 className="text-md font-semibold text-white">{i.name}</h1>
                         <div className="mt-1 flex items-center">
                          <span className="text-xs font-semibold mr-2 bg-blue-200 text-blue-800 px-2 py-1 rounded-full">
                          Brand:{i.brand}
                          </span>
                          <span className="text-xs font-semibold bg-green-200 text-green-800 px-2 py-1 rounded-full">
                          Shop:{i.shop.name}
                          </span>
                        </div>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            ) : null}
          </div>
          <div className="flex">
            <div className={`${styles.noramlFlex}`}>
              <div
                className="relative cursor-pointer mr-[15px]"
                onClick={handleOpenWishlist}
              >
                <AiOutlineHeart size={30} color="rgb(255 255 255 / 83%)" />
                <span className="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                  {wishlist && wishlist.length}
                </span>
              </div>
            </div>

            <div className={`${styles.noramlFlex}`}>
              <div
                className="relative cursor-pointer mr-[15px]"
                onClick={handleOpenCart}
              >
                <AiOutlineShoppingCart
                  size={30}
                  color="rgb(255 255 255 / 83%)"
                />
                <span className="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                  {cart && cart.length}
                </span>
              </div>
            </div>

            <div className={`${styles.noramlFlex}`}>
              <div className="relative cursor-pointer mr-[15px]">
                    {isAuthenticated ? (
        <>
        <div>
          <AvatarDropdown />
        </div>
        </>
      ) : (
        <>
        <Link to="/login">
                    <CgProfile size={30} color="rgb(255 255 255 / 83%)" />
                  </Link>
        </>
      )}
              </div>
            </div>

            {/* cart popup */}
            {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

            {/* wishlist popup */}
            {openWishlist ? (
              <Wishlist setOpenWishlist={setOpenWishlist} />
            ) : null}
          </div>
        </div>
      </div>

      {/* mobile header */}
      <div
        className={`${
          active === true ? "shadow-sm fixed top-0 left-0 z-50" : null
        }
      w-full h-[60px] bg-[#fff] z-50 top-0 left-0 shadow-sm 800px:hidden`}
      >
        <div className="w-full flex items-center justify-between">
          <div>
            <BiMenuAltLeft
              size={40}
              className="ml-4"
              onClick={() => setOpen(true)}
            />
          </div>
          <div>
            <Link to="/">
              <img
                src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1698860714/BuzzWeed-Logo-full-so-web2_kcwwjk.png"
                alt=""
                className="mt-3 cursor-pointer"
              />
            </Link>
          </div>
          <div>
            <div
              className="relative mr-[20px]"
              onClick={() => setOpenCart(true)}
            >
              <AiOutlineShoppingCart size={30} />
              <span class="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px]  leading-tight text-center">
                {cart && cart.length}
              </span>
            </div>
          </div>
          {/* cart popup */}
          {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

          {/* wishlist popup */}
          {openWishlist ? <Wishlist setOpenWishlist={setOpenWishlist} /> : null}
        </div>

        {/* header sidebar */}
        {open && (
          <div
            className={`fixed w-full bg-[#000000] z-20 h-full top-0 left-0`}
          >
            <div className="fixed w-[100%] h-screen top-0 left-0 z-10 overflow-y-scroll">
              <div className="w-full justify-between flex pr-3">
                <div>
                  <div
                    className="relative mr-[15px]"
                    onClick={() => setOpenWishlist(true) || setOpen(false)}
                  >
                    <AiOutlineHeart size={30} className="mt-5 ml-3" />
                    <span class="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px]  leading-tight text-center">
                      {wishlist && wishlist.length}
                    </span>
                  </div>
                </div>
                <RxCross1
                  size={30}
                  className="ml-4 mt-5"
                  onClick={() => setOpen(false)}
                />
              </div>

              {/* search box */}
          <div className="m-3  relative" ref={searchBoxRef}>
            <input style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
              type="text"
              placeholder="Search Products..."
              value={searchTerm}
              onChange={handleSearchChange}
              onFocus={handleFocus}
              className="h-[40px] w-full px-8 border-[#46d300] border-[2px] rounded-[2rem]"
            />
            <AiOutlineSearch
              size={20}
              className="absolute left-2 top-1.5 mt-1 cursor-pointer"
            />
            {searchTerm && (
          <AiOutlineClose
            size={18}
            className="absolute right-2 top-1.5 cursor-pointer"
            onClick={clearSearch}
          />
        )}
            {searchData && searchData.length !== 0 ? (
              <div className="absolute min-h-[30vh] bg-black search-drop shadow-sm-2 z-[9] p-4">
                {searchData &&
                  searchData.map((i, index) => {
                    return (
                      <Link to={`/product/${i._id}`}>
                        <div className="w-full flex items-start-py-3 hover:bg-green-500 rounded-lg">
                          <img
                            src={`${i.images[0]?.url}`}
                            alt=""
                            className="w-[50px] h-[50px] mr-[10px] rounded-md"
                          />
                         <h1 className="text-md font-semibold text-white">{i.name}</h1>
                         <div className="mt-1 flex items-center">
                          <span className="text-xs font-semibold mr-2 bg-blue-200 text-blue-800 px-2 py-1 rounded-full">
                          Brand:{i.brand}
                          </span>
                          <span className="text-xs font-semibold bg-green-200 text-green-800 px-2 py-1 rounded-full">
                          Shop:{i.shop.name}
                          </span>
                        </div>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            ) : null}
          </div>

              <Navbar active={activeHeading} />
              <div className={`${styles.button} ml-4 !rounded-[4px]`}>
                <Link to="/shop-create">
                  <h1 className="text-[#fff] flex items-center">
                    Become Seller <IoIosArrowForward className="ml-1" />
                  </h1>
                </Link>
              </div>
              <br />
              <br />
              <br />

              <div className="flex w-full justify-center">
      {isAuthenticated ? (
        <>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            {/* Avatar image as the dropdown button */}
            <Menu.Button>
              <img
                src={`${user.avatar?.url}`}
                alt="User Avatar"
                className="w-[60px] h-[60px] rounded-full border-[3px] border-[#ffffff] cursor-pointer"
              />
            </Menu.Button>
          </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="/profile"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        My Profile
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="/account-settings"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Account settings
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="/shop-create"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        BuzzWeed Biz
                      </a>
                    )}
                  </Menu.Item>
                  
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </>
      ) : (
        <>
          <Link
            to="/login"
            className="text-[18px] pr-[10px] text-[#000000b7]"
          >
            Login /
          </Link>
          <Link
            to="/sign-up"
            className="text-[18px] text-[#000000b7]"
          >
            Sign up
          </Link>
        </>
      )}
    </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
