import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PostWidget from "./PostWidget";
import { fetchPosts, deletePost as deletePostAction, editPost as editPostAction } from '../redux/actions/post';

const PostsWidgetRedux = ({ ownerId, profileType, isProfile = false }) => {
  const dispatch = useDispatch();
  // Using useSelector to get the state from Redux store
  const { posts, loading, error } = useSelector(state => state.posts);

  useEffect(() => {
    // Dispatch the fetchPosts action on component mount
    dispatch(fetchPosts(profileType, ownerId, isProfile));
  }, [dispatch, ownerId, profileType, isProfile,]);

  // Handle post deletion
  const handlePostDelete = (deletedPostId) => {
    // Dispatch deletePost action
    dispatch(deletePostAction(deletedPostId));
  };

  // Handle post edit
  const handlePostEdit = (updatedPost) => {
    // Dispatch editPost action
    dispatch(editPostAction(updatedPost));
  };

  // Optionally, you can handle loading and error states
  if (loading) return <div>Loading posts...</div>;
  if (error) return <div>Error fetching posts: {error}</div>;

  return (
    <>
      {posts && posts.map(post => (
        <PostWidget
          key={post._id}
          postId={post._id}
          owner={post.owner}
          postUserId={post.userId}
          profileType={post.profileType}
          name={post.name}
          description={post.description}
          location={post.location}
          mediaPath={post.mediaPath}
          avatar={post.avatar}
          likes={post.likes}
          comments={post.comments}
          createdAt={post.createdAt}
          onShareSuccess={() => {}} // Implement share functionality if needed
          resharedBy={post.resharedBy}
          reshareDescription={post.reshareDescription}
          reshareAvatar={post.reshareAvatar}
          onPostDelete={handlePostDelete}
          onPostEdit={handlePostEdit}
        />
      ))}
    </>
  );
};

export default PostsWidgetRedux;
