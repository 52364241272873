import React from 'react';
import { useSelector } from 'react-redux';

export default function TrendingWholesaleCarosel() {
  const { allProducts, isLoading } = useSelector((state) => state.products);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Filter to include only wholesale products
  const wholesaleProducts = allProducts.filter(product => product.productType.includes('wholesale'));

  return (
    <div className="bg-white">
      <div className="py-16 sm:py-24 lg:mx-auto lg:max-w-7xl lg:px-8">
        <div className="flex items-center justify-between px-4 sm:px-6 lg:px-0">
          <h2 className="text-2xl font-bold tracking-tight text-green-600">Trending Wholesale Products</h2>
          <a href="/wholesale-products" className="hidden text-sm font-semibold text-green-600 hover:text-green-500 sm:block">
            See more <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>

        <div className="relative mt-8">
          <div className="relative w-full overflow-x-auto">
            <ul
              role="list"
              className="mx-4 inline-flex space-x-8 sm:mx-6 lg:mx-0 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-x-0"
            >
              {wholesaleProducts.map((product) => (
                <li key={product._id} className="inline-flex w-64 flex-col text-center lg:w-auto">
                  <div className="group relative shadow-lg rounded-lg">
                    <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-black">
                      <a href={`/product/${product._id}`}>
                        <img
                          src={product.images[0]?.url}
                          alt={product.name}
                          className="h-full w-full object-cover object-center group-hover:opacity-75"
                        />
                        <div className="bg-black p-4">
                          <p className="text-sm text-gray-300">{product.category}</p>
                          <h3 className="mt-1 font-semibold text-white">
                            {product.name}
                          </h3>
                          <p className="mt-1 text-green">{product.discountPrice ? `$${product.discountPrice}` : 'Price not available'}</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-12 flex px-4 sm:hidden">
          <a href="/wholesale/products" className="text-sm font-semibold text-green-600 hover:text-green-500">
            See more <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
  );
}
