import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from '../../server';


const EditProduct = ({ productId }) => {
  const [productData, setProductData] = useState({
    name: '',
    description: '',
    category: '',
    tags: '',
    originalPrice: '',
    discountPrice: '',
    productType: '',
    stock: '',
    availableForPickup: false,
    availableForDelivery: false,
    handle: '',
    brand: '',
    sold_out: 0,
  });
  const [images, setImages] = useState([]);
  const [barcodeImage, setBarcodeImage] = useState(null);
  const [nutritionInfoImage, setNutritionInfoImage] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    axios.get(`${server}/product/get-product/${productId}`)
      .then(response => {
        setProductData(response.data.product);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching product data:', error);
        setLoading(false);
      });
  }, [productId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProductData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setProductData(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const handleProductTypeChange = (e) => {
    const value = e.target.value;
    setProductData(prevState => {
      const updatedProductType = prevState.productType.includes(value)
        ? prevState.productType.filter(type => type !== value)
        : [...prevState.productType, value];
      return { ...prevState, productType: updatedProductType };
    });
  };


  const handleImageChange = (event, field) => {
    if (field === 'images') {
        setImages([...event.target.files]);
    } else if (field === 'barcode') {
        setBarcodeImage(event.target.files[0]);
    } else if (field === 'NutritionInfo') {
        setNutritionInfoImage(event.target.files[0]);
    }
};


const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
  
    // Append all product data fields to formData
    for (const key in productData) {
      if (key !== 'reviews') {
        if (Array.isArray(productData[key])) {
          formData.append(key, JSON.stringify(productData[key]));
        } else {
          formData.append(key, productData[key]);
        }
      }
    }
  
    // Append images to formData
    images.forEach((image) => {
      formData.append('images', image);
    });

    // Append barcode and NutritionInfo images
    if (barcodeImage) formData.append('barcode', barcodeImage);
    if (nutritionInfoImage) formData.append('NutritionInfo', nutritionInfoImage);

    axios.put(`${server}/product/edit-product/${productId}`, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      console.log('Product updated:', response.data);
      // Handle successful update
    })
    .catch(error => {
      console.error('Error updating product:', error);
      // Handle errors
    });
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!productData) {
    return <div>Product not found</div>;
  }

  return (
    <form className="space-y-8" onSubmit={handleSubmit}>
      <div className="border-b border-gray-700 pb-8">
        <h2 className="text-lg font-semibold text-white">Edit Product</h2>
        <p className="mt-1 text-sm text-gray-400">Adjust your product details below.</p>

        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          {/* Product name input */}
          <div className="sm:col-span-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-300">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              value={productData.name}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md bg-gray-600 border-transparent py-2 px-3 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          {/* Product description input */}
          <div className="sm:col-span-6">
            <label htmlFor="description" className="block text-sm font-medium text-gray-300">Description</label>
            <textarea
              id="description"
              name="description"
              rows={3}
              value={productData.description}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md bg-gray-600 border-transparent py-2 px-3 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          {/* Category input */}
          <div className="sm:col-span-3">
            <label htmlFor="category" className="block text-sm font-medium text-gray-300">Category</label>
            <input
              type="text"
              name="category"
              id="category"
              value={productData.category}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md bg-gray-600 border-transparent py-2 px-3 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          {/* Tags input */}
          <div className="sm:col-span-3">
            <label htmlFor="tags" className="block text-sm font-medium text-gray-300">Tags</label>
            <input
              type="text"
              name="tags"
              id="tags"
              value={productData.tags}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md bg-gray-600 border-transparent py-2 px-3 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          {/* Original Price input */}
          <div className="sm:col-span-3">
            <label htmlFor="originalPrice" className="block text-sm font-medium text-gray-300">Original Price</label>
            <input
              type="number"
              name="originalPrice"
              id="originalPrice"
              value={productData.originalPrice}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md bg-gray-600 border-transparent py-2 px-3 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          {/* Discount Price input */}
          <div className="sm:col-span-3">
            <label htmlFor="discountPrice" className="block text-sm font-medium text-gray-300">Discount Price</label>
            <input
              type="number"
              name="discountPrice"
              id="discountPrice"
              value={productData.discountPrice}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md bg-gray-600 border-transparent py-2 px-3 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          {/* Product Type checkboxes */}
          <div className="sm:col-span-6">
            <span className="block text-sm font-medium text-gray-300">Product Type</span>
            <div className="mt-2 flex gap-4">
              <label className="flex items-center text-gray-300">
                <input
                  type="checkbox"
                  name="productType"
                  value="retail"
                  checked={productData.productType.includes('retail')}
                  onChange={handleProductTypeChange}
                  className="rounded bg-gray-600 text-indigo-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                <span className="ml-2">Retail</span>
              </label>
              <label className="flex items-center text-gray-300">
                <input
                  type="checkbox"
                  name="productType"
                  value="wholesale"
                  checked={productData.productType.includes('wholesale')}
                  onChange={handleProductTypeChange}
                  className="rounded bg-gray-600 text-indigo-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                <span className="ml-2">Wholesale</span>
              </label>
            </div>
          </div>

          {/* Stock input */}
          <div className="sm:col-span-3">
            <label htmlFor="stock" className="block text-sm font-medium text-gray-300">Stock</label>
            <input
              type="number"
              name="stock"
              id="stock"
              value={productData.stock}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md bg-gray-600 border-transparent py-2 px-3 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          {/* Available for Pickup checkbox */}
          <div className="sm:col-span-3">
            <label htmlFor="availableForPickup" className="flex items-center text-sm font-medium text-gray-300">
              <input
                type="checkbox"
                name="availableForPickup"
                id="availableForPickup"
                checked={productData.availableForPickup}
                onChange={handleCheckboxChange}
                className="rounded bg-gray-600 text-indigo-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              <span className="ml-2">Available for Pickup</span>
            </label>
          </div>

          {/* Available for Delivery checkbox */}
          <div className="sm:col-span-3">
            <label htmlFor="availableForDelivery" className="flex items-center text-sm font-medium text-gray-300">
              <input
                type="checkbox"
                name="availableForDelivery"
                id="availableForDelivery"
                checked={productData.availableForDelivery}
                onChange={handleCheckboxChange}
                className="rounded bg-gray-600 text-indigo-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              <span className="ml-2">Available for Delivery</span>
            </label>
          </div>

          {/* Handle input */}
          <div className="sm:col-span-4">
            <label htmlFor="handle" className="block text-sm font-medium text-gray-300">Handle</label>
            <input
              type="text"
              name="handle"
              id="handle"
              value={productData.handle}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md bg-gray-600 border-transparent py-2 px-3 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          {/* Brand input */}
          <div className="sm:col-span-4">
            <label htmlFor="brand" className="block text-sm font-medium text-gray-300">Brand</label>
            <input
              type="text"
              name="brand"
              id="brand"
              value={productData.brand}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md bg-gray-600 border-transparent py-2 px-3 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          {/* Barcode Image input */}
          <div className="sm:col-span-6">
            <label htmlFor="barcode" className="block text-sm font-medium text-gray-300">Barcode Image</label>
            <input
              type="file"
              name="barcode"
              id="barcode"
              onChange={(e) => handleImageChange(e, 'barcode')}
              className="mt-1 block w-full text-sm text-gray-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-gray-700 file:text-white
              hover:file:bg-gray-600"
            />
          </div>

          {/* Nutrition Info Image input */}
          <div className="sm:col-span-6">
            <label htmlFor="nutritionInfo" className="block text-sm font-medium text-gray-300">Nutrition Info Image</label>
            <input
              type="file"
              name="nutritionInfo"
              id="nutritionInfo"
              onChange={(e) => handleImageChange(e, 'NutritionInfo')}
              className="mt-1 block w-full text-sm text-gray-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-gray-700 file:text-white
              hover:file:bg-gray-600"
            />
          </div>

          {/* Submit button */}
          <div className="col-span-full pt-8">
            <button
              type="submit"
              className="float-right inline-flex justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Update Product
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
export default EditProduct;