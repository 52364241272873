import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { server } from "../../server";

const SponsoredShops = () => {
    const [sponsoredShops, setSponsoredShops] = useState([]);

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`);
            console.log('Click logged for ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const logImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/impression/${adId}`, {}, { withCredentials: true });
            console.log('Impression logged for ad:', adId);
        } catch (error) {
            console.error('Error logging impression:', error);
        }
    };

    useEffect(() => {
        const fetchSponsoredShops = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-shops`, { withCredentials: true }); // Adjust the endpoint as necessary
                // Filter out inactive ads before setting the state
                const activeSponsoredShops = response.data.filter(ad => ad.isActive === true);
                setSponsoredShops(activeSponsoredShops); // Set state with only active ads
            } catch (error) {
                console.error('Error fetching sponsored shops:', error);
            }
        };
        fetchSponsoredShops();
    }, []);
    

    useEffect(() => {
        sponsoredShops.forEach(({ _id: adId }) => {
            logImpression(adId); 
        });
    }, [sponsoredShops]);

    return (
        <div style={{ padding: '20px', backgroundColor: '#333', borderRadius: '10px', margin: '10px auto', maxWidth: '1000px' }}>
            <h2 style={{ textAlign: 'center', marginBottom: '20px', color: 'white' }}>Sponsored Shops</h2>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {sponsoredShops.map(({ _id: adId, shop }) => (
                    <div key={adId} style={{ width: '100%', textAlign: 'center', margin: '10px 0', border: '1px solid #ddd', borderRadius: '10px', overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Link to={`/shop/${shop.handle}`} onClick={() => logAdClick(adId)} style={{ textDecoration: 'none', color: 'inherit', display: 'block', width: '100%' }}>
                            {shop.avatar && (
                                <img src={shop.avatar.url} alt={shop.name} style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%', marginTop: '10px' }} />
                            )}
                            {shop.banner && (
                                <img src={shop.banner.url} alt={shop.name} style={{ width: '100%', height: '200px', objectFit: 'cover' }} />
                            )}
                            <div style={{ backgroundColor: 'rgba(84, 82, 82, 0.5)', color: 'white', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h3 style={{ fontSize: '16px', margin: '0' }}>{shop.name}</h3>
                                <button style={{ padding: '5px 15px', borderRadius: '5px', background: '#1f9c29', color: 'white', border: 'none', cursor: 'pointer', textDecoration: 'none' }}>Visit Store</button>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};


export default SponsoredShops;
