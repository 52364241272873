import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { deleteProduct } from "../../redux/actions/product";
import Loader from "../Layout/Loader";
import axios from "axios";
import { server } from "../../server";
import { useState } from "react";
import { toast } from "react-toastify";
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format, parseISO } from 'date-fns';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale);

const AllProducts = () => {
  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Number of Products',
        data: [],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  });
  const [productsAddedData, setProductsAddedData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Products Added',
        data: [],
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  });

  useEffect(() => {
    axios.get(`${server}/product/admin-all-products`, { withCredentials: true })
      .then((res) => {
        
        setData(res.data.products);
        processCategoryData(res.data.products);
        processProductsAddedData(res.data.products);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        toast.error("Failed to fetch products.");
      });
  }, []);

  const processCategoryData = (products) => {
    const categoryCounts = products.reduce((acc, product) => {
      acc[product.category] = (acc[product.category] || 0) + 1;
      return acc;
    }, {});

    setCategoryData({
      labels: Object.keys(categoryCounts),
      datasets: [
        {
          label: 'Number of Products',
          data: Object.values(categoryCounts),
          backgroundColor: Object.keys(categoryCounts).map(() => 'rgba(53, 162, 235, 0.5)'),
        },
      ],
    });
    
  };

const processProductsAddedData = (products) => {
  // Assume a daily timeframe for simplicity; adjust as needed for your UI controls
  const timeframe = 'daily'; // This could be dynamic based on user selection

  const sortedProducts = products.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  const chartLabels = [];
  const productsAddedCounts = {};
  
  sortedProducts.forEach(product => {
    let label;
    const date = parseISO(product.createdAt);

    if (timeframe === 'daily') {
      label = format(date, 'MM/dd/yyyy');
    } else { // If not daily, then monthly
      label = format(date, 'MM/yyyy');
    }

    if (!productsAddedCounts[label]) {
      productsAddedCounts[label] = 0;
    }
    productsAddedCounts[label]++;
  });

  setProductsAddedData({
    labels: Object.keys(productsAddedCounts),
    datasets: [{
      label: 'Products Added',
      data: Object.values(productsAddedCounts),
      borderColor: 'rgba(255, 99, 132, 1)',
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
    }],
  });
};


  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await axios.delete(`${server}/product/admin-delete/${id}`, { withCredentials: true });
        toast.success('Product deleted successfully');
        // Refresh the product list after deletion
        setData(data.filter((product) => product._id !== id));
      } catch (error) {
        toast.error('Failed to delete product');
        console.error('Error deleting product:', error);
      }
    }
  };

  const columns = [
    {
      field: "image",
      headerName: "Image",
      width: 130,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <img
          src={params.row.image} // Assuming 'image' field contains the URL
          alt={params.row.name}
          style={{ width: '125px', height: 'auto', borderRadius: '8px' }}
        />
      ),
    },
    { field: "id", headerName: "Product Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "productType",
      headerName: "Product Type",
      minWidth: 130,
      flex: 0.8
    },
    {
      field: "shopName",
      headerName: "Shop Name",
      minWidth: 150,
      flex: 0.8,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "Stock",
      headerName: "Stock",
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },

    { 
      field: "SKU", 
      headerName: "SKU", 
      minWidth: 120, 
      flex: 0.5, 
      headerAlign: 'center', 
      align: 'center',
    },
    { 
      field: "category", 
      headerName: "Category", 
      minWidth: 150, 
      flex: 0.6, 
      headerAlign: 'center', 
      align: 'center',
    },

    {
      field: "sold",
      headerName: "Sold out",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/product/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => (
        <Button
          onClick={() => handleDelete(params.id)}
          color="error"
        >
          <AiOutlineDelete />
        </Button>
      ),
    },
  ];

  const rows = [];

  data &&
  data.forEach((item) => {
      rows.push({
        id: item._id,
        name: item.name,
        productType: Array.isArray(item.productType) ? item.productType.join(', ') : item.productType,
        price: "US$ " + item.discountPrice,
        Stock: item.stock,
        sold: item?.sold_out,
        image: item?.images[0]?.url || 'defaultProductImageUrl',
        SKU: item?.SKU,
        category: item?.category,
        shopName: item?.handle,
      });
    });

    return (
      <>
        <div style={{ backgroundColor: '#333333', padding: '6px', marginTop: '20px', minHeight: '100vh', borderRadius: '6px' }}>
          <h1 style={{ fontSize: '24px', fontWeight: 'bold', color: '#F7FAFC', marginBottom: '20px' }}>All Products</h1>

          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '40px', flexWrap: 'wrap' }}>
          <div style={{ flex: 1, minWidth: '300px', height: '420px' }}>
          <Bar
            data={categoryData}
            options={{
              maintainAspectRatio: false, 
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: 'Number of Products by Category',
                },
              },
            }}
          />
        </div>

        {/* Line Chart */}
        <div style={{ flex: 1, minWidth: '300px', height: '420px' }}>
          <Line
            data={productsAddedData}
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: 'Products Added Over Time',
                },
              },
              scales: {
                x: {
                  type: 'time',
                  time: {
                    unit: 'day',
                  },
                },
              },
            }}
          />
        </div>
        </div>
          <div style={{ width: '100%' }}>
            <DataGrid
              rows={rows}
              rowHeight={120} 
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              autoHeight
              sx={{
                boxShadow: 'none',
                border: '1px solid #2D3748',
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#146e10',
                  color: '#F7FAFC',
                },
                '& .MuiDataGrid-cell': {
                  borderBottom: '1px solid #2D3748',
                  color: '#F7FAFC',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
              }}
            />
          </div>
        </div>
      </>
    );
  };

export default AllProducts;
