import React, { useState } from 'react';
import axios from 'axios';
import { server } from "../../server";
import { useSelector } from "react-redux";

const EventCreationForm = () => {
    const currentProfile = useSelector((state) => state.user.currentProfile);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        image: null,
        date: '',
        endDate: '',
        location: ''
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "image") {
            setFormData(prev => ({ ...prev, image: files[0] }));
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        for (const key in formData) {
            if (formData[key] != null) { // Include all fields, file or otherwise
                data.append(key, formData[key]);
            }
        }
        data.append('currentProfile', currentProfile); // Use Redux state directly

        try {
            const response = await axios.post(`${server}/event/create`, data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            alert('Event created successfully!');
        } catch (error) {
            console.error('Error creating event:', error);
            alert(`Failed to create event: ${error.response?.data?.message || 'Unknown error'}`);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6 bg-gray-800 p-6 rounded-md shadow-lg">
            <div>
                <label htmlFor="title" className="block text-sm font-medium leading-5 text-gray-300">Event Title</label>
                <input type="text" name="title" id="title" value={formData.title} onChange={handleChange} placeholder="Event Title" required
                    className="mt-1 block w-full rounded-md bg-gray-700 border-transparent focus:border-gray-500 focus:bg-gray-600 focus:ring-0" />
            </div>

            <div>
                <label htmlFor="description" className="block text-sm font-medium leading-5 text-gray-300">Event Description</label>
                <textarea name="description" id="description" value={formData.description} onChange={handleChange} placeholder="Event Description" required
                    className="mt-1 block w-full rounded-md bg-gray-700 border-transparent focus:border-gray-500 focus:bg-gray-600 focus:ring-0" />
            </div>

            <div>
                <label htmlFor="image" className="block text-sm font-medium leading-5 text-gray-300">Event Image</label>
                <input type="file" name="image" id="image" onChange={handleChange} required
                    className="mt-1 block w-full text-sm text-gray-300 file:mr-4 file:py-2 file:px-4
                    file:rounded-md file:border-0 file:text-sm file:font-semibold
                    file:bg-gray-700 file:text-gray-300 hover:file:bg-gray-600" />
            </div>

            <div>
                <label htmlFor="location" className="block text-sm font-medium leading-5 text-gray-300">Event Location</label>
                <input type="text" name="location" id="location" value={formData.location} onChange={handleChange} placeholder="Event Location" required
                    className="mt-1 block w-full rounded-md bg-gray-700 border-transparent focus:border-gray-500 focus:bg-gray-600 focus:ring-0" />
            </div>

            <div>
                <label htmlFor="date" className="block text-sm font-medium leading-5 text-gray-300">Event Date and Time</label>
                <input type="datetime-local" name="date" id="date" value={formData.date} onChange={handleChange} required
                    className="mt-1 block w-full rounded-md bg-gray-700 border-transparent focus:border-gray-500 focus:bg-gray-600 focus:ring-0" />
            </div>
            <div>
                <label htmlFor="endDate" className="block text-sm font-medium leading-5 text-gray-300">Event End Date and Time</label>
                <input type="datetime-local" name="endDate" id="endDate" value={formData.endDate} onChange={handleChange}
                    className="mt-1 block w-full rounded-md bg-gray-700 border-transparent focus:border-gray-500 focus:bg-gray-600 focus:ring-0" />
            </div>


            <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Create Event
            </button>
        </form>
    );
};

export default EventCreationForm;

