import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from "../../server";
import { toast } from 'react-toastify';

const ChangeSubscriptionModal = ({ currentSubscriptionId }) => {
    const [showModal, setShowModal] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [currentSubscription, setCurrentSubscription] = useState(null);

    useEffect(() => {
        // Fetch current subscription details
        const fetchCurrentSubscription = async () => {
            try {
                const response = await axios.get(`${server}/subscriptions/subscriptions/${currentSubscriptionId}`, {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                    }
                });
                setCurrentSubscription(response.data);
            } catch (error) {
                console.error('Error fetching current subscription:', error);
            }
        };
 // Fetch all available subscription plans
        const fetchSubscriptions = async () => {
            try {
                const { data } = await axios.get(`${server}/subscriptions/subscriptions/details`, {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                    }
                });
                
                setSubscriptions(data);
            } catch (error) {
                console.error('Error fetching subscription plans:', error);
            }
        };

        fetchCurrentSubscription();
        fetchSubscriptions();
    }, [currentSubscriptionId]);

    const handleSubscriptionChange = async (newPriceId) => {
        if (newPriceId === currentSubscription.priceId) {
            alert("You're already on this plan.");
            return;
        }
        try {
            const response = await axios.post(`${server}/subscriptions/change-tier`, {
                subscriptionId: currentSubscriptionId,
                newPriceId
            }, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            alert('Subscription updated successfully!');
            setCurrentSubscription(response.data.newSubscription);
            setShowModal(false);
        } catch (error) {
            console.error('Error changing subscription:', error);
            alert('Failed to update subscription.');
        }
    };

    return (
        <div>
            <button className="upgrade-plan-btn" onClick={() => setShowModal(true)}>Upgrade Plan</button>
            {showModal && (
                <div className="change-subscription-modal">
                    <div className="change-subscription-modal-content">
                        <span className="change-subscription-close-btn" onClick={() => setShowModal(false)}>&times;</span>
                        <h2>Choose your new plan</h2>
                        <ul>
    {subscriptions.map(sub => (
        <React.Fragment key={sub.name}> 
            <li
                className={`change-subscription-plan ${sub.monthlyPriceId === currentSubscription?.priceId ? 'change-subscription-plan-current' : ''}`}
                onClick={() => handleSubscriptionChange(sub.monthlyPriceId)}
            >
                {sub.name} - ${sub.monthlyUnitAmount / 100} per month
            </li>
            <li
                className={`change-subscription-plan ${sub.annualPriceId === currentSubscription?.priceId ? 'change-subscription-plan-current' : ''}`}
                onClick={() => handleSubscriptionChange(sub.annualPriceId)}
            >
                {sub.name} - ${sub.annualUnitAmount / 100} per year
            </li>
        </React.Fragment>
    ))}
</ul>

                    </div>
                </div>
            )}
        </div>
    );
};
       

export default ChangeSubscriptionModal;
