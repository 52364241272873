import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { server } from "../../server";
import Loader from "../Layout/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SponsoredBrands = () => {
    const [sponsoredBrands, setSponsoredBrands] = useState([]);

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`);
            console.log('Click logged for brand ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
            toast.error('Failed to log click for brand ad.');
        }
    };

    const logImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/impression/${adId}`, {}, { withCredentials: true });
            console.log('Impression logged for brand ad:', adId);
        } catch (error) {
            console.error('Error logging impression:', error);
            toast.error('Failed to log impression for brand ad.');
        }
    };

    useEffect(() => {
        const fetchSponsoredBrands = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-brands`, { withCredentials: true });
                const activeSponsoredBrands = response.data.filter(ad => ad.isActive);
                setSponsoredBrands(activeSponsoredBrands);
            } catch (error) {
                console.error('Error fetching sponsored brands:', error);
                toast.error('Failed to fetch sponsored brands.');
            }
        };
        fetchSponsoredBrands();
    }, []);
    

    useEffect(() => {
        sponsoredBrands.forEach(({ _id: adId }) => {
            logImpression(adId);
        });
    }, [sponsoredBrands]);

    return (
        <div style={{ padding: '20px', backgroundColor: '#333', borderRadius: '10px', margin: '10px auto', maxWidth: '1000px' }}>
            <ToastContainer />
            <h2 style={{ textAlign: 'center', marginBottom: '20px', color: 'white' }}>Sponsored Brands</h2>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {sponsoredBrands.map(({ _id: adId, brand }) => (
                    <div key={adId} style={{ width: '100%', textAlign: 'center', margin: '10px 0', border: '1px solid #ddd', borderRadius: '10px', overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Link to={`/brand/${brand.name}`} onClick={() => logAdClick(adId)} style={{ textDecoration: 'none', color: 'inherit', display: 'block', width: '100%' }}>
                            {brand.avatarImage && (
                                <img src={brand.avatarImage.url} alt={brand.name} style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%', marginTop: '10px' }} />
                            )}
                            {brand.coverImage && (
                                <img src={brand.coverImage.url} alt={brand.name} style={{ width: '100%', height: '200px', objectFit: 'cover' }} />
                            )}
                            <div style={{ backgroundColor: 'rgba(84, 82, 82, 0.5)', color: 'white', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h3 style={{ fontSize: '16px', margin: '0' }}>{brand.name}</h3>
                                <button style={{ padding: '5px 15px', borderRadius: '5px', background: '#1f9c29', color: 'white', border: 'none', cursor: 'pointer', textDecoration: 'none' }}>Visit Brand</button>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SponsoredBrands;
