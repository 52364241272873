import React, { useState } from "react";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProfileSideBar from "../components/Profile/ProfileSidebar";
import ProfileContent from "../components/Profile/ProfileContent";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import MyPostWidgetRedux from "../widgets/MyPostWidgetRedux";
import PostsWidgetRedux from "../widgets/PostsWidgetRedux";
import FriendListWidget from "../widgets/FriendListWidget";

const ProfilePage = () => {
  const [active, setActive] = useState(1);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { loading, user } = useSelector((state) => state.user);
  const picturePath = user?.picturePath;
  const _id = user?._id;

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <div className="mx-auto flex w-full items-start gap-x-8 px-4 py-10 sm:px-6 lg:px-8">
            <aside className="w-[50px] 800px:w-[335px] sticky 800px:mt-0 mt-[18%]">
              <ProfileSideBar active={active} setActive={setActive} />
            </aside>

            <main className="flex-1">{/* Main content area */}
            <div className="mt-8 p-4 bg-white shadow rounded-lg">
              <ProfileContent active={active} />
              </div>
            </main>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfilePage;

