import React, { useState } from 'react';
import axios from 'axios';
import { server } from "../../server";
import { useSelector } from 'react-redux';
import { AiOutlineCamera } from "react-icons/ai";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { categoriesData } from "../../static/data";

const BrandCatalogItemCreate = ({ brandId }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    category: '',
    SKU: '',
    tags: '',
    productType: '',
    unitOfMeasurement: '',
    priceFloor: '',
    containsTHC: false,
    containsCBD: false,
    isEdible: false,
    brand: brandId, 
  });
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const { seller } = useSelector(state => state.seller); 
  const [nutritionInfoImages, setNutritionInfoImages] = useState([]);
  const [testResultsImages, setTestResultsImages] = useState([]);

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleProductTypeChange = (e) => {
    const { value, checked } = e.target;
    const newProductTypes = checked
      ? [...formData.productType, value] // Add to array if checked
      : formData.productType.filter((type) => type !== value); // Remove from array if unchecked

    setFormData({ ...formData, productType: newProductTypes });
  };


  const handleImageChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleNutritionImagesChange = (e) => {
    // Assuming you want to allow multiple nutrition images
    setFormData({ ...formData, nutritionInfoImage: [...e.target.files] });
  };
  
  const handleTestResultsImagesChange = (e) => {
    // Assuming you want to allow multiple test results images
    setFormData({ ...formData, testResults: [...e.target.files] });
  };
  
  const handleVideosChange = (e) => {
    // Adjusting to setFormData, assuming you want to allow multiple videos
    setFormData({ ...formData, videos: [...e.target.files] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    Object.keys(formData).forEach(key => data.append(key, formData[key]));
    images.forEach(image => data.append('images', image));
    videos.forEach(video => data.append('videos', video));
    nutritionInfoImages.forEach(image => data.append('nutritionInfoImages', image));
    testResultsImages.forEach(image => data.append('testResultsImages', image));

    try {
      const response = await axios.post(`${server}/brand/create-brand-catalog-item`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'current-profile': seller.name || 'defaultProfile',
          // Include authorization headers as needed
        }, withCredentials: true,
      });
      
      toast.success("Brand catalog item created successfully");
      setFormData({
        name: '',
        description: '',
        category: '',
        SKU: '',
        tags: '',
        productType: '',
        unitOfMeasurement: '',
        priceFloor: '',
        containsTHC: false,
        containsCBD: false,
        isEdible: false,
        brand: brandId,
      });
      setImages([]);
      setVideos([]);
      setNutritionInfoImages([]);
      setTestResultsImages([]);
    } catch (error) {
      console.error(error);
      toast.error("Error creating catalog item");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8 border p-2 rounded-md">
      <div className="space-y-4">
        <h2 className="text-lg font-semibold text-gray-900">Create Brand Catalog Item</h2>
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">Product Name</label>
          <input
            id="name"
            name="name"
            type="text"
            value={formData.name}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md bg-gray-700 p-2 text-black border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
            placeholder="Product Name"
          />
        </div>
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            id="description"
            name="description"
            rows={4}
            value={formData.description}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md bg-gray-700 p-2 text-black border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="Product Description"
          ></textarea>
        </div>
        {/* Repeat for other fields as needed */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Product Images</label>
          <div className="mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md p-4">
            <div className="space-y-1 text-center">
              <AiOutlineCamera className="mx-auto h-12 w-12 text-gray-400" />
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-gray-50 px-3 py-2 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-100 focus-within:outline-none"
                >
                  <span>Upload a file</span>
                  <input id="file-upload" name="file-upload" type="file" multiple onChange={handleImageChange} className="sr-only" />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
            </div>
          </div>
        </div>
        <div>
            <label className="block text-sm font-medium text-gray-700">Nutrition Info Images</label>
            <div className="mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md p-4">
                <div className="space-y-1 text-center">
                <AiOutlineCamera className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                    <label
                    htmlFor="nutritionInfoImage"
                    className="relative cursor-pointer rounded-md bg-gray-50 px-3 py-2 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-100 focus-within:outline-none"
                    >
                    <span>Upload a file</span>
                    <input 
                        id="nutritionInfoImage" 
                        name="nutritionInfoImage" 
                        type="file" 
                        multiple 
                        onChange={handleNutritionImagesChange} 
                        className="sr-only" 
                    />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                </div>
            </div>
            </div>

            <div>
            <label className="block text-sm font-medium text-gray-700">Test Results Images</label>
            <div className="mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md p-4">
                <div className="space-y-1 text-center">
                <AiOutlineCamera className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                    <label
                    htmlFor="testResults"
                    className="relative cursor-pointer rounded-md bg-gray-50 px-3 py-2 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-100 focus-within:outline-none"
                    >
                    <span>Upload a file</span>
                    <input 
                        id="testResults" 
                        name="testResults" 
                        type="file" 
                        multiple 
                        onChange={handleTestResultsImagesChange} 
                        className="sr-only" 
                    />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                </div>
            </div>
            </div>

            <div>
            <label className="block text-sm font-medium text-gray-700">Videos</label>
            <div className="mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md p-4">
                <div className="space-y-1 text-center">
                <AiOutlineCamera className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                    <label
                    htmlFor="videos"
                    className="relative cursor-pointer rounded-md bg-gray-50 px-3 py-2 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-100 focus-within:outline-none"
                    >
                    <span>Upload a file</span>
                    <input 
                        id="videos" 
                        name="videos" 
                        type="file" 
                        multiple 
                        onChange={handleVideosChange} 
                        className="sr-only" 
                    />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">MP4, MOV up to 100MB</p>
                </div>
            </div>
            </div>

        
      </div>

      <div>
  <label htmlFor="category" className="block text-sm font-medium text-gray-700">Category</label>
  <select
    id="category"
    name="category"
    value={formData.category}
    onChange={handleChange}
    className="mt-1 block w-full rounded-md bg-gray-700 p-2 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
    required // Ensures a value is selected
  >
    <option value="" disabled>Select a category</option>
    {categoriesData.map((cat, index) => (
      <option key={index} value={cat.title}>{cat.title}</option>
    ))}
  </select>
</div>

        
<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div >
        <label htmlFor="priceFloor" className="block text-sm font-medium text-gray-700">Price Floor</label>
        <input
          id="priceFloor"
          name="priceFloor"
          type="number"
          min="0" 
          step="0.01" 
          value={formData.priceFloor}
          onChange={handleChange}
          className="mt-1 block w-full bg-gray-700 p-2 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
          placeholder="Minimum selling price"
          required 
        />
      </div>

        <div>
          <label htmlFor="SKU" className="block text-sm font-medium text-gray-700">SKU</label>
          <input
            id="SKU"
            name="SKU"
            type="text"
            value={formData.SKU}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md bg-gray-700 p-2 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
            placeholder="SKU"
          />
        </div>
        </div>

        <div>
          <label htmlFor="tags" className="block text-sm font-medium text-gray-700">Tags</label>
          <input
            id="tags"
            name="tags"
            type="text"
            value={formData.tags}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md bg-gray-700 p-2 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
            placeholder="Tags, Separated, By, Commas"
          />
        </div>

        {/* Product Type */}
      <div className="mt-10">
        <label className="block text-sm font-medium text-gray-700">
          Product Type <span className="text-green-500">*</span>
        </label>
        <div className="mt-2">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="productType"
              value="retail"
              checked={formData.productType.includes("retail")}
              onChange={handleProductTypeChange}
              className="form-checkbox h-5 w-5 text-green-600"
            />
            <span className="ml-2 text-gray-700">Retail</span>
          </label>
          <label className="inline-flex items-center ml-6">
            <input
              type="checkbox"
              name="productType"
              value="wholesale"
              checked={formData.productType.includes("wholesale")}
              onChange={handleProductTypeChange}
              className="form-checkbox h-5 w-5 text-green-600"
            />
            <span className="ml-2 text-gray-700">Wholesale</span>
          </label>
        </div>
      </div>

        <div>
          <label htmlFor="unitOfMeasurement" className="block text-sm font-medium bg-gray-700 p-2 text-gray-700">Unit of Measurement</label>
          <select 
  name="unitOfMeasurement" 
  onChange={handleChange}
  value={formData.unitOfMeasurement}
  required // Ensures a value is selected
>
  <option value="" disabled>Select unit</option>
  <option value="g">Grams (g)</option>
  <option value="lbs">Pounds (lbs)</option>
  <option value="kg">Kilograms (kg)</option>
</select>

        </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Create
          </button>
        </div>
      </div>
    </form>
  );
};

export default BrandCatalogItemCreate;
