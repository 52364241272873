import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { server } from "../../server";

const BannerAd = () => {
    const [ad, setAd] = useState(null);

    useEffect(() => {
        const fetchAd = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-products`, { withCredentials: true });
                
                const activeAds = Array.isArray(response.data) ? response.data.filter(ad => ad.isActive && ad.product && ad.product.images && ad.product.images.length > 0) : [];
                if (activeAds.length > 0) {
                    setAd(activeAds[0]);
                    logImpression(activeAds[0]._id);
                } else {
                    console.log("No active ads with images found");
                }
            } catch (error) {
                console.error('Error fetching ad:', error);
            }
        };
    
        fetchAd();
    }, []);
    


    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`);
            
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const logImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/impression/${adId}`, {}, { withCredentials: true });
            
        } catch (error) {
            console.error('Error logging impression:', error);
        }
    };

    if (!ad) return null;

    return (
        <div className="fixed z-50 inset-x-0 bottom-0 px-12 py-8 sm:pb-8 lg:px-16 h-32">
            <div className="flex items-center justify-between bg-gray-900 bg-opacity-75 bg-cover bg-center py-4 px-4 h-24 rounded-lg"
                 style={{
                    backgroundImage: `linear-gradient(to left, rgba(0,0,0,0.1), rgba(0,0,0,0.8)), url(${ad.product.images[0].url})`,
                    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.5)' 
                }}>
                <Link to={`/product/${ad.product._id}`} 
                      onClick={() => logAdClick(ad._id)} 
                      className="text-sm leading-6 text-white flex-grow">
                    <strong className="font-semibold">{ad.product.name}</strong>
                    <span> – Explore Now &rarr;</span>
                </Link>
                <button onClick={() => setAd(null)} className="-m-3 p-3">
                    <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
                    <span className="sr-only">Dismiss</span>
                </button>
            </div>
        </div>
    );
};

export default BannerAd;

