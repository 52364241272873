import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { server } from "../../server";
import axios from 'axios';

const SponsoredFeedProducts = ({ adId, product }) => {
    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`);
            console.log('Click logged for ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const logImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/impression/${adId}`, {}, { withCredentials: true });
            console.log('Impression logged for ad:', adId);
        } catch (error) {
            console.error('Error logging impression:', error);
        }
    };

    useEffect(() => {
        
        if (adId) {
            logImpression(adId);
        }
    }, [adId]); 

    if (!product || !product.images || product.images.length === 0) {
        return null; // Render nothing if no product or product images are provided
    }

    const imageUrl = product.images.length > 0 ? product.images[0].url : ''; // Fallback to an empty string if no image is available

    return (
        <div style={{ padding: '20px', backgroundColor: '#333', borderRadius: '10px', margin: '10px auto', maxWidth: '1000px' }}>
            <h2 style={{ textAlign: 'center', marginBottom: '20px', color: 'white' }}>Sponsored Product</h2>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div key={product._id} style={{ width: '100%', textAlign: 'center', margin: '10px 0', border: '1px solid #ddd', borderRadius: '10px', overflow: 'hidden' }}>
                    <Link to={`/product/${product._id}`} onClick={() => logAdClick(adId)} style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
                        {imageUrl && <img src={imageUrl} alt={product.name} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />}
                        <div style={{ backgroundColor: 'rgba(84, 82, 82, 0.5)', color: 'white', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <h3 style={{ fontSize: '16px', margin: '0' }}>{product.name}</h3>
                                <p style={{ fontSize: '14px', fontWeight: 'bold', margin: '5px 0' }}>${product.discountPrice}</p>
                            </div>
                            <Link to={`/shop/${product.handle}`} style={{ fontSize: '12px', textDecoration: 'none', color: '#1f9c29', backgroundColor: 'white', padding: '5px 10px', borderRadius: '5px' }}>Visit Store</Link>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SponsoredFeedProducts;



