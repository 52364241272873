import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from "react-redux"; // useDispatch removed if not used
import { server } from "../../server";
import { AiOutlineClose } from 'react-icons/ai';
import Loader from "../Layout/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const SponsorShop = () => {
    const [adSpendPerDay, setAdSpendPerDay] = useState(50);
    const [adDuration, setAdDuration] = useState(7);
    const [totalAdSpend, setTotalAdSpend] = useState(350);
    const { seller } = useSelector((state) => state.seller);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const currentProfileName = sessionStorage.getItem('currentProfile');
    
    useEffect(() => {
        // If you need to fetch shop details or similar setup, add your logic here
        setTotalAdSpend(adSpendPerDay * adDuration);
    }, [adSpendPerDay, adDuration]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            // Assuming the shop ID is available as seller.shopId or similar
            const shopId = seller.shopId;
            const response = await axios.post(`${server}/sponsored/sponsored-shops`, 
                { shopId, adSpend: totalAdSpend, adDuration }, 
                { withCredentials: true,
                    headers: {
                        'current-profile': currentProfileName || 'defaultProfile',
                    },
                });
            toast.success('Shop successfully sponsored!');
            setIsLoading(false);
        } catch (error) {
            console.error('Error sponsoring shop:', error);
            toast.error('Error sponsoring shop');
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading ? <Loader /> : (
                 <div style={{ padding: '20px', backgroundColor: '#333333', borderRadius: '10px', maxWidth: '100%', margin: 'auto', color: 'white' }}>
                    <ToastContainer />
                    <form onSubmit={handleSubmit}>
                        <h2 style={{ textAlign: 'center' }}>Sponsor Your Shop</h2>
                        
                        {/* Ad Spend and Duration Sliders similar to SponsorProduct */}

                        <div style={{ marginBottom: '20px' }}>
                            <label style={{ display: 'block', marginBottom: '10px' }}>Set Ad Spend per Day ($):</label>
                            <input 
                                type="text"
                                value={adSpendPerDay}
                                onChange={(e) => setAdSpendPerDay(Number(e.target.value))}
                                style={{ marginBottom: '10px', padding: '5px', width: '100%', backgroundColor: '#333333', border: '1px', borderColor: '#fff',  borderRadius: '4px' }}  
                            />
                            <input 
                                type="range" 
                                min="1" 
                                max="1000"
                                value={adSpendPerDay}
                                onChange={(e) => setAdSpendPerDay(Number(e.target.value))}
                                className="range-slider"
                                style={{ width: '100%' }}  
                            />
                        </div>


                        <div style={{ marginBottom: '20px' }}>
                            <label style={{ display: 'block', marginBottom: '10px' }}>Set Ad Duration (Days):</label>
                            <input 
                                type="text"
                                value={adDuration}
                                onChange={(e) => setAdDuration(Number(e.target.value))}
                                style={{ marginBottom: '10px', padding: '5px', width: '100%',  backgroundColor: '#333333', border: '1px', borderColor: '#fff', borderRadius: '4px' }}  
                            />
                            <input 
                                type="range" 
                                min="1" 
                                max="30"
                                value={adDuration}
                                onChange={(e) => setAdDuration(Number(e.target.value))}
                                className="range-slider"
                                style={{ width: '100%' }} 
                            />
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <label style={{ display: 'block', marginBottom: '10px' }}>Total Ad Spend ($): {totalAdSpend}</label>
                        </div>

                       {/* Ad Preview */}
                        {seller && (
                            <div style={{ margin: '20px 0', maxWidth: '100%', padding: '10px', backgroundColor: '#545252', borderRadius: '10px' }}>
                                <h3 style={{ textAlign: 'center', color: 'white' }}>Ad Preview</h3>
                                <Link to={`/shop/${seller.handle}`} style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
                                    {seller.avatar && (
                                        <img src={seller.avatar.url} alt={seller.name} style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%', margin: '10px auto' }} />
                                    )}
                                    {seller.banner && (
                                        <img src={seller.banner.url} alt={seller.name} style={{ width: '100%', height: '200px', objectFit: 'cover' }} />
                                    )}
                                    <div style={{ backgroundColor: 'rgba(84, 82, 82, 0.5)', color: 'white', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <h3 style={{ fontSize: '16px', margin: '0' }}>{seller.name}</h3>
                                        <button style={{ padding: '5px 15px', borderRadius: '5px', background: '#1f9c29', color: 'white', border: 'none', cursor: 'pointer', textDecoration: 'none' }}>Visit Store</button>
                                    </div>
                                </Link>
                            </div>
                        )}



                        <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: '#1f9c29', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Sponsor Shop</button>
                    </form>
                </div>
            )}
        </>
    );
};

export default SponsorShop;
