import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import { fetchOwnedBrands } from "../../redux/actions/brand"; // Import the Redux action
import Loader from "../Layout/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { server } from "../../server";

const SponsorBrand = () => {
    const [selectedBrandId, setSelectedBrandId] = useState('');
    const [adSpendPerDay, setAdSpendPerDay] = useState(50);
    const [adDuration, setAdDuration] = useState(7);
    const [totalAdSpend, setTotalAdSpend] = useState(350);
    const { ownedBrands } = useSelector(state => state.brand);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const currentProfileName = sessionStorage.getItem('currentProfile');

    useEffect(() => {
        dispatch(fetchOwnedBrands());
    }, [dispatch]);

    useEffect(() => {
        setIsLoading(false);
        setTotalAdSpend(adSpendPerDay * adDuration);
    }, [adSpendPerDay, adDuration]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!selectedBrandId) {
            toast.error('No brand selected for sponsorship');
            return;
        }
        setIsLoading(true);
        try {
            const response = await axios.post(`${server}/sponsored/sponsored-brands`, 
                { brandId: selectedBrandId, adSpend: totalAdSpend, adDuration }, 
                { withCredentials: true,
                    headers: {
                        'current-profile': currentProfileName || 'defaultProfile',
                    },
                });
            toast.success('Brand successfully sponsored!');
        } catch (error) {
            console.error('Error sponsoring brand:', error);
            toast.error('Error sponsoring brand');
        }
        setIsLoading(false);
    };

    const handleDeselectBrand = () => {
        setSelectedBrandId('');
    };

    return (
        <>
            {isLoading ? <Loader /> : (
                 <div style={{ padding: '20px', backgroundColor: '#333333', borderRadius: '10px', maxWidth: '100%', margin: 'auto', color: 'white' }}>
                    <ToastContainer />
                    <form onSubmit={handleSubmit}>
                        <h2 style={{ textAlign: 'center' }}>Sponsor Your Brand</h2>

                         {/* Brand Selection and Preview */}
                         <div style={{ marginBottom: '20px' }}>
                            <label style={{ display: 'block', marginBottom: '10px' }}>Select a Brand:</label>
                            <div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px', maxHeight: '200px', overflowY: 'auto', backgroundColor: '#545252' }}>
                                {ownedBrands.map(brand => (
                                    <div key={brand._id} 
                                         onClick={() => setSelectedBrandId(brand._id)} 
                                         style={{ display: 'flex', alignItems: 'center', padding: '5px', cursor: 'pointer', backgroundColor: selectedBrandId === brand._id ? '#1f9c29' : 'transparent', position: 'relative' }}>
                                        <img src={brand.avatarImage.url} alt={brand.name} style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }} />
                                        <span>{brand.name}</span>
                                        {selectedBrandId === brand._id && (
                                            <AiOutlineClose 
                                                onClick={handleDeselectBrand}
                                                style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', color: 'white', cursor: 'pointer' }}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Ad Preview */}
                        {selectedBrandId && (
                            <div style={{ margin: '20px 0', maxWidth: '420px', padding: '10px', backgroundColor: '#545252', borderRadius: '10px' }}>
                                <h3 style={{ textAlign: 'center', color: 'white' }}>Ad Preview</h3>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {ownedBrands.filter(brand => brand._id === selectedBrandId).map(brand => (
                                        <Link to={`/brand/${brand._id}`} style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
                                            {brand.avatarImage && (
                                                <img src={brand.avatarImage.url} alt={brand.name} style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%', margin: '10px auto' }} />
                                            )}
                                            <div style={{ backgroundColor: 'rgba(84, 82, 82, 0.5)', color: 'white', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <h3 style={{ fontSize: '16px', margin: '0' }}>{brand.name}</h3>
                                                <button style={{ fontSize: '12px', padding: '5px 10px', borderRadius: '5px', background: '#1f9c29', color: 'white', border: 'none', cursor: 'pointer' }}>Visit Profile</button>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        )}

                        
                        <div style={{ marginBottom: '20px' }}>
                            <label style={{ display: 'block', marginBottom: '10px' }}>Set Ad Spend per Day ($):</label>
                            <input 
                                type="text"
                                value={adSpendPerDay}
                                onChange={(e) => setAdSpendPerDay(Number(e.target.value))}
                                style={{ marginBottom: '10px', padding: '5px', width: '100%', backgroundColor: '#333333', border: '1px solid #fff', borderRadius: '4px' }}  
                            />
                            <input 
                                type="range" 
                                min="1" 
                                max="1000"
                                value={adSpendPerDay}
                                onChange={(e) => setAdSpendPerDay(Number(e.target.value))}
                                className="range-slider"
                                style={{ width: '100%' }}  
                            />
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <label style={{ display: 'block', marginBottom: '10px' }}>Set Ad Duration (Days):</label>
                            <input 
                                type="text"
                                value={adDuration}
                                onChange={(e) => setAdDuration(Number(e.target.value))}
                                style={{ marginBottom: '10px', padding: '5px', width: '100%', backgroundColor: '#333333', border: '1px solid #fff', borderRadius: '4px' }}  
                            />
                            <input 
                                type="range" 
                                min="1" 
                                max="30"
                                value={adDuration}
                                onChange={(e) => setAdDuration(Number(e.target.value))}
                                className="range-slider"
                                style={{ width: '100%' }} 
                            />
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <label style={{ display: 'block', marginBottom: '10px' }}>Total Ad Spend ($): {totalAdSpend}</label>
                        </div>

                        <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: '#1f9c29', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Sponsor Brand</button>
                    </form>
                </div>
            )}
        </>
    );
};

export default SponsorBrand;
