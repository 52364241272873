import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../server";
import { getAllProductsShop } from "../../redux/actions/product";
import { AiOutlineClose } from 'react-icons/ai';
import Loader from "../Layout/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const SponsorProduct = () => {
    const [selectedProductId, setSelectedProductId] = useState('');
    const [adSpendPerDay, setAdSpendPerDay] = useState(50); 
    const [adDuration, setAdDuration] = useState(7);
    const [totalAdSpend, setTotalAdSpend] = useState(350);
    const [predictedClicks, setPredictedClicks] = useState({ min: 0, max: 0 });
    const [predictedImpressions, setPredictedImpressions] = useState({ min: 0, max: 0 });
    const [displayedPredictedClicks, setDisplayedPredictedClicks] = useState({ min: 0, max: 0 });
    const [displayedPredictedImpressions, setDisplayedPredictedImpressions] = useState({ min: 0, max: 0 });
    const { seller } = useSelector((state) => state.seller);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const id = seller?._id
    const currentProfileName = sessionStorage.getItem('currentProfile');
    const [displayMode, setDisplayMode] = useState('total');
    const debounceRef = useRef(null);

    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);
            try {
                const { data } = await axios.get(`${server}/product/get-all-products-shop/${id}`, { withCredentials: true });
                setProducts(data.products);
            } catch (error) {
                setError(error.response.data.message); // Handle error
            }
            setIsLoading(false);
        };

        fetchProducts();
    }, []);

      

    useEffect(() => {
        setTotalAdSpend(adSpendPerDay * adDuration);
    }, [adSpendPerDay, adDuration]);

    useEffect(() => {
        fetchPredictions(adSpendPerDay * adDuration);
    }, []); 
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${server}/sponsored/sponsored-products`, 
                { productId: selectedProductId, adSpend: totalAdSpend, adDuration }, 
                { withCredentials: true,
                    headers: {
                      'current-profile': currentProfileName || 'defaultProfile',
                    }, });
            console.log(response.data.message);
            toast.success('Product successfully sponsored!');

            setSelectedProductId('');
            setAdSpendPerDay(50); 
            setAdDuration(7); 
            setTotalAdSpend(350); 
    
        } catch (error) {
            console.error('Error sponsoring product:', error);
            toast.error('Error sponsoring product'); 
        }
    };


    const fetchPredictions = async (spend) => {
        if (debounceRef.current) clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(async () => {
            try {
                const { data } = await axios.post(`${server}/sponsored/predict`, { adSpend: spend }, { withCredentials: true });
                if (data) {
                    setPredictedClicks(data.predictedClicks);
                    setPredictedImpressions(data.predictedImpressions);
                    updateDisplayValues(displayMode);
                }
            } catch (error) {
                console.error('Error fetching predictions:', error);
                toast.error('Failed to fetch predictions');
            }
        }, 500);
    };

    // Update display mode to show either total or daily values
    const updateDisplayValues = (mode) => {
        const factor = mode === 'daily' ? 1 / adDuration : 1;
        setDisplayedPredictedClicks({
            min: Math.round(predictedClicks.min * factor),
            max: Math.round(predictedClicks.max * factor)
        });
        setDisplayedPredictedImpressions({
            min: Math.round(predictedImpressions.min * factor),
            max: Math.round(predictedImpressions.max * factor)
        });
    };

    const handleDisplayModeChange = (mode) => {
        setDisplayMode(mode);
        updateDisplayValues(mode);
    };
    
      
    

    const handleDeselectProduct = (event) => {
        event.stopPropagation();
        setSelectedProductId('');
    };

    return (
        <>
        {isLoading ? (
          <Loader />
        ) : (
       
            <div style={{ padding: '20px', backgroundColor: '#333333', borderRadius: '10px', maxWidth: '100%', margin: 'auto', color: 'white' }}>
            <form onSubmit={handleSubmit}>
                <h2 style={{ textAlign: 'center' }}>Sponsor a Product</h2>
                
                {/* Product Selection and Preview */}
                <div style={{ marginBottom: '20px' }}>
                        <label style={{ display: 'block', marginBottom: '10px' }}>Select a Product:</label>
                        <div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px', maxHeight: '200px', overflowY: 'auto', backgroundColor: '#545252' }}>
                            {products.map(product => (
                                <div key={product._id} 
                                     onClick={() => setSelectedProductId(product._id)} 
                                     style={{ display: 'flex', alignItems: 'center', padding: '5px', cursor: 'pointer', backgroundColor: selectedProductId === product._id ? '#1f9c29' : 'transparent', position: 'relative' }}>
                                    <img src={product.images[0].url} alt={product.name} style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }} />
                                    <span>{product.name}</span>
                                    {selectedProductId === product._id && (
                                        <AiOutlineClose 
                                            onClick={handleDeselectProduct}
                                            style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', color: 'white', cursor: 'pointer' }}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Ad Preview */}
                    {selectedProductId && (
                        <div style={{ margin: '20px 0', maxwidth: '420px', padding: '10px', backgroundColor: '#545252', borderRadius: '10px' }}>
                            <h3 style={{ textAlign: 'center', color: 'white' }}>Ad Preview</h3>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {products.filter(product => product._id === selectedProductId).map(product => (
                                    <Link to={`/product/${product._id}`} style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
                                        {product.images && product.images.length > 0 && (
                                            <img src={product.images[0].url} alt={product.name} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                                        )}
                                        <div style={{ backgroundColor: 'rgba(84, 82, 82, 0.5)', color: 'white', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>
                                                <h3 style={{ fontSize: '16px', margin: '0' }}>{product.name}</h3>
                                                <p style={{ fontSize: '14px', fontWeight: 'bold', margin: '5px 0' }}>${product.discountPrice || product.originalPrice}</p>
                                            </div>
                                            <Link to={`/shop/${product.handle}`} style={{ fontSize: '12px', textDecoration: 'none', color: '#1f9c29', backgroundColor: 'white', padding: '5px 10px', borderRadius: '5px' }}>Visit Store</Link>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    )}

                {/* Ad Spend Slider */}
                <div style={{ marginBottom: '20px' }}>
                    <label style={{ display: 'block', marginBottom: '10px' }}>Set Ad Spend per Day ($):</label>
                    <input 
                        type="text"
                        value={adSpendPerDay}
                        onChange={(e) => {
                            const newSpend = Number(e.target.value);
                            setAdSpendPerDay(newSpend);
                            fetchPredictions(newSpend * adDuration); 
                        }}
                        style={{ marginBottom: '10px', padding: '5px',  backgroundColor: '#333333', border: '1px', borderColor: '#fff', borderRadius: '4px' }}
                    />
                    <input 
                        type="range" 
                        min="1" 
                        max="1000"
                        value={adSpendPerDay}
                        onChange={(e) => {
                            const newSpend = Number(e.target.value);
                            setAdSpendPerDay(newSpend);
                            fetchPredictions(newSpend * adDuration); 
                        }}
                        className="range-slider"
                    />

                </div>

                {/* Ad Duration Slider */}
                <div style={{ marginBottom: '20px' }}>
                    <label style={{ display: 'block', marginBottom: '10px' }}>Set Ad Duration (Days):</label>
                    <input 
                        type="text"
                        value={adDuration}
                        onChange={(e) => {
                            const newDuration = Number(e.target.value);
                            setAdDuration(newDuration);
                            fetchPredictions(adSpendPerDay * newDuration); 
                        }}
                        style={{ marginBottom: '10px', padding: '5px', backgroundColor: '#333333', border: '1px', borderColor: '#fff', borderRadius: '4px' }}
                    />
                    <input 
                        type="range" 
                        min="1" 
                        max="30"
                        value={adDuration}
                        onChange={(e) => {
                            const newDuration = Number(e.target.value);
                            setAdDuration(newDuration);
                            fetchPredictions(adSpendPerDay * newDuration); 
                        }}
                        className="range-slider"
                    />
                </div>


                {/* Display Total Ad Spend */}
                <div style={{ marginBottom: '20px' }}>
                    <label style={{ display: 'block', marginBottom: '10px' }}>Total Ad Spend ($): {totalAdSpend}</label>
                </div>

                <div style={{ marginBottom: '20px' }}>
                            <button type="button" onClick={() => handleDisplayModeChange('daily')} style={{ marginRight: '10px' }}>Daily</button>
                            <button type="button" onClick={() => handleDisplayModeChange('total')}>Total</button>
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                    <label style={{ display: 'block', marginBottom: '10px' }}>Predicted Clicks Range: {displayedPredictedClicks.min} to {displayedPredictedClicks.max}</label>
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <label style={{ display: 'block', marginBottom: '10px' }}>Predicted Impressions Range: {displayedPredictedImpressions.min} to {displayedPredictedImpressions.max}</label>
                </div>



                <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: '#1f9c29', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Sponsor Product</button>
            </form>
        </div>
)}
</>
);
};

export default SponsorProduct;
