import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, TextField } from '@mui/material';
import Friend from '../../components/Friend'; 
import WidgetWrapper from '../../components/WidgetWrapper';
import { getCurrentUserFollowers } from '../../redux/actions/followers';  

const FollowerListWidget = ({ userId, profileType }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const { currentUserFollowers, loading, error } = useSelector(state => state.followers);  

  useEffect(() => {
    console.log('Effect running for userId:', userId, 'profileType:', profileType);
    if (userId && profileType) {
      dispatch(getCurrentUserFollowers(userId, profileType));
      console.log('Dispatching getCurrentUserFollowers');
    }
  }, [userId, profileType, dispatch]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredFollowers = searchTerm
    ? currentUserFollowers.filter((follower) => follower.name.toLowerCase().includes(searchTerm))
    : currentUserFollowers;

  return (
    <WidgetWrapper>
      <Typography color="#2c4a2e" variant="h5" fontWeight="500" sx={{ mb: '1rem' }}>
        Followers
      </Typography>
      <TextField
        variant="outlined"
        placeholder="Search followers"
        fullWidth
        onChange={handleSearchChange}
        sx={{ mb: '1.5rem' }}
      />
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {loading && <Typography>Loading...</Typography>}
        {error && <Typography>{error}</Typography>}
        {!loading && !error && filteredFollowers.length > 0 ? filteredFollowers.map((follower) => (
          <Friend key={follower._id} friendId={follower._id} {...follower} friendType={follower.type} />
        )) : <Typography>{error || 'No followers found.'}</Typography>}
      </Box>
    </WidgetWrapper>
  );
};

export default FollowerListWidget;
