import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@mui/material';

const Carousel4Slides = () => {
    // Example items data
    const items = [
        {
            name: "Dad Grass",
            description: "Nothing like the old-school!",
            backgroundImage: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1707305463/nkjhmugjf0pbrgqpet3c.jpg",
            buttonLink: "http://link_to_some_destination.com",
            buttonText: "Learn More"
        },
        {
            name: "Crystal CBD",
            description: "The New Collection",
            backgroundImage: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1707304820/ipqauu9pcdoalabtqrmk.jpg",
            buttonLink: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1707304820/ipqauu9pcdoalabtqrmk.jpg",
            buttonText: "Discover"
        },
        {
            name: "What's the Buzz?",
            description: "Our Favourites",
            backgroundImage: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1707301222/frpqwldxxe29zaqcpuea.jpg",
            buttonLink: "http://link_to_some_destination.com",
            buttonText: "Explore"
        },
        {
            name: "Sustainabilty Matters",
            description: "Sustainable Cannabis Packaging",
            backgroundImage: "https://res.cloudinary.com/dzlopmfj8/image/upload/v1707295626/dluc2s6bhbr75zhh4tzm.jpg",
            buttonLink: "http://link_to_some_destination.com",
            buttonText: "Sign Up"
        }
    ];

    return (
        <Carousel>
            {
                items.map((item, i) => <Item key={i} item={item} />)
            }
        </Carousel>
    );
}

const Item = ({item}) => {
    return (
        <Paper style={{
            background: `url(${item.backgroundImage}) center center`,
            backgroundSize: 'cover',
            height: 400,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            color: '#fff'
        }}>
            <div style={{
                background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
                borderRadius: '10px',
                padding: '20px',
                textAlign: 'center'
            }}>
                <h2 style={{ fontSize: '2em' }}>{item.name}</h2>
                <p style={{ fontSize: '1.25em' }}>{item.description}</p>
            </div>
            <Button className="CheckButton" href={item.buttonLink} variant="contained" style={{backgroundColor: '#4caf50', marginTop: '20px'}}>
                {item.buttonText}
            </Button>
        </Paper>
    )
}


export default Carousel4Slides;
