import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchBrandCatalogItems } from '../../redux/actions/brand';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { server } from '../../server';


const BrandCatalog = () => {
  const { brandId } = useParams();
  const dispatch = useDispatch();
  const { catalogItems, error } = useSelector((state) => state.brand);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [select, setSelect] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editFormData, setEditFormData] = useState({
    name: '',
    description: '',
    priceFloor: '',
    category: '',
    SKU: '',
    productType: '',
    unitOfMeasurement: '',
    containsTHC: false,
    containsCBD: false,
    isEdible: false,
    newImages: [],
    newVideos: [],
  });


  useEffect(() => {
    dispatch(fetchBrandCatalogItems(brandId));
  }, [dispatch, brandId]);

  useEffect(() => {
    if (isEditMode && selectedItem) {
      setEditFormData({
        name: selectedItem.name || '',
        description: selectedItem.description || '',
        priceFloor: selectedItem.priceFloor || '',
        category: selectedItem.category || '',
        SKU: selectedItem.SKU || '',
        productType: selectedItem.productType?.join(', ') || '',
        unitOfMeasurement: selectedItem.unitOfMeasurement || '',
        containsTHC: selectedItem.containsTHC || false,
        containsCBD: selectedItem.containsCBD || false,
        isEdible: selectedItem.isEdible || false,
        newImages: [],
        newVideos: [],
      });
    }
  }, [isEditMode, selectedItem]);

  const openModal = (item, mode = 'view') => {
    setSelectedItem(item);
    setIsModalOpen(true);
    setIsEditMode(mode === 'edit');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
    setIsEditMode(false);
    // Reset form data upon closing the modal to ensure clean state for next edit
    setEditFormData({
      name: '',
      description: '',
      priceFloor: '',
      category: '',
      SKU: '',
      productType: '',
      unitOfMeasurement: '',
      containsTHC: false,
      containsCBD: false,
      isEdible: false,
      newImages: [],
      newVideos: [],
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleFileChange = (e) => {
    setEditFormData({
      ...editFormData,
      [e.target.name]: [...e.target.files],
    });
  };

  const submitEditForm = async () => {
    const formData = new FormData();
    Object.keys(editFormData).forEach((key) => {
      if (key === 'newImages' || key === 'newVideos') {
        Array.from(editFormData[key]).forEach((file) => {
          formData.append(key, file);
        });
      } else {
        formData.append(key, editFormData[key]);
      }
    });


    try {
      const response = await fetch(`${server}/update-brand-catalog-item/${selectedItem._id}`, {
        method: 'PATCH',
        body: formData,
      });
      const data = await response.json();
      if (data.success) {
        alert('Catalog item updated successfully');
        closeModal();
        dispatch(fetchBrandCatalogItems(brandId)); 
      } else {
        alert('Error updating catalog item');
      }
    } catch (error) {
      console.error('Error updating catalog item:', error);
      alert('Error updating catalog item');
    }
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="bg-white">
      {/* Catalog grid */}
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="text-2xl font-bold text-gray-900">Brand Catalog</h2>
        {catalogItems.length > 0 ? (
          <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
            {catalogItems.map((item) => (
              <div key={item._id} className="relative cursor-pointer" onClick={() => openModal(item)}>
                <div className="relative h-72 w-full overflow-hidden rounded-lg">
                  {item.images.length > 0 && (
                    <img src={item.images[0].url} alt={item.name} className="h-full w-full object-cover object-center" />
                  )}
                </div>
                <div className="relative mt-4">
                  <h3 className="text-sm font-medium text-gray-900">{item.name}</h3>
                  <p className="mt-1 text-sm text-gray-500">{item.category}</p>
                  <p className="mt-2 text-sm font-semibold text-gray-700">${item.priceFloor}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No catalog items found.</p>
        )}
      </div>


     
{
  isModalOpen && selectedItem && (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        {/* Modal backdrop */}
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full mt-10 items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                {isEditMode ? (
                  // Edit form
                  <form onSubmit={(e) => e.preventDefault()} className="space-y-6">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={editFormData.name}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border bg-gray-700 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                      <textarea
                        name="description"
                        id="description"
                        value={editFormData.description}
                        onChange={handleInputChange}
                        rows={3}
                        className="mt-1 block w-full border bg-gray-700 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      ></textarea>
                    </div>
                    <div>
                      <label htmlFor="priceFloor" className="block text-sm font-medium text-gray-700">Price Floor</label>
                      <input
                        type="number"
                        name="priceFloor"
                        id="priceFloor"
                        value={editFormData.priceFloor}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border bg-gray-700 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Product Type</label>
                      <input
                        type="text"
                        name="productType"
                        value={editFormData.productType}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border bg-gray-700 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                        placeholder="Separate types with commas (e.g., retail,wholesale)"
                      />
                    </div>
                    {/* Checkboxes for boolean fields */}
                    <div>
                      <label htmlFor="containsTHC" className="flex items-center">
                        <input
                          type="checkbox"
                          name="containsTHC"
                          id="containsTHC"
                          checked={editFormData.containsTHC}
                          onChange={handleInputChange}
                          className="mr-2"
                        />
                        Contains THC
                      </label>
                    </div>
                    <div>
                      <label htmlFor="category" className="block text-sm font-medium text-gray-700">Category</label>
                      <input
                        type="text"
                        name="category"
                        id="category"
                        value={editFormData.category}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border bg-gray-700 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="SKU" className="block text-sm font-medium text-gray-700">SKU</label>
                      <input
                        type="text"
                        name="SKU"
                        id="SKU"
                        value={editFormData.SKU}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border bg-gray-700 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="containsCBD" className="flex items-center">
                        <input
                          type="checkbox"
                          name="containsCBD"
                          id="containsCBD"
                          checked={editFormData.containsCBD}
                          onChange={handleInputChange}
                          className="mr-2"
                        />
                        Contains CBD
                      </label>
                    </div>
                    <div>
                      <label htmlFor="isEdible" className="flex items-center">
                        <input
                          type="checkbox"
                          name="isEdible"
                          id="isEdible"
                          checked={editFormData.isEdible}
                          onChange={handleInputChange}
                          className="mr-2"
                        />
                        Is Edible
                      </label>
                    </div>

                    {/* File inputs for images and videos */}
                    <div className="space-y-4">
                      <h4 className="text-lg font-medium text-gray-300">Current Images</h4>
                      <div className="flex flex-wrap">
                        {selectedItem.images.map((image, index) => (
                          <div key={index} className="w-24 h-24 p-1 m-1 border border-gray-300">
                            <img src={image.url} alt={`Image ${index + 1}`} className="object-cover w-full h-full" />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div>
                      <label htmlFor="newImages" className="block text-sm font-medium text-gray-700">Upload New Images</label>
                      <input
                        type="file"
                        name="newImages"
                        id="newImages"
                        multiple
                        onChange={handleFileChange}
                        className="mt-1 block w-full text-sm text-gray-900 border-gray-300 rounded-md file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-green-50 file:text-green-700 hover:file:bg-green-100"
                      />
                    </div>
                    <div>
                      <h4 className="text-lg font-medium text-gray-300">Current Videos</h4>
                      <div className="flex flex-wrap">
                        {selectedItem.videos.map((video, index) => (
                          <div key={index} className="w-24 h-24 p-1 m-1 border border-gray-300">
                            <video controls className="object-cover w-full h-full">
                              <source src={video.url} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div>
                      <label htmlFor="newVideos" className="block text-sm font-medium text-gray-700">Upload New Videos</label>
                      <input
                        type="file"
                        name="newVideos"
                        id="newVideos"
                        multiple
                        onChange={handleFileChange}
                        className="mt-1 block w-full text-sm text-gray-900 border-gray-300 rounded-md file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-green-50 file:text-green-700 hover:file:bg-green-100"
                      />
                    </div>

                    {/* File input for videos */}
                    <div>
                      <button
                        type="button"
                        onClick={submitEditForm}
                        className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                      >
                        Save Changes
                      </button>
                    </div>
                  </form>
                ) : (
                  <div>
                    <>
                      <div className="absolute top-0 right-0 pt-4 pr-4">
                        <button
                          type="button"
                          className="text-gray-400 hover:text-gray-500"
                          onClick={closeModal}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                      <div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            {/* Display Area */}
                            {select < selectedItem.images.length ? (
                            <img
                                src={selectedItem.images[select]?.url}
                                alt={selectedItem.name}
                                className="aspect-w-1 aspect-h-1 rounded-md object-cover"
                            />
                            ) : (
                            <video
                                src={selectedItem.videos[select - selectedItem.images.length]?.url}
                                className="aspect-w-1 aspect-h-1 object-cover"
                                controls
                            />
                            )}
                            {/* Thumbnails Gallery */}
                            <div className="mt-4">
                            <h4 className="font-medium text-gray-900">Images</h4>
                            <div className="flex flex-wrap">
                                {selectedItem.images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image.url}
                                    alt="Product"
                                    className={`w-24 h-24 mr-2 mb-2 cursor-pointer ${select === index ? "ring-2 ring-green-500" : ""}`}
                                    onClick={() => setSelect(index)}
                                />
                                ))}
                                {selectedItem.videos.map((video, index) => (
                                <video
                                    key={index}
                                    src={video.url}
                                    className={`w-24 h-24 mr-2 mb-2 cursor-pointer ${select === selectedItem.images.length + index ? "ring-2 ring-green-500" : ""}`}
                                    onClick={() => setSelect(selectedItem.images.length + index)}
                                    loop
                                />
                                ))}
                            </div>
                            </div>
                        </div>
                        <div>
                            <h3 className="text-2xl font-medium text-gray-900">{selectedItem.name}</h3>
                            <p className="mt-1 text-sm text-gray-500">{selectedItem.description}</p>
                            <p className="mt-2 text-sm font-semibold text-gray-700">Price Floor: ${selectedItem.priceFloor}</p>
                        </div>
                        </div>
                        <div className="mt-2">
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <p>SKU: {selectedItem.SKU}</p>
                            <p>Category: {selectedItem.category}</p>
                            <p>Product Type: {selectedItem.productType.join(', ')}</p>
                            <p>Unit of Measurement: {selectedItem.unitOfMeasurement}</p>
                            <p>Contains THC: {selectedItem.containsTHC ? 'Yes' : 'No'}</p>
                            <p>Contains CBD: {selectedItem.containsCBD ? 'Yes' : 'No'}</p>
                            <p>Is Edible: {selectedItem.isEdible ? 'Yes' : 'No'}</p>
                          </div>
                          <div className="mt-4">
                            <h4 className="font-medium text-gray-900">Test Results</h4>
                            {selectedItem.testResults.map((testResult, index) => (
                              <img key={index} src={testResult.url} alt="Test Result" className="w-24 h-24 mr-2 mb-2" />
                            ))}
                          </div>
                          <div className="mt-4">
                            <h4 className="font-medium text-gray-900">Nutrition Info Image</h4>
                            {selectedItem.nutritionInfoImage.map((image, index) => (
                              <img key={index} src={image.url} alt="Nutrition Info" className="w-24 h-24 mr-2 mb-2" />
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                     
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-500 text-base font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                      <button
                        onClick={() => setIsEditMode(true)}
                        className="mt-4 sm:mt-0 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                      >
                        Edit
                      </button>
                    </div>

                    </>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
    </div>
  );
                            }

export default BrandCatalog;




  