import React from 'react'
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader'
import SponsorProduct from '../../components/Sponsored/SponsorProducts';
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import SponsoredDashboard from '../../components/Sponsored/SponsoredDashboard';
import SponsorShop from '../../components/Sponsored/SponsorShop';
import DualSponsoredDashboard from '../../components/Sponsored/DualSponsoredDashboard';
import SponsorBrand from '../../components/Sponsored/SponsorBrand';


const ShopCreateAdvertisement = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <DashboardHeader />
            <div className="flex flex-grow">
                <div className="w-[330px]">
                    <DashboardSideBar active={7} />
                </div>
                <div className="flex flex-col flex-grow p-4">
                    <div className="flex justify-between mb-4">
                        <div className="flex-1 p-1 mr-2">
                            <SponsorProduct />
                        </div>
                        <div className="flex-1 p-1">
                            <SponsorShop />
                        </div>
                        <div className="flex-1 p-1">
                            <SponsorBrand />
                        </div>
                    </div>
                    <div>
                        <DualSponsoredDashboard />
                    </div>
                </div>
            </div>
        </div>
      );
    }

export default ShopCreateAdvertisement