import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const DropDown = ({ categoriesData, setDropDown }) => {
  const navigate = useNavigate();
  const dropdownRef = useRef();

  const featureProducts = [
    {
      title: 'Dad Flower',
      image_Url: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1707305463/nkjhmugjf0pbrgqpet3c.jpg',
      shopName: 'Dad Grass',
    },
    {
      title: 'Crystal Weed CBD flower',
      image_Url: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1707304820/ipqauu9pcdoalabtqrmk.jpg',
      shopName: 'Crystal',
    },
  ];

    const featuredShop = {
      name: 'The Weed Rabbi',
      imageUrl: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1702653845/banners/xqe7z5qpegfufyodgrwz.png',
    };

  const submitHandle = (i) => {
    navigate(`/products/${i.title}`);
    setDropDown(false);
    window.location.reload();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropDown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setDropDown]);

  return (
    <div ref={dropdownRef} className="absolute z-50 mt-1 top-14 w-full sm:min-w-[420px] md:min-w-[1000px] max-w-7xl shadow-lg rounded-md" style={{ backgroundColor: 'rgba(34, 34, 34, 0.8)', border: '2px solid green' }}>
      <div className="flex">
        {/* Categories Section - 2/5 width */}
        <div className="flex-grow" style={{ flexBasis: '40%' }}>
          <h3 className="text-sm font-medium text-green-700 p-4 mb-4">Categories</h3>
          {categoriesData.map((category, index) => (
            <div key={index} className="flex items-center gap-4 py-2 px-4 cursor-pointer hover:bg-green-700 rounded-md" onClick={() => submitHandle(category)}>
              <img src={category.image_Url} alt="" className="w-12 h-12 rounded-sm object-cover" />
              <span className="text-sm font-semibold">{category.title}</span>
            </div>
          ))}
        </div>
        {/* Feature Products Section - 3/5 width */}
        <div className="flex-grow" style={{ flexBasis: '60%' }}>
          <h3 className="text-sm font-medium text-green-700 p-4 mb-4">Featured Products</h3>
          {featureProducts.map((product, index) => (
            <div key={index} className="flex items-center gap-4 py-2 px-4 cursor-pointer hover:bg-black rounded-md hover:shadow-lg">
              <img src={product.image_Url} alt="" className="rounded-md" style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
              <div>
                <span className="text-m font-semibold" style={{ fontSize: '20px', color: 'white' }}> {product.title}</span>
                <div className="text-s" style={{ fontSize: '12px', color: '#46D300' }}>{product.shopName}</div>
              </div>
            </div>
          ))}
          {/* Featured Shop Section */}
      <div className="p-4">
        <h3 className="text-sm font-medium text-green-700 mb-4">Featured Shop</h3>
        <div className="relative">
          <img src={featuredShop.imageUrl} alt={featuredShop.name} className="w-full h-auto object-cover rounded-md" />
          <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 p-2 rounded-b-md">
            <span className="text-white font-semibold">{featuredShop.name}</span>
          </div>
        </div>
      </div>
        </div>
      </div>
    </div>
  );
};

export default DropDown;
