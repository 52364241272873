import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { server } from "../../server";
import Modal from '@mui/material/Modal';  
import Box from '@mui/material/Box';      

const SavePaymentMethod = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [paymentMethodInfo, setPaymentMethodInfo] = useState(null);
  const [openModal, setOpenModal] = useState(false); 

  const fetchPaymentMethod = async () => {
    try {
      const response = await axios.get(`${server}/sponsored/get-payment-method`, { withCredentials: true, headers: {
        "Content-Type": "application/json",
        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
    } });
        setPaymentMethodInfo(response.data.paymentMethod);
    } catch (error) {
        console.error('Error fetching payment method:', error.response?.data);
        alert('Failed to fetch payment method.');
    }
  };

  useEffect(() => {
    fetchPaymentMethod();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    const cardElement = elements.getElement(CardElement);
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('[error]', error);
      alert(error.message);
      setLoading(false);
    } else {
      try {
        const response = await axios.post(`${server}/sponsored/save-payment-method`, {
          paymentMethodId: paymentMethod.id,
        }, { withCredentials: true,  headers: {
          "Content-Type": "application/json",
          'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
      }});

        alert('Payment method saved successfully!');
        fetchPaymentMethod(); 
      } catch (err) {
        console.error('Error saving payment method:', err.response?.data);
        alert('Failed to save the payment method.');
      }
      setLoading(false);
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 690,
    bgcolor: '#333',
    color: '#fff',
    boxShadow: 24,
    p: 4,
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  return (
    <div className="spm-container">
      {paymentMethodInfo && (
        <div className="spm-payment-details">
            <h3 className="spm-details-heading">Current Payment Method:</h3>
            <p className="spm-details-brand">Brand: {paymentMethodInfo.card.brand}</p>
            <p className="spm-details-last4">Last four: {paymentMethodInfo.card.last4}</p>
            <p className="spm-details-expiry">Expiry: {paymentMethodInfo.card.exp_month}/{paymentMethodInfo.card.exp_year}</p>
            <button onClick={() => setOpenModal(true)} className="spm-open-modal-btn">Add New Payment Method</button>
        </div>
      )}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <h2 id="modal-modal-title">Add New Payment Method</h2>
          <form onSubmit={handleSubmit} className="spm-form">
              <CardElement className="spm-card-element" />
              <button className="spm-submit-btn" type="submit" disabled={!stripe || loading}>
                  Save Payment Method
              </button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default SavePaymentMethod;