import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { server } from "../../server";

const SponsoredShopsCarousel = () => {
    const [shops, setShops] = useState([]);

    // Fetch shops and set up impression logging
    useEffect(() => {
        const fetchShops = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-shops`, { withCredentials: true });
                const activeShops = response.data.filter(ad => ad.isActive && ad.shop);
                setShops(activeShops);
            } catch (error) {
                console.error('Error fetching shops:', error);
            }
        };

        fetchShops();
    }, []);

    // Log impressions
    useEffect(() => {
        shops.forEach(shop => {
            axios.post(`${server}/sponsored/impression/${shop._id}`, {}, { withCredentials: true })
                .then(() => console.log('Impression logged for shop:', shop._id))
                .catch(error => console.error('Error logging impression:', error));
        });
    }, [shops]);

    // Log ad clicks
    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`);
            console.log('Click logged for shop:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    return (
        <Carousel>
            {shops.map(({ _id, shop }) => (
                <ShopItem key={_id} shop={shop} logAdClick={() => logAdClick(_id)} />
            ))}
        </Carousel>
    );
};

const ShopItem = ({ shop, logAdClick }) => {
    return (
        <Paper style={{
            background: `url(${shop.banner?.url}) center center`,
            backgroundSize: 'cover',
            height: 400,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            color: '#fff'
        }}>
            <div style={{
                background: 'rgba(0, 0, 0, 0.5)',
                borderRadius: '10px',
                padding: '20px',
                textAlign: 'center'
            }}>
                <img src={shop.avatar?.url} alt={shop.name} style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%', margin: '10px' }} />
                <h2 style={{ fontSize: '2em' }}>{shop.name}</h2>
                <Button component={Link} to={`/shop/${shop.handle}`} onClick={logAdClick} variant="contained" style={{ backgroundColor: '#4caf50', marginTop: '20px' }}>
                    Visit Store
                </Button>
            </div>
        </Paper>
    )
}

export default SponsoredShopsCarousel;
