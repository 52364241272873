import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadSeller } from "../../redux/actions/user";
import FollowerListWidget from './FollowersListWidget';
import FollowingListWidget from './FollowingListWidget';

export default function ConnectionsWidget() {
  const dispatch = useDispatch();
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const profileId = currentProfile === 'User' ? user?._id : seller?._id;
  const profileType = currentProfile === 'User' ? 'User' : 'Shop'

  useEffect(() => {
    dispatch(loadSeller()); // Adjust as necessary based on what data needs to be loaded
  }, [dispatch]);

  // You can still keep the tab functionality to switch views between Followers and Following
  const [activeTab, setActiveTab] = useState('Followers');
  const tabs = [
    { name: 'Followers', current: activeTab === 'Followers' },
    { name: 'Following', current: activeTab === 'Following' },
  ];

  return (
    <div className="relative z-10">
      <div className="bg-white shadow-xl">
        <div className="p-6">
          <div className="flex items-start justify-between">
            <h1 className="text-lg font-medium text-gray-900">Social Connections</h1>
          </div>
          <div className="mb-4 border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  className={tab.current ? 'border-green-500 text-green-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
                  style={{ paddingBottom: '16px', borderBottomWidth: '2px', fontWeight: 'medium', fontSize: 'small', whiteSpace: 'nowrap' }}
                  aria-current={tab.current ? 'page' : undefined}
                  onClick={() => setActiveTab(tab.name)}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
          {activeTab === 'Followers' ? <FollowerListWidget key={profileId} userId={profileId} profileType={profileType} /> : null}
          {activeTab === 'Following' ? <FollowingListWidget key={profileId} userId={profileId} profileType={profileType} /> : null}
        </div>
      </div>
    </div>
  );
}
