import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom"; // Import useParams
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import Sidebar from '../components/Layout/Sidebar';
import HeroAllProducts from "../components/Route/Hero/HeroAllProducts";
import SuggestedProduct from "../components/Products/SuggestedProduct";
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid'; 
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import TrendingProductsCarosel from "../components/Products/TrendingProductsCarosel";

const CategoryProductsPage = () => {
  const { category } = useParams();
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000); 
  const [brands, setBrands] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [openPriceRange, setOpenPriceRange] = useState(false);
  const [availableForPickup, setAvailableForPickup] = useState(false);
  const [availableForDelivery, setAvailableForDelivery] = useState(false);
  const [minRating, setMinRating] = useState(0); 
  const [sortOption, setSortOption] = useState('');

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    if (allProducts) {
      const uniqueBrands = new Set(allProducts.map(product => product.brand));
      setBrands([...uniqueBrands]);
  
      let filteredProducts = [...allProducts];
  
      const newActiveFilters = [];

      // Sort products based on the selected sort option
    if (sortOption === 'priceLowHigh') {
      filteredProducts.sort((a, b) => a.discountPrice - b.discountPrice);
    } 
    if (sortOption === 'priceHighToLow') {
      filteredProducts.sort((a, b) => b.discountPrice - a.discountPrice);
    } 
    if (sortOption === 'newest') {
      filteredProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }
      if (category) {
        filteredProducts = filteredProducts.filter(product => product.category === category);
        // Assuming you want to add a category filter to active filters as well
        newActiveFilters.push({ id: 'category', name: 'Category', value: category });
      }
      if (selectedBrand) {
        filteredProducts = filteredProducts.filter(product => product.brand === selectedBrand);
        newActiveFilters.push({ id: 'brand', name: 'Brand', value: selectedBrand });
      }
      // Rating filter
    if (minRating > 0) {
      filteredProducts = filteredProducts.filter(product => product.ratings >= minRating);
      newActiveFilters.push({ id: 'rating', name: 'Rating', value: `${minRating}+ stars` });
    }
      if (availableForPickup) {
        filteredProducts = filteredProducts.filter(product => product.availableForPickup);
        newActiveFilters.push({ id: 'availability', name: 'Availability', value: 'Pickup' });
      } else if (availableForDelivery) {
        filteredProducts = filteredProducts.filter(product => product.availableForDelivery);
        newActiveFilters.push({ id: 'availability', name: 'Availability', value: 'Delivery' });
      }
  
      filteredProducts = filteredProducts.filter(product => product.discountPrice >= minPrice && product.discountPrice <= maxPrice);
      newActiveFilters.push({ id: 'priceRange', name: 'Price Range', value: `$${minPrice} - $${maxPrice}` });
  
      setData(filteredProducts);
      setActiveFilters(newActiveFilters);
    } else {
      setData([]);
      setBrands([]);
    }
  }, [allProducts, sortOption, category, selectedBrand, minPrice, maxPrice, availableForPickup, availableForDelivery, minRating]);
  

  const removeFilter = (filterId) => {
    setActiveFilters(activeFilters.filter(filter => filter.id !== filterId));
    if (filterId === 'brand') {
      setSelectedBrand('');
    } else if (filterId === 'availability') {
      setAvailableForPickup(false);
      setAvailableForDelivery(false);
    } else if (filterId === 'priceRange') {
      setMinPrice(0);
      setMaxPrice(1000);
    } else if (filterId === 'rating') {
      setMinRating(0); 
    }
  };
  

  const renderProductCards = (products) => {
    return products.map((product, index) => <ProductCard data={product} key={index} />);
  };

  const renderActiveFilters = () => {
    return activeFilters.map((filter) => (
      <span key={filter.id} className="inline-flex items-center shadow-lg rounded-full border px-3 py-0.5 text-sm font-medium text-gray-700 bg-green-700 m-1">
        {filter.name}: {filter.value}
        <button onClick={() => removeFilter(filter.id)} className="flex-shrink-0 ml-2 inline-flex text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <XMarkIcon className="h-3 w-3" aria-hidden="true" />
        </button>
      </span>
    ));
  };

  // Render brand filter dropdown and price range slider here
  const renderFilters = () => {
    return (
      <div className="flex justify-center">
        <div className="w-full max-w-7xl">
          <Disclosure as="section" className="border-b border-gray-200 px-4 py-6 sm:px-6 lg:px-8">
            {({ open }) => (
              <>
                <h2 id="filter-heading" className="text-3xl text-green-500 font-medium text-center">
                  {category ? category : "All Products"}
                </h2>
                <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                  <span className="font-medium text-gray-900">Filters</span>
                  <ChevronDownIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5`} />
                </Disclosure.Button>
                <Disclosure.Panel className="pt-4">
                  <div className="flex justify-center pt-2 pb-2 space-x-4 overflow-x-auto">
                    {/* Brand Filter */}
                    <Menu as="div" className="relative inline-block text-left">
                      <Menu.Button className="inline-flex justify-center items-center w-[150px] h-[40px] rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50">
                        {selectedBrand || 'Select Brand'}
                        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-400" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                  } group flex w-full items-center px-4 py-2 text-sm`}
                                  onClick={() => setSelectedBrand('')}
                                >
                                  All Brands
                                </button>
                              )}
                            </Menu.Item>
                            {brands.map((brand, index) => (
                              <Menu.Item key={brand}>
                                {({ active }) => (
                                  <button
                                    className={`${
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                    } group flex w-full items-center px-4 py-2 text-sm`}
                                    onClick={() => setSelectedBrand(brand)}
                                  >
                                    {brand}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

<div className="relative inline-block text-left">
  <Menu as="div" className="relative inline-block text-left">
    <Menu.Button className="inline-flex justify-center items-center w-[150px] h-[40px] rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50">
      Availability
      <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-400" />
    </Menu.Button>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                onClick={() => {
                  setAvailableForPickup(!availableForPickup);
                  setAvailableForDelivery(false);
                }}
              >
                Pickup
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                onClick={() => {
                  setAvailableForDelivery(!availableForDelivery);
                  setAvailableForPickup(false);
                }}
              >
                Delivery
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
</div>

{/* Rating Filter */}
<div className="relative inline-block text-left">
  <Menu as="div" className="relative inline-block text-left">
    <Menu.Button className="inline-flex justify-center items-center w-[150px] h-[40px] rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50">
      Rating: {minRating}+
      <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-400" />
    </Menu.Button>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          {[0, 1, 2, 3, 4, 5].map((rating) => (
            <Menu.Item key={rating}>
              {({ active }) => (
                <button
                  className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                  onClick={() => setMinRating(rating)}
                >
                  {rating}+ Stars
                </button>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
</div>
  
                    {/* Price Range */}
                    <div className="relative inline-block text-left">
                      <button
                        type="button"
                        className="inline-flex justify-center items-center w-[150px] h-[40px] rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50"
                        onClick={() => setOpenPriceRange(!openPriceRange)}
                      >
                        Price Range
                        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-400" />
                      </button>
                      {openPriceRange && (
                        <div className="right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-4">
                          <div className="flex flex-col space-y-4">
                            <label htmlFor="minPrice" className="block text-sm font-medium text-gray-700">
                              Minimum Price
                            </label>
                            <input
                              type="range"
                              id="minPrice"
                              name="minPrice"
                              min="0"
                              max={maxPrice}
                              value={minPrice}
                              onChange={(e) => setMinPrice(Number(e.target.value))}
                              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                            />
                            <span className="block text-sm text-gray-500 text-center">Value: ${minPrice}</span>
  
                            <label htmlFor="maxPrice" className="block text-sm font-medium text-gray-700">
                              Maximum Price
                            </label>
                            <input
                              type="range"
                              id="maxPrice"
                              name="maxPrice"
                              min={minPrice}
                              max="1000" // Adjust based on your data
                              value={maxPrice}
                              onChange={(e) => setMaxPrice(Number(e.target.value))}
                              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                            />
                            <span className="block text-sm text-gray-500 text-center">Value: ${maxPrice}</span>
                          </div>
                        </div>
                      )}
                    </div>

          {/* Sort Dropdown */}
          <div className="flex items-center space-x-2 w-full max-w-xs mx-auto">
  <label htmlFor="sortOptions" className="text-sm font-medium text-white">
    Sort by:
  </label>
  <div className="relative flex-1">
    <select
      id="sortOptions"
      name="sortOptions"
      value={sortOption}
      onChange={(e) => setSortOption(e.target.value)}
      className="block w-full pl-3 pr-10 py-2 text-base bg-black border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md shadow-sm cursor-pointer"
    >
      <option value="">Select</option>
      <option value="newest">Newest to Oldest</option>
      <option value="priceHighToLow">Price: High to Low</option>
      <option value="priceLowToHigh">Price: Low to High</option>
    </select>
  </div>
</div>

                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    );
  };
  
  return (
    <div className="bg-white">
      {isLoading ? (
        <Loader />
      ) : (
        <>
        <Header activeHeading={3} />
          <Sidebar />
          <HeroAllProducts />
          <div className="flex justify-center">
            <div className="w-full max-w-7xl">
              {renderFilters()}
            </div>
         </div>
         {/* Active Filters Bar */}
         <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
            <div className="flex flex-wrap">{renderActiveFilters()}</div>
          </div>

          <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
  <h2 className="sr-only">Products</h2>
  {data && data.length > 0 ? (
    <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
      {renderProductCards(data)}
    </div>
  ) : (
    <div>
      <p className="text-center text-lg mb-4">Oops, sorry we can't find what you're looking for, but check out some of these cool products!</p>
      <TrendingProductsCarosel className="w-full" />
    </div>
  )}
</div>

        </>
      )}
      <Footer />
    </div>
  );
};

export default CategoryProductsPage;
