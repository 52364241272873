import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import BrandCreate from '../components/Brands/CreateBrand';
import DashboardSideBar from '../components/Shop/Layout/DashboardSideBar';
import OwnedBrands from '../components/Brands/OwnedBrands';
import BrandCatalogItemCreate from '../components/Brands/BrandCatalogItemCreate';
import { Grid, Container } from '@mui/material';
import DashboardHeader from '../components/Shop/Layout/DashboardHeader';
import BrandCatalog from '../components/Brands/BrandCatalog';
import BrandSelection from '../components/Brands/BrandSelection';

// Enhanced style for the modal content using MUI system
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', sm: 500 }, // Responsive width
  bgcolor: 'black',
  border: '1px solid #ddd',
  borderRadius: 2, // Rounded corners
  boxShadow: 24,
  p: 4,
};

const BrandPage = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
        <DashboardHeader />
    
    <div className="flex justify-between w-full">
    <div className="w-[80px] 800px:w-[330px]">
          <DashboardSideBar active={13} />
        </div>
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={9} container direction="column" spacing={2}>
          <Grid item>
            <Button variant="contained" onClick={handleOpen}>
              Create New Brand
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="create-brand-modal-title"
              aria-describedby="create-brand-modal-description"
            >
              <Box sx={modalStyle}>
                <Typography id="create-brand-modal-title" variant="h6" component="h2" sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  Create a New Brand
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Typography>
                <BrandCreate />
              </Box>
            </Modal>
          </Grid>
          <Grid item>
            <BrandSelection />
          </Grid>
        </Grid>
      </Grid>
    </Container>
    </div>
    </div>
  );
};

export default BrandPage;