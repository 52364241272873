import React from 'react';
import { useSelector } from 'react-redux';

const AboutUser = () => {
  // Access user details from Redux store
  const { user } = useSelector((state) => state.user);

  if (!user) {
    return <div>Loading user data...</div>;
  }

  return (
    <div className="about-user-container">
      <h2>About {user.name}</h2>
      <div className="user-details">
        <p><strong>Email:</strong> {user.email}</p>
        {user.location && <p><strong>Location:</strong> {user.location}</p>}
        {user.occupation && <p><strong>Occupation:</strong> {user.occupation}</p>}
        {/* Add more user details here as needed */}
      </div>
      {/* Optionally, you can add more sections about the user here */}
    </div>
  );
};

export default AboutUser;
