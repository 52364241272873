import React, { useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import { Box, Grid, Typography, Paper, Avatar } from '@mui/material';
import { EnvelopeIcon, PhoneIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import { server } from "../server";

const FriendListTileWidget = ({ userId, profileType }) => {
  const [friends, setFriends] = useState([]);
  const token = Cookie.get('token');

  const getFriends = async () => {
    try {
      const response = await fetch(`${server}/friends/get-friends/${userId}/${profileType}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log('Fetched friends data:', data); // Debugging line
      if (Array.isArray(data)) {
        setFriends(data);
      } else {
        console.error('Error fetching friends: Expected an array, got:', data);
      }
    } catch (error) {
      console.error('Error fetching friends:', error);
    }
  };

  useEffect(() => {
    getFriends();
  }, [userId, profileType]);

  return (
    <Box sx={{ maxWidth: '100%', margin: 'auto' }}>
      <Typography color="#2c4a2e" variant="h5" fontWeight="500" sx={{ mb: 3 }}>
        Connections
      </Typography>
      <Grid container spacing={2} sx={{ backgroundColor: 'rgb(250, 250, 250, 0.2)'}}>
        {friends.length > 0 ? friends.map((friend) => (
          <Grid item xs={12} sm={6} key={friend._id}>
            <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
              <Avatar
                alt={friend.name}
                src={friend.avatar}
                sx={{ width: 80, height: 80, mb: 2 }}
              />
              <Typography variant="h6" sx={{ mb: 1 }}>
                {friend.name}
              </Typography>
              {/* Additional friend details like occupation can be added here if available */}
              <Box sx={{ display: 'flex', width: '100%', borderTop: 1, borderColor: 'divider' }}>
                <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', py: 1, borderRight: 1, borderColor: 'divider' }}>
                  <EnvelopeIcon sx={{ width: 20, height: 20, mr: 1 }} />
                  <Typography variant="body2">Message</Typography>
                </Box>
                <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', py: 1 }}>
                  <UserCircleIcon sx={{ width: 20, height: 20, mr: 1 }} />
                  <Typography variant="body2">Profile</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        )) : <Typography sx={{ textAlign: 'center', width: '100%' }}>No friends found.</Typography>}
      </Grid>
    </Box>
  );
};

export default FriendListTileWidget;


