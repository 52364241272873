import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../server'; 
import AddEditShippingRuleForm from './AddEditShippingRuleForm';
import { DataGrid } from '@mui/x-data-grid';

const ShippingRules = () => {
    const [shippingRules, setShippingRules] = useState([]);
    const [editingRule, setEditingRule] = useState(null);
    const currentProfileName = sessionStorage.getItem('currentProfile');

    useEffect(() => {
        fetchShippingRules();
    }, []);

    const fetchShippingRules = async () => {
        try {
            const response = await axios.get(`${server}/shippingrule/shipping-rules`, { withCredentials: true,
                headers: {
                  'current-profile': currentProfileName || 'defaultProfile',
                }, });
            setShippingRules(response.data);
        } catch (error) {
            console.error('Error fetching shipping rules:', error);
        }
    };

    const handleEdit = (rule) => {
        setEditingRule(rule);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${server}/shippingrule/shipping-rules/${id}` , { withCredentials: true,
                headers: {
                  'current-profile': currentProfileName || 'defaultProfile',
                }, });
            fetchShippingRules();
        } catch (error) {
            console.error('Error deleting shipping rule:', error);
        }
    };


    const columns = [
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'price', headerName: 'Price', width: 100 },
        { field: 'region', headerName: 'Region', width: 120 },
        { field: 'transitTime', headerName: 'Transit Time (days)', width: 180 },
        { field: 'minWeight', headerName: 'Min Weight (kg)', width: 130 },
        { field: 'maxWeight', headerName: 'Max Weight (kg)', width: 130 },
        { field: 'dimensions', headerName: 'Dimensions (LxWxH)', width: 200 },
        { field: 'minOrderPrice', headerName: 'Min Order Price ($)', width: 150 },
        { field: 'maxOrderPrice', headerName: 'Max Order Price ($)', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 150,
            renderCell: (params) => (
                <>
                    <button onClick={() => handleEdit(params.row)}>Edit</button>
                    <button onClick={() => handleDelete(params.id)}>Delete</button>
                </>
            ),
        },
    ];

    const rows = shippingRules.map((rule) => ({
        id: rule._id,
        name: rule.name,
        price: rule.price.toFixed(2),
        region: rule.region.name,
        transitTime: rule.transitionTime,
        minWeight: rule.weightRange?.min || 'N/A',
        maxWeight: rule.weightRange?.max || 'N/A',
        dimensions: `${rule.dimensions?.length || 'N/A'} x ${rule.dimensions?.width || 'N/A'} x ${rule.dimensions?.height || 'N/A'}`,
        minOrderPrice: rule.minOrderPrice,
        maxOrderPrice: rule.maxOrderPrice !== null ? rule.maxOrderPrice : 'No limit',
    }));

    return (
        <div>
        {editingRule ? (
            <AddEditShippingRuleForm rule={editingRule} setEditingRule={setEditingRule} fetchShippingRules={fetchShippingRules} />
        ) : (
            <button className="add-rule-btn" onClick={() => setEditingRule({})}>Add New Rate</button>
        )}
        <div style={{ height: 420, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
                sx={{
                    boxShadow: 'none',
                    border: '1px solid #2D3748',
                    color: '#F7FAFC',
                    '& .MuiDataGrid-cell': {
                      borderBottom: '1px solid #2D3748',
                      padding: '4px',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      backgroundColor: '#146e10',
                      color: '#F7FAFC',
                    },
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none',
                    },
                  }}
            />
        </div>
        </div>
    );
};

export default ShippingRules;
