import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from '../../server';
import { AiOutlineClose } from "react-icons/ai";

const TagSelector = ({ selectedTags, updateTags }) => {
  const [availableTags, setAvailableTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');
  const [customTag, setCustomTag] = useState('');

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const response = await axios.get(`${server}/tags/tags`, { withCredentials: true });
      
      setAvailableTags(response.data.filter(tag => tag.approved));
    } catch (error) {
      console.error('Failed to fetch tags:', error);
    }
  };

  const handleTagSelection = (tagId) => {
    
    if (tagId) {
      const tagToAdd = availableTags.find(tag => tag._id === tagId);
      
      if (!selectedTags.some(tag => tag._id === tagToAdd._id)) {
        const newSelectedTags = [...selectedTags, tagToAdd];
        
        updateTags(newSelectedTags);
      }
      setSelectedTag('');
    }
  };

  const handleCustomTagAddition = async () => {
    console.log("Custom Tag: ", customTag); // Log custom tag name
    if (customTag.trim() && !selectedTags.some(tag => tag.name.toLowerCase() === customTag.toLowerCase())) {
      try {
        const response = await axios.post(`${server}/tags/user-tags`, { name: customTag }, { withCredentials: true });
        if (response.data.tag) { // Ensure response contains the tag object
          const newTag = {...response.data.tag, approved: false}; 
          const newSelectedTags = [...selectedTags, newTag];
          updateTags(newSelectedTags);
          setCustomTag('');
        }
      } catch (error) {
        console.error('Error adding custom tag:', error);
      }
    }
};


  const handleRemoveTag = (tagId) => {
    const updatedTags = selectedTags.filter(tag => tag._id !== tagId);
    updateTags(updatedTags);
  };

  return (
    <div className="tag-selector">
      <select
        value={selectedTag}
        onChange={(e) => handleTagSelection(e.target.value)}
        className="tag-dropdown">
        <option value="">Select a tag</option>
        {availableTags.map(tag => (
          <option key={tag._id} value={tag._id}>{tag.name}</option>
        ))}
      </select>

      <input
        type="text"
        value={customTag}
        onChange={(e) => setCustomTag(e.target.value)}
        className="tag-custom-input"
        placeholder="Add custom tag"
        onKeyDown={(e) => e.key === 'Enter' && handleCustomTagAddition()}
      />
      <button onClick={handleCustomTagAddition}>Add Custom Tag</button>

      <div className="selected-tags-list">
        {selectedTags.map(tag => (
          <span key={tag._id} className={`tag-badge ${tag.approved ? 'approved' : 'unapproved'}`}>
            {tag.name}
            <button onClick={() => handleRemoveTag(tag._id)} className="remove-tag-button">
              <AiOutlineClose size={14} />
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default TagSelector;








