import React from 'react';

const generateICS = (event) => {
    const formatDate = (date) => {
       
        return new Date(date).toISOString().replace(/-|:|\.\d{3}/g, '').slice(0, 15) + 'Z';
    };

    const start = formatDate(event.date);
    const end = formatDate(new Date(new Date(event.date).getTime() + 2 * 60 * 60 * 1000)); // Assuming a 2-hour event

    return [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `URL:${document.location.href}`,
        `DTSTART:${start}`,
        `DTEND:${end}`,
        `SUMMARY:${event.title}`,
        `DESCRIPTION:${event.description.replace(/(\r\n|\n|\r)/gm, " ")}`, // Flatten description to remove new lines
        `LOCATION:${event.location.address}`,
        'END:VEVENT',
        'END:VCALENDAR'
    ].join('\r\n');
};

const DownloadICSButton = ({ event }) => {
    const downloadICSFile = () => {
        const icsData = generateICS(event);
        const blob = new Blob([icsData], { type: 'text/calendar;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${event.title}.ics`); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const buttonStyle = {
        backgroundColor: '#4CAF50', 
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
        fontFamily: '"Roboto", sans-serif',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease'
    };

    return (
        <button onClick={downloadICSFile} style={buttonStyle}>
            Download Calendar Event
        </button>
    );
};

export default DownloadICSButton;
