import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { server } from "../../server";

const SponsoredProductCarousel = () => {
    const [products, setProducts] = useState([]);

    // Fetch products and set up impression logging
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-products`, { withCredentials: true });
                const activeProducts = response.data.filter(ad => ad.isActive && ad.product);
                setProducts(activeProducts);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    // Log impressions
    useEffect(() => {
        products.forEach(product => {
            axios.post(`${server}/sponsored/impression/${product._id}`, {}, { withCredentials: true })
                .then(() => console.log('Impression logged for product:', product._id))
                .catch(error => console.error('Error logging impression:', error));
        });
    }, [products]);

    // Log ad clicks
    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`);
            console.log('Click logged for ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    return (
        <Carousel>
            {products.map(({ _id, product }) => (
                <Item key={_id} product={product} logAdClick={() => logAdClick(_id)} />
            ))}
        </Carousel>
    );
};

const Item = ({ product, logAdClick }) => {
    return (
        <Paper style={{
            background: `url(${product.images[0].url}) center center`,
            backgroundSize: 'cover',
            height: 400,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            color: '#fff'
        }}>
            <div style={{
                background: 'rgba(0, 0, 0, 0.5)', 
                borderRadius: '10px',
                padding: '20px',
                textAlign: 'center'
            }}>
                <h2 style={{ fontSize: '2em' }}>{product.name}</h2>
                <p style={{ fontSize: '1.25em' }}>{`$${product.discountPrice || product.originalPrice}`}</p>
                <Button component={Link} to={`/product/${product._id}`} onClick={logAdClick} variant="contained" style={{backgroundColor: '#4caf50', marginTop: '20px'}}>
                    Buy now
                </Button>
            </div>
        </Paper>
    )
}

export default SponsoredProductCarousel;
