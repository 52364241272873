import axios from 'axios';
import { server } from '../../server';
import Cookies from 'js-cookie'; // Assuming you're using js-cookie

// Action Types
export const CREATE_POST_REQUEST = 'CREATE_POST_REQUEST';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_FAILURE = 'CREATE_POST_FAILURE';

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE';

export const UPLOAD_VIDEO_REQUEST = 'UPLOAD_VIDEO_REQUEST';
export const UPLOAD_VIDEO_SUCCESS = 'UPLOAD_VIDEO_SUCCESS';
export const UPLOAD_VIDEO_FAILURE = 'UPLOAD_VIDEO_FAILURE';

export const LIKE_POST_SUCCESS = 'LikePostSuccess';
export const POST_COMMENT_SUCCESS = 'PostCommentSuccess';
export const EDIT_POST_SUCCESS = 'EditPostSuccess';
export const EDIT_COMMENT_SUCCESS = 'EditCommentSuccess';
export const DELETE_POST_SUCCESS = 'DeletePostSuccess';
export const DELETE_COMMENT_SUCCESS = 'DeleteCommentSuccess';
export const FETCH_COMMENTS_SUCCESS = 'FetchCommentsSuccess';
export const OPERATION_FAIL = 'OperationFail';
export const OPERATION_REQUEST = 'OperationRequest';
export const OPERATION_COMPLETE = 'OperationComplete';
export const CLEAR_ERRORS = 'ClearErrors';
export const FETCH_POSTS_REQUEST = 'FETCH_POSTS_REQUEST';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export const FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE';

export const RESHARE_POST_REQUEST = 'RESHARE_POST_REQUEST';
export const RESHARE_POST_SUCCESS = 'RESHARE_POST_SUCCESS';
export const RESHARE_POST_FAILURE = 'RESHARE_POST_FAILURE';

// Helper to get auth token
const getToken = () => Cookies.get('token');

// Action to handle post creation
export const createPost = (postData) => async (dispatch) => {
    dispatch({ type: CREATE_POST_REQUEST });

    try {
        const token = getToken();
        const response = await axios.post(`${server}/posts/create-post`, postData, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        dispatch({ type: CREATE_POST_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: CREATE_POST_FAILURE, payload: error.response?.data?.message || error.message });
    }
};

  export const fetchPosts = (profileType, ownerId, isProfile, page = 1) => async (dispatch) => {
    dispatch({ type: FETCH_POSTS_REQUEST });
    try {
        const token = getToken();
        // Determine the correct URL based on whether it's a profile-specific or general posts fetch
        const url = isProfile ? `${server}/posts/get-user-posts/${profileType}/${ownerId}/posts` : `${server}/posts/get-feed-posts`;
        
        const response = await axios.get(url, {
            withCredentials: true,
            headers: { Authorization: `Bearer ${token}` },
            params: { page } 
        });

        
        const { posts, totalPages } = response.data;
        console.log("totalpages", totalPages);
        if (!Array.isArray(posts)) {
            // If the posts are nested within a property (e.g., response.data.posts), adjust accordingly
            posts = response.data.posts; // Adjust this based on the actual response structure
        }

        // Sort the posts by createdAt date
        const sortedPosts = posts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        dispatch({
          type: FETCH_POSTS_SUCCESS,
          payload: {
            posts: sortedPosts, 
            page, 
            totalPages
          }
        });
        
    } catch (error) {
        console.error("Error fetching the posts:", error);
        dispatch({ type: FETCH_POSTS_FAILURE, payload: error });
    }
};



  // Action to upload an image to Cloudinary
export const uploadImage = (imageFile) => async (dispatch) => {
    dispatch({ type: UPLOAD_IMAGE_REQUEST });
    const formData = new FormData();
    formData.append('file', imageFile);
    formData.append('upload_preset', 'qfdl8km9'); 

    try {
        const response = await axios.post('https://api.cloudinary.com/v1_1/dzlopmfj8/image/upload', formData); 
        dispatch({ type: UPLOAD_IMAGE_SUCCESS, payload: response.data.secure_url });
    } catch (error) {
        dispatch({ type: UPLOAD_IMAGE_FAILURE, payload: error.response?.data?.message || error.message });
    }
};

// Action to upload a video to Cloudinary
export const uploadVideo = (videoFile) => async (dispatch) => {
    dispatch({ type: UPLOAD_VIDEO_REQUEST });
    const formData = new FormData();
    formData.append('file', videoFile);
    formData.append('upload_preset', 'qfdl8km9'); 

    try {
        const response = await axios.post('https://api.cloudinary.com/v1_1/dzlopmfj8/video/upload', formData); 
        dispatch({ type: UPLOAD_VIDEO_SUCCESS, payload: response.data.secure_url });
    } catch (error) {
        dispatch({ type: UPLOAD_VIDEO_FAILURE, payload: error.response?.data?.message || error.message });
    }
};
// Like or Unlike a Post
export const likeOrUnlikePost = (postId, profileType) => async dispatch => {
  try {
    const token = getToken();
    const response = await axios.patch(`${server}/posts/like-post/${postId}`, { profileType }, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
      },
    });
    dispatch({ type: LIKE_POST_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error occurred during like/unlike post request:", error);
    dispatch({ type: OPERATION_FAIL, payload: error });
  }
};

// Post a Comment
export const postComment = (postId, commentText, profileType) => async dispatch => {
    console.log(`Action: Posting comment for post ID: ${postId}, Text: ${commentText}, ProfileType: ${profileType}`);
  try {
    const token = getToken();
    const response = await axios.post(`${server}/posts/post-comment/${postId}`, { commentText, profileType }, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
      },
    });
    dispatch({ type: POST_COMMENT_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error posting comment:", error);
    dispatch({ type: OPERATION_FAIL, payload: error });
  }
};

// Edit a Post
export const editPost = (postId, description, reshareDescription) => async dispatch => {
  try {
    const token = getToken();
    const updateData = reshareDescription ? { description, reshareDescription } : { description };
    const response = await axios.patch(`${server}/posts/edit-post/${postId}`, updateData, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
      },
    });
    dispatch({ type: EDIT_POST_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error editing post:", error);
    dispatch({ type: OPERATION_FAIL, payload: error });
  }
};

// Edit a Comment
export const editComment = (postId, commentId, commentText) => async dispatch => {
  try {
    const token = getToken();
    const response = await axios.patch(`${server}/posts/edit-comment/${postId}/${commentId}`, { commentText }, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
      },
    });
    dispatch({ type: EDIT_COMMENT_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error occurred during comment update:", error);
    dispatch({ type: OPERATION_FAIL, payload: error });
  }
};

// Delete a Post
export const deletePost = (postId) => async dispatch => {
  try {
    const token = getToken();
    await axios.delete(`${server}/posts/delete-post/${postId}`, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
      },
    });
    dispatch({ type: DELETE_POST_SUCCESS, payload: postId });
  } catch (error) {
    console.error("Error occurred during post deletion:", error);
    dispatch({ type: OPERATION_FAIL, payload: error });
  }
};

// Delete a Comment
export const deleteComment = (postId, commentId) => async dispatch => {
  try {
    const token = getToken();
    await axios.delete(`${server}/posts/delete-comment/${postId}/${commentId}`, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
      },
    });
    dispatch({ type: DELETE_COMMENT_SUCCESS, payload: { postId, commentId } });
  } catch (error) {
    console.error("Error occurred during comment deletion:", error);
    dispatch({ type: OPERATION_FAIL, payload: error });
  }
};

// Fetch Comments
export const fetchComments = (postId, page = 1) => async dispatch => {
  try {
    const token = getToken();
    const response = await axios.get(`${server}/posts/get-comments/${postId}?page=${page}&limit=10`, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: FETCH_COMMENTS_SUCCESS, payload: { postId, comments: response.data.comments } });
  } catch (error) {
    console.error("Error fetching comments:", error);
    dispatch({ type: OPERATION_FAIL, payload: error });
  }
};

// Reshare a post
export const resharePost = (postId, reshareData) => async (dispatch) => {
    dispatch({ type: RESHARE_POST_REQUEST });
    try {
      const token = getToken(); // Assuming you have a function to get the auth token
      const response = await axios.post(`${server}/posts/share-post/${postId}`, reshareData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
          'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
        },
      });
  
      if (response.status === 201) {
        dispatch({ type: RESHARE_POST_SUCCESS, payload: response.data });
        // Optionally, include any callback or notification logic here
      } else {
        console.error('Failed to reshare post:', response);
        dispatch({ type: RESHARE_POST_FAILURE, payload: 'Failed to reshare post' });
      }
    } catch (error) {
      console.error('Error resharing post:', error);
      dispatch({ type: RESHARE_POST_FAILURE, payload: error.response?.data?.message || error.message });
    }
  };
  



