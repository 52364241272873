import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import BrandCatalogItemCreate from './BrandCatalogItemCreate';
import { fetchOwnedBrands, updateBrand } from '../../redux/actions/brand';
import { loadSeller } from "../../redux/actions/user";
import { AiOutlineCamera } from "react-icons/ai";



const OwnedBrand = () => {
  const { brandId } = useParams(); // Extract brandId from URL
  const dispatch = useDispatch();
  const { seller } = useSelector((state) => state.seller);
  const { isLoading, error, ownedBrands } = useSelector((state) => state.brand);
  const [open, setOpen] = useState(false);
  const [editingBrand, setEditingBrand] = useState(false);
  const [brandData, setBrandData] = useState({
    description: '',
  });
  const [coverImage, setCoverImage] = useState(null);
  const [avatarImage, setAvatarImage] = useState(null);

  // Find the specific brand from the ownedBrands array using brandId
  const selectedBrand = ownedBrands.find(brand => brand._id === brandId);

  useEffect(() => {
    dispatch(loadSeller());
  }, [dispatch]);

  useEffect(() => {
    if (seller) {
      dispatch(fetchOwnedBrands());
    }
  }, [seller, dispatch]);

  useEffect(() => {
    if (selectedBrand) {
      setBrandData({ description: selectedBrand.description });
    }
  }, [selectedBrand]);

  const handleFileChange = (e) => {
    if (e.target.name === 'coverImage') {
      setCoverImage(e.target.files[0]);
    } else if (e.target.name === 'avatarImage') {
      setAvatarImage(e.target.files[0]);
    }
  };

  const handleDescriptionChange = (e) => {
    setBrandData({ ...brandData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (coverImage) formData.append('coverImage', coverImage);
    if (avatarImage) formData.append('avatarImage', avatarImage);
    formData.append('description', brandData.description);

    if (selectedBrand && selectedBrand._id) {
      dispatch(updateBrand(selectedBrand._id, formData));
    }
    setEditingBrand(false); // Reset editing state after submit
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!selectedBrand) return <p>Brand not found.</p>;

  return (
    <div>

      <div style={{ width: 'full', margin: '0 auto', marginBottom: '20px', padding: '20px', boxShadow: '0 2px 4px rgba(0,0,0,0.2)', borderRadius: '10px' }}>
        <div >
        <div style={{ position: 'relative', textAlign: 'center' }}>
        <img src={selectedBrand.coverImage.url} alt="Cover" style={{ width: '100%', maxHeight: '250px', objectFit: 'cover' }} />
        <div style={{ position: 'absolute', bottom: '-30px', left: '15px', border: '3px solid white', borderRadius: '50%', overflow: 'hidden' }}>
          <img src={selectedBrand.avatarImage.url} alt="Avatar" style={{ width: '60px', height: '60px' }} />
          {editingBrand && (
            <>
              <label htmlFor="avatarImage" style={{ position: 'absolute', right: '5px', bottom: '5px', cursor: 'pointer' }}>
                
              <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'green',
          borderRadius: '50%',
          width: '24px',
          height: '24px',
          zIndex: '20',
          padding: '1px',
        }}>
          <AiOutlineCamera className="h-5 w-5" style={{ color: 'white' }} />
        </div>
              </label>
              <input type="file" id="avatarImage" name="avatarImage" onChange={handleFileChange} style={{ display: 'none' }} />
            </>
          )}
        </div>
        {editingBrand && (
          <>
            <label htmlFor="coverImage" style={{ position: 'absolute', right: '10px', bottom: '10px', cursor: 'pointer' }}>
            <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'green',
          borderRadius: '50%',
          width: '24px', 
          height: '24px',
          zIndex: '20',
          padding: '1px',
        }}>
          <AiOutlineCamera className="h-5 w-5" style={{ color: 'white' }} />
        </div>
            </label>
            <input type="file" id="coverImage" name="coverImage" onChange={handleFileChange} style={{ display: 'none' }} />
          </>
        )}
      </div>
          <h3 style={{
            fontSize: '24px',
            color: '#ffffff',
            fontWeight: 'bold',
            margin: '10px 0 5px',
            textTransform: 'uppercase',
            letterSpacing: '1px',
            textAlign: 'center',
          }}>{selectedBrand.name}</h3>
          {editingBrand ? (
            <div>
              <textarea id="description" name="description" value={brandData.description} onChange={handleDescriptionChange} style={{ width: '100%', borderRadius: '5px', padding: '10px', marginTop: '5px', background: 'black' }} />
            </div>
          ) : (
            <p style={{
              fontSize: '16px',
              color: '#ffffff',
              margin: '0 auto',
              maxWidth: '80%',
              lineHeight: '1.5',
              textAlign: 'center',
            }}>{selectedBrand.description}</p>
          )}
          {editingBrand ? (
            <>
              <button type="submit" onClick={handleSubmit} style={{
                marginTop: '20px',
                backgroundColor: '#46d300',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}>Update Brand</button>
              <button type="button" onClick={() => setEditingBrand(false)} style={{
                marginTop: '20px',
                marginLeft: '10px',
                backgroundColor: 'grey',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}>Cancel</button>
            </>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button onClick={() => setEditingBrand(true)} style={{
                marginTop: '20px',
                backgroundColor: '#46D300',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontWeight: 'bold',
            }}>Edit your brand</button>
            </div>

          )}
        </div>
      </div>
      <div style={{ backgroundColor: '#006400', width: '100%', display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
  <Button
    variant="contained"
    onClick={() => setOpen(true)}
    sx={{
      backgroundColor: 'green',
      '&:hover': {
        backgroundColor: 'darkgreen',
      },
      color: 'white',
      boxShadow: 'none',
      margin: '20px 0',
    }}
  >
    Add Catalog Item
  </Button>
</div>


      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="catalog-create-modal-style">
          <BrandCatalogItemCreate brandId={brandId} />
        </Box>
      </Modal>
    </div>
  );
};


export default OwnedBrand;
