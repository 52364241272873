import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { server } from "../../server";
import styles from "../../styles/styles";
import Loader from "../Layout/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductsShop } from "../../redux/actions/product";
import ShareToSocialMedia from './ShareToSocialMedia';
import { AiOutlineCamera } from "react-icons/ai";
import { loadSeller } from "../../redux/actions/user";
import { toast } from "react-toastify";
import AddFriendButton from "../AddFriendButton";
import FriendsAvatarDisplay from "../FriendsAvatarDisplay";
import FollowersAvatarDisplay from "../Followers/FollowersAvatarDisplay";
import FollowButton from "../Followers/FollowButton";



const ShopInfo = ({ isOwner }) => {
  const [data, setData] = useState({});
  const {products} = useSelector((state) => state.products);
  const [isLoading,setIsLoading] = useState(false);
  const {handle} = useParams();
  const [avatar, setAvatar] = useState();
  const dispatch = useDispatch();
  const currentProfile = useSelector((state) => state.user.currentProfile);
  

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${server}/shop/handle-to-id/${handle}`)
      .then(res => {
        const id = res.data.id;
        
        dispatch(getAllProductsShop(id));
        return axios.get(`${server}/shop/get-shop-info/${id}`);
      })
      .then(res => {
        setData(res.data.shop);
        
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [handle, dispatch]);
  
  useEffect(() => {
    console.log("Shop Data updated:", data);
    // Place any logic here that needs to run after `data` updates
  }, [data]);
  

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        axios
          .put(
            `${server}/shop/update-shop-avatar`,
            { avatar: reader.result },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            dispatch(loadSeller());
            toast.success("Avatar updated successfully!");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };


  

  const logoutHandler = () => {
    axios.post(`${server}/shop/logout`, {}, { withCredentials: true })
      .then((response) => {
        if (response.data.success) {
          // Provide a success message
          toast.success("Successfully logged out");
  
          // Redirect to the login page or home page
          window.location.href = "/shop-login"; // Adjust the route as needed
        }
      })
      .catch((error) => {
        // Handle error case
        console.error('Logout failed:', error);
        toast.error(error.response?.data?.message || "Logout failed");
      });
  };
  

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings = products && products.reduce((acc,product) => acc + product.reviews.reduce((sum,review) => sum + review.rating, 0),0);

  const averageRating = parseFloat((totalRatings / totalReviewsLength).toFixed(1)) || 0;

  

  return (
   <>
   {
    isLoading  ? (
      <Loader />
    ) : (
      <div>
      <div className="w-full py-5">
        <div className="w-full flex item-center justify-center">
                <img
                  src={`${data.avatar?.url}`}
                  alt="Shop Avatar"
                  className="w-[150px] h-[150px] object-cover rounded-full"
                />
                {isOwner && (
                  <div classname= "w-[42px] h-[42px] bg-[#099910] rounded-full flex items-center justify-center cursor-pointer absolute right-[15px] -mt-[3rem]">
                    <input type="file" id="avatar" className="hidden" onChange={handleImage} />
                    <label htmlFor="avatar">
                    <AiOutlineCamera />
                    </label>
                  </div>
                )}
                </div>
        <h3 className="text-center py-2 text-[20px]">{data?.name}</h3>
        {data && data._id && currentProfile && (
            <div className="flex flex-col items-center justify-center">
                <AddFriendButton friendId={data?._id} friendType="Shop" />
                <FollowersAvatarDisplay userId={data?._id} profileType="Shop" />
            </div>
        )}


        <p className="text-[16px] text-[#000000a6] p-[10px] flex items-center">
          {data?.description}
        </p>
      </div>
      <div className="p-3">
        <h5 className="font-[600]">Address</h5>
        <h4 className="text-[#000000a6]">{data?.address}</h4>
      </div>
      <div className="p-3">
        <h5 className="font-[600]">Phone Number</h5>
        <h4 className="text-[#000000a6]">{data?.phoneNumber}</h4>
      </div>
      <div className="p-3">
        <h5 className="font-[600]">Total Products</h5>
        <h4 className="text-[#000000a6]">{products && products.length}</h4>
      </div>
      <div className="p-3">
        <h5 className="font-[600]">Shop Ratings</h5>
        <h4 className="text-[#000000b0]">{averageRating}/5</h4>
      </div>
      <div className="p-3">
        <h5 className="font-[600]">Joined On</h5>
        <h4 className="text-[#000000b0]">{data?.createdAt?.slice(0, 10)}</h4>
      </div>
      <div className="p-3">
            <h5 className="font-[600]">Share Shop</h5>
            <ShareToSocialMedia 
                url={window.location.href} 
                message={`Check out this shop: ${data.name}`}
            />
        </div>
      {isOwner && (
        <div className="py-3 px-4">
           <Link to="/settings">
           <div className={`${styles.button} !w-full !h-[42px] !rounded-[5px]`}>
            <span className="text-white">Edit Shop</span>
          </div>
           </Link>
          <div className={`${styles.button} !w-full !h-[42px] !rounded-[5px]`}
          onClick={logoutHandler}
          >
            <span className="text-white">Log Out</span>
          </div>
        </div>
      )}
    </div>
    )
   }
   </>
  );
};

export default ShopInfo;
