import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllUsers } from "../../redux/actions/user";
import { DataGrid } from "@mui/x-data-grid";
import { AiOutlineDelete } from "react-icons/ai";
import { Button } from "@mui/material";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const AllUsers = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'User Sign-ups',
        data: [],
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      }
    ]
  });
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [timeFrame, setTimeFrame] = useState('daily'); 

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch, timeFrame]);

  useEffect(() => {
    if (users && users.length > 0) {
      processChartData(users, timeFrame); // Pass timeFrame to the processing function
    }
  }, [users, timeFrame]); // React to changes in users or timeFrame

  const processChartData = (users, timeFrame) => {
    const sortedUsers = [...users].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    const chartLabels = [];
    const signUpCounts = [];
    let currentUserCount = 0;

    sortedUsers.forEach(user => {
      const date = new Date(user.createdAt);
      let label;
      if (timeFrame === 'daily') {
        label = date.toLocaleDateString();
      } else { // 'monthly'
        label = `${date.getMonth() + 1}-${date.getFullYear()}`;
      }

      if (!chartLabels.includes(label)) {
        chartLabels.push(label);
        signUpCounts.push(currentUserCount);
        currentUserCount = 0; // Reset count for the new label
      }
      currentUserCount++; // Increment count for current label
    });
    // Ensure the last count is added
    if (currentUserCount > 0) {
      signUpCounts.push(currentUserCount);
    }

    setChartData({
      labels: chartLabels,
      datasets: [{
        label: 'User Sign-ups',
        data: signUpCounts,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      }]
    });
  };

  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: 'User Sign-ups Over Time', // Adjusted for the context of user sign-ups
        font: {
          size: 24, // Increase the font size for better visibility
        },
        color: '#ffffff', // Dark gray text color for better contrast
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += context.parsed.y;
            return label;
          },
        },
      },
      legend: {
        onClick: (e, legendItem, legend) => {
          const index = legendItem.datasetIndex;
          const chart = legend.chart;
          const meta = chart.getDatasetMeta(index);
      
          // Toggle the visibility
          meta.hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null;
      
          // Trigger an update and render to show the change
          chart.update();
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
        },
        beginAtZero: true,
      },
    },
    elements: {
      line: {
        tension: 0.4, // Smoothing the line
      },
      point: {
        hoverRadius: 7, // Bigger hover radius for better visibility
        radius: 5, // Standard point radius
      },
    },
  };


  const handleDelete = async (id) => {
    await axios
    .delete(`${server}/user/delete-user/${id}`, { withCredentials: true })
    .then((res) => {
      toast.success(res.data.message);
    });

  dispatch(getAllUsers());
  };

  const columns = [
    { field: "id", headerName: "User ID", minWidth: 150, flex: 0.7 },
    {
      field: "avatar",
      headerName: "Avatar",
      renderCell: (params) => (
        <img
          src={params.value}
          alt="avatar"
          style={{ width: 42, height: 42, borderRadius: '50%' }}
        />
      ),
      minWidth: 70,
      flex: 0.5,
      sortable: false,
    },

    {
      field: "name",
      headerName: "name",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "email",
      headerName: "Email",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "role",
      headerName: "User Role",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "joinedAt",
      headerName: "joinedAt",
      type: "text",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "viewProfile",
      headerName: "View Profile",
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/profile/${params.id}`} style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              View Profile
            </Button>
          </Link>
        );
      },
      minWidth: 150,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "Delete User",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => setUserId(params.id) || setOpen(true)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];
  users &&
    users.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        email: item.email,
        role: item.role,
        joinedAt: item.createdAt.slice(0, 10),
        avatar: item.avatar.url,
      });
    });

    return (
      <div className="w-full flex flex-col justify-center pt-5 items-center"> 
        {/* Graph Section */}
        <div className="w-full p-4 mb-5"> 
          <h3 className="text-[22px] font-Poppins pb-2">User Sign-ups Chart</h3>
          <div>
  <button
    onClick={() => setTimeFrame('daily')}
    style={{
      backgroundColor: '#4CAF50',
      color: 'white',
      padding: '10px 24px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      marginRight: '8px',
    }}
  >
    Daily
  </button>
  <button
    onClick={() => setTimeFrame('monthly')}
    style={{
      backgroundColor: '#008CBA',
      color: 'white',
      padding: '10px 24px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    }}
  >
    Monthly
  </button>
</div>


          <div className="w-full bg-white rounded p-5 shadow" style={{ minHeight: '420px' }}>
            <Line data={chartData} options={options} />
          </div>
        </div>

        {/* Data Grid Section */}
        <div className="w-full p-4">
          <h3 className="text-[22px] font-Poppins pb-2">All Users</h3>
          <div className="w-full min-h-[45vh] bg-white rounded shadow" style={{ marginTop: '32px' }}> 
            <DataGrid
              rows={row}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              autoHeight
              rowHeight={120} 
              sx={{
                boxShadow: 'none',
                border: '1px solid #2D3748',
                '.MuiDataGrid-columnHeaders': {
                  backgroundColor: '#146e10',
                  color: '#F7FAFC',
                  justifyContent: 'center',
                },
                '.MuiDataGrid-cell': {
                  borderBottom: '1px solid #2D3748',
                  padding: '4px',
                  color: '#F7FAFC',
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                },
                '.MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
              }}
            />
          </div>
        </div>

        
        {/* Modal for Deleting a User */}
        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Are you sure you wanna delete this user?
              </h3>
              <div className="w-full flex items-center justify-center">
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                  onClick={() => setOpen(false)}
                >
                  cancel
                </div>
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] ml-4`}
                  onClick={() => setOpen(false) || handleDelete(userId)}
                >
                  confirm
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
);}
    

export default AllUsers;
