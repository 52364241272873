import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, TextField } from '@mui/material';
import Friend from '../../components/Friend'; 
import WidgetWrapper from '../../components/WidgetWrapper';
import { getCurrentUserFollowers } from '../../redux/actions/followers'; 

const FollowingListWidget = ({ userId, profileType }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const { currentUserFollowing, loading, error } = useSelector(state => state.followers); 

  useEffect(() => {
    console.log('Effect running for userId:', userId, 'profileType:', profileType);
    if (userId && profileType) {
      dispatch(getCurrentUserFollowers(userId, profileType));
      console.log('Dispatching getCurrentUserFollowers');
    }
  }, [userId, profileType, dispatch]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredFollowing = searchTerm
    ? currentUserFollowing.filter((profile) => profile.name.toLowerCase().includes(searchTerm))
    : currentUserFollowing;

    console.log('Filtered following:', filteredFollowing);

  return (
    <WidgetWrapper>
      <Typography color="#2c4a2e" variant="h5" fontWeight="500" sx={{ mb: '1rem' }}>
        Following
      </Typography>
      <TextField
        variant="outlined"
        placeholder="Search following"
        fullWidth
        onChange={handleSearchChange}
        sx={{ mb: '1.5rem' }}
      />
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {loading && <Typography>Loading...</Typography>}
        {error && <Typography>{error}</Typography>}
        {!loading && !error && filteredFollowing.length > 0 ? filteredFollowing.map((profile) => (
          <Friend key={profile._id} friendId={profile._id} {...profile} friendType={profile.type} />
        )) : <Typography>{error || 'No following found.'}</Typography>}
      </Box>
    </WidgetWrapper>
  );
};

export default FollowingListWidget;
