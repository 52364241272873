import { React, useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { loadSellers } from "../../redux/actions/user";

const ShopLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [shopHandle, setShopHandle] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const sellerProfiles = useSelector((state) => state.seller.sellers);

  useEffect(() => {
    dispatch(loadSellers()); // Load sellers if not already loaded
  }, [dispatch]);

  useEffect(() => {
    // Retrieve the current profile name from session storage
    const currentProfileName = sessionStorage.getItem('currentProfile');
    if (currentProfileName) {
      // Find the seller with this name
      const foundSeller = sellerProfiles.find(seller => seller.name === currentProfileName);
      if (foundSeller) {
        setShopHandle(foundSeller.handle); // Set the handle if found
      }
    }
  }, [sellerProfiles]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!shopHandle || !password) {
      toast.error("Please provide both shop handle and password");
      return;
    }

    await axios.post(`${server}/shop/login-shop`, { shopHandle, password }, { withCredentials: true })
      .then((res) => {
        toast.success("Login Successful!");
        navigate(`/dashboard/${shopHandle}`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Login to your shop
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="shopHandle"
                className="block text-sm font-medium text-gray-700"
              >
                Shop Handle
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="shopHandle"
                  required
                  value={shopHandle}
                  onChange={(e) => setShopHandle(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Login
              </button>
            </div>
            <div className={`${styles.normalFlex} w-full`}>
              <h4>Don’t have an account?</h4>
              <Link to="/shop-create" className="text-blue-600 pl-2">
                Sign Up
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopLogin;
