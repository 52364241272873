import AddFriendButton from "../AddFriendButton";

const FriendCard = ({ friend, profileType, friendId }) => {
    return (
        <div className="friend-card">
            <div className="friend-card-image">
                <img src={friend.avatar.url} alt={friend.name} />
            </div>
            <div className="friend-card-content">
                <h4 className="friend-card-name">{friend.name}</h4>
            </div>
            <div className="friend-card-actions">
                <AddFriendButton friendId={friendId} friendType={profileType} />
            </div>
        </div>
    );
};

export default FriendCard;

