import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { server } from "../../server";
import EventCard from './EventCard';

const EventCarousel = () => {
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${server}/event/events`);
                setEvents(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching events:', error);
                setIsLoading(false);
            }
        };

        fetchEvents();
    }, []);

    return (
        <div>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <Carousel>
                    {events.map((event) => (
                        <Link key={event._id} to={`/events/${event._id}`} style={{ textDecoration: 'none' }}>
                            <div style={{ padding: '0 20%' }}>  
                                <EventCard event={event} />
                            </div>
                        </Link>
                    ))}
                </Carousel>
            )}
        </div>
    );
};

export default EventCarousel;

