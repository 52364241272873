import React from 'react';
import { useCookies } from 'react-cookie';

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  return (
    <div className="fixed inset-x-0 bottom-0 z-100 bg-gray-700" style={{ minHeight: '15%' }}>
      <div className="mx-auto max-w-7xl px-6 py-4 lg:flex lg:items-center lg:justify-between lg:px-8">
        <p className="text-lg font-bold tracking-tight text-gray-900">
          We use cookies to enhance your user experience.
          <br />
          By using our website, you agree to our use of cookies.
          <a href="/privacy-policy" className="text-sm font-semibold leading-6 text-green-500 ml-2">
            Learn more <span aria-hidden="true">→</span>
          </a>
        </p>
        <div className="mt-4 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
          <button
            onClick={giveCookieConsent}
            className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
