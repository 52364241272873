import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { server } from "../../server";

const QuadProductAdCarousel = () => {
    const [productQuads, setProductQuads] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${server}/sponsored/sponsored-products`, { withCredentials: true });
                const activeProducts = response.data.filter(ad => ad.isActive && ad.product);
                const quads = [];
                for (let i = 0; i < activeProducts.length; i += 4) {
                    quads.push(activeProducts.slice(i, i + 4).map(ad => ({
                        ...ad,
                        product_id: ad.product._id,
                        name: ad.product.name, 
                        images: ad.product.images,
                        discountPrice: ad.product.discountPrice,
                        originalPrice: ad.product.originalPrice 
                    })));
                }
                setProductQuads(quads);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching products:', error);
                setIsLoading(false);
            }
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        productQuads.forEach(quad => {
            quad.forEach(ad => {
                if (ad.product_id) {
                    axios.post(`${server}/sponsored/impression/${ad._id}`, {}, { withCredentials: true })
                        .then(() => console.log(`Impression logged for product: ${ad._id}`))
                        .catch(error => console.error(`Error logging impression for product: ${ad._id}:`, error));
                }
            });
        });
    }, [productQuads]);

    const logAdClick = async (ad_id) => {
        try {
            await axios.post(`${server}/sponsored/click/${ad_id}`);
            console.log('Click logged for product:', ad_id);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    return (
        <div>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <Carousel>
                    {productQuads.map((quad, index) => (
                        <div key={index} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                            {quad.map((ad, idx) => (
                                ad.product_id ? (
                                    <Item key={ad._id} ad={ad} logAdClick={() => logAdClick(ad._id)} />
                                ) : (
                                    <div>Error: Missing product data.</div>
                                )
                            ))}
                        </div>
                    ))}
                </Carousel>
            )}
        </div>
    );
};

const Item = ({ ad, logAdClick }) => {
    const imageUrl = ad.images && ad.images.length > 0 ? ad.images[0].url : "defaultImageUrl";
    return (
        <Paper style={{
            background: `url(${imageUrl}) center center`,
            backgroundSize: 'cover',
            height: 200, 
            width: '48%', 
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            color: '#fff',
            margin: '1%' 
        }}>
            <div style={{
                background: 'rgba(0, 0, 0, 0.5)',
                borderRadius: '10px',
                padding: '10px', 
                textAlign: 'center'
            }}>
                <h2 style={{ fontSize: '1.5em' }}>{ad.name}</h2>
                <p style={{ fontSize: '1em' }}>{`$${ad.discountPrice || ad.originalPrice}`}</p>
                <Button component={Link} to={`/product/${ad.product_id}`} onClick={logAdClick} variant="contained" style={{backgroundColor: '#4caf50', marginTop: '10px'}}>
                    Buy now
                </Button>
            </div>
        </Paper>
    );
};

export default QuadProductAdCarousel;
